import { ogrFrameMsg } from './iframe-messaging';
import templateAlert from './obs-popup-alert.html';
import templateConfirm from './obs-popup-confirm.html';
import './_obs-popup.scss';

export default class PopupService {

  constructor($uibModal, $uibModalStack, $window, messagesService, urlParamsService, piwikService) {
    'ngInject'; //NOSONAR
    this._$uibModal = $uibModal;
    this._$uibModalStack = $uibModalStack;
    this._$window = $window;
    this._messagesService = messagesService;
    this._urlParamsService = urlParamsService;
    this._piwikService = piwikService;
  }

  /**
   * VRL-1109
   * Führt eine Text-Ersetzung auf einer Message durch (Name der Partner-Bank) und fügt
   * die Redirect-URL zur Partner-Seite in das Message-Objekt ein.
   * @param {*} messageName
   */
  getMessage(messageName) {
    const messageData = this._messagesService.getMessage(messageName);
    if (this._urlParamsService.domain && (messageData.contentReDirect || messageData.reDirect)) {
      messageData.domain = this._urlParamsService.domain;
      if (messageData.contentReDirect) {
        // Ist eine Re-Direct URL übergeben worden und für diesen Fall ein spezieller Text konfiguriert, so wird dieser an dieser Stelle verwendet.
        const replacement = (this._urlParamsService.partnerBankName) ?
        this._urlParamsService.partnerBankName : 'Volksbank Raiffeisenbank';
        messageData.content = this._messagesService.getMessageFormatted(messageData.contentReDirect, replacement);
      }
    }
    return messageData;
  }

  /**
   * Falls die Anwendung im IFrame läuft, muss zum geöffneten Popup gescrollt werden.
   */
  _scrollToModal(elementId) {
    var domElement = document.getElementById(elementId);
    if (ogrFrameMsg.runsInIframe() && domElement) {
      ogrFrameMsg.scrollTo(domElement, -100);
    }
  }

  /**
   * Öffnet einen modalen Dialog vom Typ 'alert' oder 'confirm' und zeigt
   * die Nachricht an. Ist bereits ein modaler Dialog geöffnet, wird kein neuer
   * geöffnet. Durch Öffnen eines Dialogs wird ein Piwik Tracking ausgelöst.
   *
   * @param {*} messageName
   * @param {*} message
   * @param {*} modalType 'alert' oder 'confirm'
   * @param {*} trackEventNamePrefix 'ggf. ergänzende Infos, z.B. über den Prozessschritt'
   * @param {*} params optionales Array mit dynamischen Parametern, die im Message-Title oder -Inhalt ersetzt werden
   * @param {*} allowMultiple erlaubt das Öffnen des Popups im Vordergrund, obwohl bereits ein anderes Popup geöffnet ist - default false
   */
  _openModal(messageName, message, modalType, modalTemplate, trackEventNamePrefix, params, allowMultiple) {
    // ist bereits ein PopUp geöffnet?
    if (this._$uibModalStack.getTop() && !allowMultiple) {
      return;
    }

    var eventNamePrefix = (typeof trackEventNamePrefix !== 'undefined') ? trackEventNamePrefix : '';
    var eventName = (modalType == 'alert') ? eventNamePrefix + this._messagesService.getTrackEventName(messageName) : '';
     // console.log('>>> Matomo >>> ' + messagesService.getTrackEventCategory(messageName) + ' - ' + messagesService.getTrackEventAktion(messageName) + ' - '+ eventName);//NOSONAR
    this._piwikService.trackPopup(this._messagesService.getTrackEventAktion(messageName), this._messagesService.getTrackEventCategory(messageName), eventName);

    var modalInstance = this._$uibModal.open(
      {
        template: modalTemplate,
        animation: true,
        backdropClass: 'obs-backdrop',
        resolve: {
          message: function () {
            return message;
          }
        },
        controller: function($uibModalInstance, message, $state, messagesService) {
          'ngInject'; //NOSONAR
          const ctrl = this;
          ctrl.message = message;
          ctrl.params = params || [];
          ctrl.snippet = function (msg) {
            return messagesService.getSnippet(msg);
          };

          ctrl.cancel = function (msg) {
            $uibModalInstance.dismiss(msg);
          };

          ctrl.confirm = function(value) {
            $uibModalInstance.close(value);
          };

          ctrl.navigate = function(site) {
            $state.go(site);
          };

        },
        controllerAs: '$ctrl'
      });
    modalInstance.result.catch((result) => {
      if(modalType == 'confirm') {
        // console.log('>>> Matomo >>> ' + messagesService.getTrackEventCategory(messageName) + ' - ' + messagesService.getTrackEventAktion(messageName) + ' - ' + result);//NOSONAR
        this._piwikService.trackPopup(this._messagesService.getTrackEventAktion(messageName), this._messagesService.getTrackEventCategory(messageName), result);
      }
    });
    modalInstance.rendered.then(() => {
      this._scrollToModal('modal-' + modalType);
    });

    return modalInstance.result;
  }

  openAlert (messageName, trackEventNamePrefix, parameter) {
    var message = this._messagesService.getMessage(messageName);
    return this._openModal(messageName, message, 'alert', templateAlert, trackEventNamePrefix, parameter, false);
  }

  openConfirm (messageName, parameter) {
    var message = this.getMessage(messageName);
    return this._openModal(messageName, message, 'confirm', templateConfirm, undefined, parameter, false);
  }

  openAlertFromServer (messageData) {
    var message = {
      title: messageData.meldungTitel,
      htmlContent: messageData.meldungText
    };
    return this._openModal('serverError', message, 'alert', templateAlert, undefined, undefined, true);
  }

  close() {
    const modalTop = this._$uibModalStack.getTop();
    if (modalTop) {
      this._$uibModalStack.dismiss(modalTop.key, 'cancel');
    }
  }
}
