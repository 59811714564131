import template from './obs-buttons.html';
import './_obs-buttons.scss';

export const ButtonsComponent = {
  template: template, 
  bindings: {
    noLeftButton: '<',
    noRightButton: '<',
    goToPrevPage: '&',
    goToNextPage: '&',
    rightButtonText: '@'
  }
};
