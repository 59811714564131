import { staatsangehoerigkeit } from './staatsangehoerigkeit.data';
import { land } from './laender.data';

/**
 * Mit Hilfe dieser Service-Klasse wird die Länderliste vom Server gelesen.
 * Die ermittelte Länderliste wird dabei intern behalten,
 * damit der Request nur einmal abgesetzt wird.
 */
export default class LaenderlisteService {


  constructor($q, obsRequestWrapper) {
    'ngInject'; //NOSONAR
    this._$q = $q;
    this._obsRequestWrapper = obsRequestWrapper;
    this.laender = null;
  }


  _sorting(a, b) {
    return a.nationalitaet.toLowerCase().localeCompare(b.nationalitaet.toLowerCase());
  }

  /**
   * Daten vom Server beziehen
   * @param {*} showMask
   */
  getLaenderliste(showMask) {
    if (this.laender) {
      return this._$q.when(this.laender);
    } else {
      return this._obsRequestWrapper.sendRequest('countries', null, showMask)
        .then((response) => {
          var result = response.data.laenderliste;
          this.laender = result.sort(this._sorting);
          return this.laender;
        });
    }
  }

  /**
   * Liste der Staatsangehörigkeiten statisch im Frontend hinterlegt.
   * (Quelle: https://www.destatis.de/DE/Methoden/Klassifikationen/Bevoelkerung/Staatsangehoerigkeitsgebietsschluessel_xls.xlsx?__blob=publicationFile)
   */
  getStaatsangehoerigkeiten() {
    return staatsangehoerigkeit;
  }

  getLand() {
    return land;
  }

}
