export const ObsPatternInputDirective = () => {
  'ngInject'; //NOSONAR
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {},
    link: function (scope, element, attrs, ctrl) {
      element.bind('keypress', function (event) {
        if(event.keyCode === 13) return;

        let pattern = attrs.obsPatternInput ? attrs.obsPatternInput : attrs.ngPattern;
        let regex = new RegExp(pattern);
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        var newValue = (ctrl.$viewValue ? ctrl.$viewValue : '') + key;
        if(!regex.test(newValue)){
          event.preventDefault();
          return false;
        }
      });
    }
  };
};
