import template from './unternehmensangaben.html';

export { UnternehmensangabenComponent, UnternehmensangabenController };

const UnternehmensangabenComponent = {
  template: template,
  controller: UnternehmensangabenController,
  bindings: {
    onUnternehmenAusgewaehlt: '&',
    onUnternehmenSuchen: '&'
  },
  require: {
    parent: '^^kontaktdaten'
  }
};

/**
 * Controller für die Komponente in der die Firmensuche stattfindet.
 */
function UnternehmensangabenController(
  firmenSuchService,
  scrollService,
  formUtilService,
  popupService
) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.model = firmenSuchService.model;
    ctrl.onAuswahl = onAuswahl;
    ctrl.onSuchen = onSuchen;

    ctrl.model.maxDate = initializeMaxDate();
    ctrl.isFieldError = isFieldError;
    ctrl.isSubmittedFormError = isSubmittedFormError;
    ctrl.parent.addValidator(validate, 3);

    ctrl.popupBeDirectClicked = popupBeDirectClicked;
  };

  function onAuswahl() {
    //Komponenten-Listener aufrufen
    ctrl.onUnternehmenAusgewaehlt({});
  }

  function onSuchen() {
    //Komponenten-Listener aufrufen
    ctrl.onUnternehmenSuchen({});
  }

  function validate() {
    if (ctrl.model.showGruendungsdatumEingabe) {
      if (isFormError()) {
        scrollService.scrollTo('gruendungsDatumDaten');
        return false;
      }
    }
    return true;
  }

  function isFieldError(fieldname) {
    return formUtilService.isFieldError(
      ctrl.gruendungsDatumForm,
      ctrl.model.submittedGruendungsdatum,
      fieldname
    );
  }

  function isFormError() {
    return formUtilService.isFormError(ctrl.gruendungsDatumForm);
  }

  function isSubmittedFormError() {
    return formUtilService.isSubmittedFormError(
      ctrl.gruendungsDatumForm,
      ctrl.model.submittedGruendungsdatum
    );
  }

  function initializeMaxDate() {
    var date = new Date();
    date.setFullYear(date.getFullYear() - 2);
    return date;
  }

  function popupBeDirectClicked() {
    popupService.openAlert('bedirect');
  }
}
