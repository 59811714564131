import navigationModule from './navigation/navigation.module';
import footerModule from './footer/obs-footer.module';

/**
 * Das Modul obs.layout enthält globale Layout-Komponenten wie Navigation oder Footer.
 */
export default angular
  .module('obs.layout', [
    navigationModule,
    footerModule
  ])
  .name;

