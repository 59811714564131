import template from './kontaktdaten.html';
import { ValidatorContainer } from '../../common/obs-form-utils.service';

export { KontaktdatenComponent, KontaktdatenController };

const KontaktdatenComponent = {
  template: template,
  controller: KontaktdatenController,
  bindings: {
    laenderliste: '<'
  }
};

/**
 * Controller für die Kontaktdatenseite.
 *
 * @param {*} $state
 * @param {*} kontaktdatenService
 * @param {*} firmenSuchService
 * @param {*} personDatenService
 * @param {*} piwikService
 * @param {*} scrollService
 */
function KontaktdatenController($state,
  kontaktdatenService,
  firmenSuchService,
  personDatenService,
  piwikService,
  scrollService,
  interessentenService) {
  'ngInject'; //NOSONAR
  const ctrl = this;
  const validatorContainer = new ValidatorContainer();

  ctrl.$onInit = function () {
    ctrl.model = kontaktdatenService.model;
    ctrl.model.personModel.laenderliste = ctrl.laenderliste;

    ctrl.unternehmenAusgewaehlt = unternehmenAusgewaehlt;
    ctrl.unternehmenSuchen = unternehmenSuchen;
    ctrl.goToNextPage = goToNextPage;
    ctrl.goToPrevPage = goToPrevPage;
    ctrl.addValidator = addValidator;
  };

  function addValidator(validator, executionOrder) {
    validatorContainer.addValidator(validator, executionOrder);
  }

  function unternehmenAusgewaehlt() {
    ctrl.model.showPersonDaten = true;
    ctrl.model.showEinwilligungWerbung = true;
    ctrl.model.showNextPageButton = true;
    ctrl.model.personModel.gewaehlteFirma = ctrl.model.firmaModel.suchergebnis.ausgewaehlt;  
    personDatenService.adressenIdentischChanged();
    scrollService.scrollTo('unternehmensangaben');
  }

  function unternehmenSuchen() {
    ctrl.model.showPersonDaten = false;
    ctrl.model.showEinwilligungWerbung = false;
    ctrl.model.showNextPageButton = false;
  }

  /**
   * Wechselt zurück zur Finanzierungsvorhaben-Seite
   */
  function goToPrevPage() {
    $state.go('finanzierungsvorhaben');
  }

  /**
   * Wechselt wenn möglich auf die Ein- bzw. Ausgaben- Seite
   */
  function goToNextPage() {
    ctrl.model.personModel.submitted = true;
    firmenSuchService.model.submittedGruendungsdatum = true;
    const isValid = validatorContainer.validate() && firmenSuchService.isValid();
    trackProcessStep(isValid);
    if (isValid) {
      $state.go('finanzkennzahlen').then(() => {
        interessentenService.save();
      });
    }
  }

  /**
   * Piwik-Tracking bei Wechsel zum nächsten Prozess-Schritt
   */
  function trackProcessStep(success) {
    if (success) {
      piwikService.trackValidationSuccess([ctrl.model.personModel.daten.datenweitergabe === true ?
        'Einwilligungserklärung-Datenschutz-ja' : 'Einwilligungserklärung-Datenschutz-nein']);
    }
    else {
      piwikService.trackValidationFailure(ctrl.model.personModel.invalidFields.personenDaten.concat(
        ctrl.model.personModel.invalidFields.einwilligungWerbung));
    }
  }
  
}

