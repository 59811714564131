import _ from 'lodash';

/**
 * Services zur Ermittlung des korrekten Zinssatzes auf Basis einer
 * zweidimensionalen Matrix (Zinstableau) mit den Achsen Laufzeit
 * und Finanzierungsbetrag. 
 * Das zugrundeliegende Modell muss folgende Struktur aufweisen:
 * 
 *   [
 *      { "volumenMax": 12500, "laufzeitMax": 24, "zinssatz": 6.9 },
 *      { "volumenMax": 12500, "laufzeitMax": 48, "zinssatz": 6.9 },
 *      { "volumenMax": 12500, "laufzeitMax": 72, "zinssatz": 6.9 },
 *      { "volumenMax": 25000, "laufzeitMax": 24, "zinssatz": 6   },
 *      { "volumenMax": 25000, "laufzeitMax": 48, "zinssatz": 6.5 },
 *      ...
 *   ]
 * 
 * Eine Sortierung der Array-Einträge nach bestimmten Kriterien wird nicht
 * vorausgesetzt. Die Max-Schranken (volumenMax, laufzeitMax) sind inklusiv zu verstehen.
 * 
 */
export default class PricingService {

  /**
   * Konstruktor des Services, Initialisierung der Datenfelder
   */
  constructor () {
    'ngInject'; //NOSONAR
    this._zinstableau = [];
  }

  /**
   * Setzt das Zinstableau in Form eines Arrays von Objekten mit den
   * Felder volumenMax, laufzeitMax und zinssatz.
   * @param {*} zinstableau 
   */
  setZinstableau(zinstableau) {
    this._zinstableau = zinstableau;
  }

  /**
   * Liefert den korrekten Zinssatz für Volumen (Finanzierungsbetrag) und Laufzeit.
   * @param {*} volumen 
   * @param {*} laufzeit 
   */
  getZinssatz(volumen, laufzeit) {
    let matchingEntry;
    _.forEach(this._zinstableau, (entry) => {
      //Nicht passende Einträge ausschließen
      if (entry.volumenMax < volumen || 
          entry.laufzeitMax < laufzeit) {
        return;
      }
      if (!matchingEntry) {
        //Ersten Match übernehmen
        matchingEntry = entry;
      } else {
        //Prüfen, ob der zu prüfende Eintrag den bisher ausgewählten Eintrag ersetzt
        //(weil er eines oder beide Prüfkriterien besser erfüllt)
        if (entry.volumenMax - volumen <= matchingEntry.volumenMax - volumen &&
            entry.laufzeitMax - laufzeit <= matchingEntry.laufzeitMax - laufzeit) {
          matchingEntry = entry;
        }
      }
    });
    return matchingEntry.zinssatz;
  }

}

