import template from './interessent-code-ungueltig.html';

export {InteressentCodeUngueltigComponent, InteressentCodeUngueltigController};

const InteressentCodeUngueltigComponent = {
  template: template,
  controller: InteressentCodeUngueltigController
};

function InteressentCodeUngueltigController($window, $sce, $state, messagesService) {
  'ngInject'; //NOSONAR
  const ctrl = this;
  this.$state = $state;
  this._messagesService = messagesService;
  const message = this._messagesService.getMessage('iCodeUngueltigError');
  ctrl.$onInit = function () {
    this.$state.current.isModalViewState = true;
    this.$state.current.viewTitle = message.title;
    ctrl.text = $sce.trustAsHtml(message.content);
    ctrl.buttonText =  'Jetzt Anfrage starten';
    ctrl.goToNextPage = goToNextPage;
  };

  function goToNextPage() {
    $window.open('#/finanzierungsvorhaben');
  }
}
