import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import ngAnimate from 'angular-animate';
import uiMask from 'angular-ui-mask';
import componentModule from '../../components/components.module';
import commonModule from '../../common/common.module';
import KontaktformularService from './kontaktformular.service';
import UuidService from './utils/uuid.service';
import { KontaktformularComponent } from './kontaktformular.component';
import { BankfilialeComponent } from './bankfiliale/bankfiliale.component';
import { WunschterminComponent } from './wunschtermin/wunschtermin.component';
import { BestaetigungComponent } from './terminbestaetigung/bestaetigung.component';
import { routes, transitionChecks } from './kontaktformular-routes';
import interessentModule from '../../anfrage/interessent/interessent.module';

/**
 * Das Modul obs.Kontaktformular stellt die Kontaktformularseite zur Verfügung.
 */
export default angular
  .module('obs.Kontaktformular', [
    ngAnimate,
    uiRouter,
    commonModule,
    componentModule,
    uiMask,
    interessentModule
  ])
  .service('kontaktformularService', KontaktformularService)
  .service('uuidService', UuidService)
  .component('kontaktformular', KontaktformularComponent)
  .component('bankfiliale', BankfilialeComponent)
  .component('wunschtermin', WunschterminComponent)
  .component('bestaetigung', BestaetigungComponent)
  .config(routes)
  .run(transitionChecks)
  .name;
