import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import angularScrollModule from 'angular-scroll';
import modalDialogModule from 'angular-ui-bootstrap/src/modal';
import PopupService from './obs-popup.service';
import MessagesService from './obs-messages.service';
import EndpointsService from './obs-endpoints.service';
import PiwikService from './obs-piwik.service';
import CookieService from './obs-cookie.service';
import PricingService from './obs-pricing.service';
import FinancialService from './obs-financial.service';
import ScrollService from './obs-scroll.service';
import RequestWrapperService from './obs-http-handler.service';
import WaitingService from './obs-waiting.service';
import URLParamsService from './obs-url-params.service';
import ErrorMessageService from './obs-error-message.service';
import DateUtilService from './obs-date-utils.service';
import ThemesService from './obs-themes.service';
import LaenderlisteService from './obs-laender.service';
import BankService from './obs-banksuche.service';
import BankauswahlService from './obs-bankauswahl.service';
import BankfilialenSucheService from './obs-bankfilialen-suche.service';
import UploadService from './obs-upload.service';
import { FormUtilService } from './obs-form-utils.service';
import { NumberFilter } from './obs-number.filter';
import { BindHtmlCompileDirective } from './obs-bind-html-compile.directive';
import { ValidateAsDateDirective } from './obs-validate-as-date.directive';
import { DownloadLinkDirective } from './obs-download-link.directive';
import { NumberInputDirective } from './obs-number-input.directive';
import { ValidateIbanDirective } from './obs-iban-validation.directive';
import { SpecialCharacterValidateDirective } from './obs-special-character-validate.directive';
import { CompareToDirective } from './obs-compare-to.directive';
import { TrackingDirective } from './obs-tracking.directive';
import LoggingService from './obs-logging.service';
import ObsTokenService from './obs-token.service';
import { ObsPatternInputDirective } from './obs-pattern-input.directive';
import InteressentenService from './obs-interessenten.service';
import VideoLegitimationService from '../anfrage/legitimation/video-legitimation/video-legitimation.service';

export default angular
  .module('obs.common', [
    uiRouter,
    angularScrollModule,
    modalDialogModule
  ])
  .value('defaultTheme', 'ogr_theme')
  .value('duScrollDuration', 750)
  .value('duScrollOffset', 200)
  .service('popupService', PopupService)
  .service('messagesService', MessagesService)
  .service('endpointsService', EndpointsService)
  .service('piwikService', PiwikService)
  .service('cookieService', CookieService)
  .service('pricingService', PricingService)
  .service('financialService', FinancialService)
  .service('scrollService', ScrollService)
  .service('obsRequestWrapper', RequestWrapperService)
  .service('obsTokenService', ObsTokenService)
  .service('waiting', WaitingService)
  .service('urlParamsService', URLParamsService)
  .service('errorMessageService', ErrorMessageService)
  .service('dateUtilService', DateUtilService)
  .service('themesService', ThemesService)
  .service('laenderlisteService', LaenderlisteService)
  .service('formUtilService', FormUtilService)
  .service('bankService', BankService)
  .service('bankfilialenSucheService', BankfilialenSucheService)
  .service('bankauswahlService', BankauswahlService)
  .service('uploadService', UploadService)
  .service('loggingService', LoggingService)
  .service('interessentenService', InteressentenService)
  .service('videoLegitimationService', VideoLegitimationService)
  .directive('bindHtmlCompile', BindHtmlCompileDirective)
  .directive('validateAsDate', ValidateAsDateDirective)
  .directive('downloadLink', DownloadLinkDirective)
  .directive('obsNumberInput', NumberInputDirective)
  .directive('obsPatternInput', ObsPatternInputDirective)
  .directive('validateIban', ValidateIbanDirective)
  .directive('validateSpecialCharacter', SpecialCharacterValidateDirective)
  .directive('compareTo', CompareToDirective)
  .directive('obsTracking', TrackingDirective)
  .filter('obsNumber', NumberFilter)
  .name;

