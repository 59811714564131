import {applikationStatusBeantragung} from '../layout/navigation/navigation.constants';
import {PartnerIdSource} from './obs-url-params.service';
import _ from 'lodash';

/**
 * Mithilfe dieses Services werden die Daten des Interessenten gespeichert.
 */
export default class InteressentenService {

  constructor(
    urlParamsService,
    obsRequestWrapper,
    dateUtilService,
    piwikService,
    finanzierungsvorhabenService,
    kontaktdatenService,
    finanzkennzahlenService,
    obsTokenService,
    finanzierungswunschService,
    firmenSuchService,
    personDatenService,
    kalkulationParameterService,
    applikationStatus,
    themesService,
    $window,
    $state
  ) {
    'ngInject'; //NOSONAR
    this._urlParamsService = urlParamsService;
    this._obsRequestWrapper = obsRequestWrapper;
    this._dateUtilService = dateUtilService;
    this._piwikService = piwikService;
    this._finanzierungsvorhabenService = finanzierungsvorhabenService;
    this._kontaktdatenService = kontaktdatenService;
    this._finanzkennzahlenService = finanzkennzahlenService;
    this._obsTokenService = obsTokenService;
    this._finanzierungswunschService = finanzierungswunschService;
    this._firmenSuchService = firmenSuchService;
    this._personDatenService = personDatenService;
    this._kalkulationParameterService = kalkulationParameterService;
    this._applikationStatus = applikationStatus;
    this._themesService = themesService;
    this._$window = $window;
    this._$state = $state;
    this.iCode = null;
    this._interessentenData = null;
  }


  /**
   * Speichert die Daten des Interessenten in der Datenbank.
   * @returns {promise} iCode
   */
  save() {
    const finanzen = this._finanzkennzahlenService.model.daten;
    const firma = this._kontaktdatenService.model.personModel.gewaehlteFirma;
    const person = this._kontaktdatenService.model.personModel.daten;
    const params = this._urlParamsService.getData4Server();
    let data = {};
    if (this._isValidFirma(firma) && this._isValidPerson(person)) {
      data.finanzierung = this._getFinanzierung();
      data.firma = this._getFirma(finanzen, firma);
      data.anfrageSteller = this._getAnfrageSteller(person);
      if (this._isValidFinanzen(finanzen)) {
        data.bankverbindung = this._getBankverbindung(finanzen);
        data.jahresabschlussDaten = this._getJahresAbschlussDaten(finanzen);
        data.kdfPositiv =  this._finanzkennzahlenService.kdfPositiv;
      } else {
        data.kdfPositiv =  false;
      }
      if (params.partnerId) {
        data.vertriebsPartnerBlz = params.partnerId;
      }
      if (params.referrer) {
        data.referrer = params.referrer;
      }
      if (params.extReferenz) {
        data.extReferenz = params.extReferenz;
      }
      data.visitorId = this._piwikService.visitorId;
      data.eingangskanal = 'OBS';
      const isCreate = this._interessentenData === null && data;
      const isUpdate = (!( _.isEqual(data, this._interessentenData)));
      if (isCreate || isUpdate) {
        this._sendInteressentRequest(data);
      }
    }
  }

  _sendInteressentRequest(data){
    const interessentenRequest = this._createRequestOptions(this.iCode, data);
    return this._obsRequestWrapper
      .sendRequest('interessent', interessentenRequest, false, this.iCode)
      .then((response) => {
        this._interessentenData = _.cloneDeep(data);
        if (response && response.data && response.data.interessentenCode) {
          this.iCode = response.data.interessentenCode;
          this._piwikService.eventTrack('Prozess Wiedereinstieg', 'Interessentendaten gespeichert', '');
        }
      });
  }

  /**
   * Lädt die Daten des Interessenten mithilfe des iCodes und Zugangcodes nach anklicken des Wiedereinstieglinks.
   * @param {number} iCode
   * @param {number} zugangscode
   * @returns {string} Wiedereinstiegsseite
   */
  load(iCode, zugangscode) {
    return this._obsRequestWrapper
      .sendRequest('interessent',
        { useCustomErrorHandler: true, headers: this._obsTokenService.getAccessTokenHeader(zugangscode) },
        true, iCode)
      .then((response) => {
        if (response && response.data) {
          this.iCode = iCode;
          this._piwikService.eventTrack('Prozess Wiedereinstieg', 'Interessentendaten geladen', '');
          return this._kalkulationParameterService.getKalkulationParameter().then((kalkResponse) => {
            this._finanzierungswunschService.initModel(kalkResponse);
            return this._mapInterressentenData(response.data);
          });
        }
      });
  }

  /**
   * Initialisiert die Daten des Service durch Abruf vom Server.
   * @param {*} iCode
   * @returns
   */
  getStatusFromServer(iCode) {
    if (!iCode) {
      throw 'Interessentencode fehlt!';
    }
    return this._obsRequestWrapper
      .sendRequest('interessent', { useCustomErrorHandler: true }, true, iCode)
      .then((response) => {
        if (response && response.data) {
          this.iCode = iCode;
          this._piwikService.goalTrackAmount(1, response.data);
          return this._setStatusResponse(response.data);
        }
      }).catch((errorResponse) => {
        const errorData = errorResponse.data;
        if (errorData && errorData.errorCode === 'INTERESSENTEN_CODE_NOT_FOUND') {
          return errorData.errorCode;
        }
        return this._$state.go('error');
      });
  }

  /**
   * Setzt die initialen Daten des Interessenten nach erstmaligem Serveraufruf
   * und leitet redirect anhand einer redirectUrl ein.
   * @param {object} result
   */
  _setStatusResponse(result) {
    // Falls die redirectURL gesetzt ist, wird der Kunde umgeleitet und ein Promise returned
    // um die weitere Verarbeitung / laden der Seite zu verhindern.
    if (result.redirectUrl && result.redirectUrl !== '') {
      this._$window.location.href = result.redirectUrl;
      return new Promise(() => {
      });
    }

    if (result) {
      this._applikationStatus.eingangskanal = result.eingangskanal || this._applikationStatus.eingangskanal;
      this._applikationStatus.lob = result.lob;
      this._applikationStatus.ulob = result.ulob;
      this._piwikService.setSegmentBlz(result.partnerId);
      this._piwikService.setEingangskanal(this._applikationStatus.eingangskanal);
      this._themesService.applyTheme(result.referrer);
      if (result.visitorId) {
        // visitorId auf der Wiedereinstiegsseite setzen, um über Matomo eine Verknüpfung zum Interessenten herzustellen
        this._piwikService.setVisitorId(result.visitorId);
      }
    }
  }

  /**
   * Erzeugt das options-Objekt des Interessenten für den obsRequestWrapper.
   * @param {string | null} existingICode
   * * @param {object} data
   * @returns {object} requestOptions
   */
  _createRequestOptions(existingICode, data) {
    return {
      method: existingICode ? 'PUT' : 'POST',
      timeout: 60000,
      data: data,
      useCustomErrorHandler: true
    };
  }

  _isValidFirma(firma) {
    return firma && firma.gruendungsdatum;
  }

  _isValidPerson(person) {
    return !!(person.geschlecht && person.vorname && person.nachname && person.geburtstag && person.geburtsort
      && person.nationalitaet && person.strasse && person.plz && person.ort
      && person.land && person.email && person.mobilnummer && (person.datenweitergabe !== null));
  }

  _isValidFinanzen(finanzen) {
    return !!(finanzen.bilanzstichtag1 && finanzen.bilanzstichtag2 && finanzen.gewinn1 && finanzen.gewinn2 && finanzen.umsatz1
    && finanzen.umsatz2 && finanzen.abschreibung1 && finanzen.abschreibung2 && finanzen.zinsaufwand1
    && finanzen.zinsaufwand2 && finanzen.iban);
  }

  /**
   * Liefert die Finanzierungsdaten des Interessenten.
   * @returns {object} finanzierung
   */
  _getFinanzierung() {
    return {
      'wunschrate': this._finanzierungsvorhabenService.getWunschrate(),
      'finanzierungsbetrag': this._finanzierungsvorhabenService.getFinanzierungsbetrag(),
      'berechnungsart': this._finanzierungsvorhabenService.getBerechnungsart(),
      'laufzeit': this._finanzierungsvorhabenService.getLaufzeit()
    };
  }


  /**
   * Liefert die Unternehmensdaten des Interessenten.
   * @param {finanzen} finanzen
   * @param {firma} firma
   * @returns {object} firma
   */
  _getFirma(finanzen, firma) {
    const rechtsform = this._kontaktdatenService.model.firmaModel.rechtsformPruefung;
    return {
      'nameGesamt': firma.nameGesamt,
      'beDirectNr': firma.beDirectNr,
      'strasse': firma.strasse,
      'plz': firma.plz,
      'ort': firma.ort,
      'gruendungsdatum': this._dateUtilService.getDate(firma.gruendungsdatum),
      'rechtsformZugelassen': rechtsform.zugelassen
    };
  }

  /**
   * Liefert die Personendaten des Interessenten.
   * @param {person} person
   * @returns {object} anfrageSteller
   */
  _getAnfrageSteller(person) {
    return {
      'anrede': person.geschlecht,
      'vorname': person.vorname,
      'nachname': person.nachname,
      'geburtsdatum': this._dateUtilService.getDate(person.geburtstag),
      'geburtsort': person.geburtsort,
      'nationalitaet': person.nationalitaet,
      'einwilligungDatenweitergabe': person.datenweitergabe,
      'adresse': {
        'strasse': person.strasse,
        'plz': person.plz,
        'ort': person.ort,
        'land': person.land,
        'email': person.email,
        'mobilNr': '+49' + person.mobilnummer
      }
    };
  }


  /**
   * Liefert die Banverbindung des Interessenten.
   * @param {finanzen} finanzen
   * @returns {object} bankverbindung
   */
  _getBankverbindung(finanzen) {
    const validIban = finanzen && finanzen.iban && finanzen.iban.replace(/\s/g, '').toUpperCase();
    const bank = this._finanzkennzahlenService.model.bank;
    return {
      'iban': validIban,
      'bankname': bank ? bank.name : null
    };
  }


  /**
   * Liefert die Jahresabschlussdaten des Interessenten.
   * @param {finanzen} finanzen
   * @returns {object} jahresabschlussDaten
   */
  _getJahresAbschlussDaten(finanzen) {
    return [
      {
        'typ': finanzen.finanzkennzahlenArt,
        'fkzAuspraegung': finanzen.fkzAuspraegung,
        'bilanzStichtag': this._dateUtilService.getDate(finanzen.bilanzstichtag1),
        'gewinn': finanzen.gewinn1,
        'umsatz': finanzen.umsatz1,
        'abschreibung': finanzen.abschreibung1,
        'zinsaufwand': finanzen.zinsaufwand1
      },
      {
        'typ': finanzen.finanzkennzahlenArt,
        'fkzAuspraegung': finanzen.fkzAuspraegung,
        'bilanzStichtag': this._dateUtilService.getDate(finanzen.bilanzstichtag2),
        'gewinn': finanzen.gewinn2,
        'umsatz': finanzen.umsatz2,
        'abschreibung': finanzen.abschreibung2,
        'zinsaufwand': finanzen.zinsaufwand2
      }
    ];
  }


  /**
    * Leitet den Interessenten anhand der redirectURL weiter oder
    * füllt die eingehenden Daten des Interessenten in die passenden Felder.
    * @param {object} interessentenData
    * @returns {string} $state
    */
  _mapInterressentenData(interessentenData) {
    this._interessentenData = _.cloneDeep(interessentenData);
    this._applikationStatus.status = applikationStatusBeantragung;

    this._mapFinanzierung(interessentenData.finanzierung);
    this._mapFirma(interessentenData.firma);
    this._mapAnfrageSteller(interessentenData.anfrageSteller);
    this._mapParams(interessentenData);
    if (interessentenData.bankverbindung && interessentenData.jahresabschlussDaten) {
      this._mapBankverbindung(interessentenData.bankverbindung);
      this._mapJahresAbschlussDaten(interessentenData.jahresabschlussDaten);
      return interessentenData.kdfPositiv ? 'kdfPositiv' : 'kdfNegativ';
    }
    return interessentenData.kdfPositiv ? 'kdfPositiv' : 'finanzkennzahlen';
  }


  /**
   * Befüllt die Finanzierungsdaten des Interessenten.
   * @param {object} finanzierung
   */
  _mapFinanzierung(finanzierung) {
    this._finanzierungswunschService.daten.finanzierungsparameter.rateSlider.value = finanzierung.wunschrate;
    this._finanzierungswunschService.daten.finanzierungsparameter.betragSlider.value = finanzierung.finanzierungsbetrag;
    this._finanzierungswunschService.daten.finanzierungsparameter.berechnungsartBudgetrechner = finanzierung.berechnungsart === 'budgetrechner';
    this._finanzierungswunschService.daten.finanzierungsparameter.laufzeitSlider.value = finanzierung.laufzeit;
    // sorgt für erneutes kalkulieren des Kreditbetrages, notwendig für richtige Darstellung:
    this._finanzierungswunschService.berechnungsartGeaendert();
  }


  /**
   * Befüllt die Unternehmensdaten des Interessenten.
   * @param {object} firma
   */
  _mapFirma(firma) {
    this._personDatenService.model.gewaehlteFirma = firma;
    this._firmenSuchService.model.suchergebnis.ausgewaehlt = firma;
    this._firmenSuchService.model.showKriterien = false;
    this._firmenSuchService.model.showAuswahl = true;

    this._firmenSuchService.model.daten.name = firma.nameGesamt;
    this._firmenSuchService.model.daten.strasse = firma.strasse;
    this._firmenSuchService.model.daten.plz = firma.plz;
    this._firmenSuchService.model.daten.ort = firma.ort;
    this._firmenSuchService.model.rechtsformPruefung.zugelassen = firma.rechtsformZugelassen;
    this._firmenSuchService.initialized = true;
  }


  /**
   * Befüllt die Anfragestellerdaten des Interessenten.
   * @param {object} anfrageSteller
   */
  _mapAnfrageSteller(anfrageSteller) {
    this._personDatenService.model.daten.geschaeftsfuehrer = anfrageSteller.geschaeftsfuehrer;
    this._personDatenService.model.daten.geschlecht = anfrageSteller.anrede;
    this._personDatenService.model.daten.vorname = anfrageSteller.vorname;
    this._personDatenService.model.daten.nachname = anfrageSteller.nachname;
    this._personDatenService.model.daten.strasse = anfrageSteller.adresse.strasse;
    this._personDatenService.model.daten.plz = anfrageSteller.adresse.plz;
    this._personDatenService.model.daten.ort = anfrageSteller.adresse.ort;
    this._personDatenService.model.daten.land = anfrageSteller.adresse.land;
    this._personDatenService.model.daten.nationalitaet = anfrageSteller.nationalitaet;
    this._personDatenService.model.daten.geburtstag = new Date(anfrageSteller.geburtsdatum);
    this._personDatenService.model.daten.geburtsort = anfrageSteller.geburtsort;
    this._personDatenService.model.daten.mobilnummer = anfrageSteller.adresse.mobilNr.split('+49')[1];
    this._personDatenService.model.daten.email = anfrageSteller.adresse.email;
    this._personDatenService.model.email_wiederholung = this._personDatenService.model.daten.email;
    this._personDatenService.model.daten.datenweitergabe = anfrageSteller.einwilligungDatenweitergabe;

    this._kontaktdatenService.model.showPersonDaten = true;
    this._kontaktdatenService.model.showNextPageButton = true;
    this._kontaktdatenService.model.showEinwilligungWerbung = true;

    this._personDatenService.initialized = true;

  }


  /**
   * Befüllt die Finanzdaten des Interessenten.
   * @param {object} finanzen
   */
  _mapBankverbindung(finanzen) {
    this._finanzkennzahlenService.model.bank = { 'name': finanzen.bankname };
    this._finanzkennzahlenService.model.daten.iban = finanzen.iban;

    this._finanzkennzahlenService.initialized = true;
  }


  /**
   * Befüllt die Jahresabschlussdaten des Interessenten.
   * @param {object} finanzen
   */
  _mapJahresAbschlussDaten(finanzen) {
    this._finanzkennzahlenService.model.daten.finanzkennzahlenArt = finanzen[0].typ;
    this._finanzkennzahlenService.model.daten.fkzAuspraegung = finanzen[0].fkzAuspraegung;
    this._finanzkennzahlenService.model.daten.bilanzstichtag1 = new Date(finanzen[0].bilanzStichtag);
    this._finanzkennzahlenService.model.daten.bilanzstichtag2 = new Date(finanzen[1].bilanzStichtag);
    this._finanzkennzahlenService.model.daten.gewinn1 = finanzen[0].gewinn;
    this._finanzkennzahlenService.model.daten.gewinn2 = finanzen[1].gewinn;
    this._finanzkennzahlenService.model.daten.umsatz1 = finanzen[0].umsatz;
    this._finanzkennzahlenService.model.daten.umsatz2 = finanzen[1].umsatz;
    this._finanzkennzahlenService.model.daten.abschreibung1 = finanzen[0].abschreibung;
    this._finanzkennzahlenService.model.daten.abschreibung2 = finanzen[1].abschreibung;
    this._finanzkennzahlenService.model.daten.zinsaufwand1 = finanzen[0].zinsaufwand;
    this._finanzkennzahlenService.model.daten.zinsaufwand2 = finanzen[1].zinsaufwand;
    this._finanzkennzahlenService.bwaAkzeptiert = this._kalkulationParameterService.getKalkulationParameter().bwaAkzeptiert;
    this._finanzkennzahlenService.initialized = true;
  }


  /**
  * Befüllt sonstige Parameter des Interessenten.
  * @param {object} params
  */
  _mapParams(params) {
    if (params.vertriebsPartnerBlz) {
      this._urlParamsService.partnerId = params.vertriebsPartnerBlz;
      this._urlParamsService.partnerIdSource = PartnerIdSource.URL;
    }
    if (params.referrer) {
      this._urlParamsService.referrer = params.referrer;
    }
    if (params.extReferenz) {
      this._urlParamsService.extReferenz = params.extReferenz;
    }
    this._piwikService.setVisitorId(params.visitorId);
    this._finanzkennzahlenService.eingangskanal = params.eingangskanal;
    this._finanzkennzahlenService.kdfPositiv = params.kdfPositiv;
    this._finanzkennzahlenService.initialized = true;
  }

}
