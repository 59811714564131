import { PartnerIdSource } from './obs-url-params.service';

/**
 * Service für Banksuche mit Hilfe der IBAN.
 * @param $log
 * @param urlParamsService  - Enthält die Service-Funktionen und Parameter
 *                            mit denen die Seite aufgerufen wurde.
 * @param obsRequestWrapper - Enhält die Service-Funktionen für die Kom-
 *                            munikation mit dem Backend
 */
export default class BankService {

  constructor($log,
              urlParamsService,
              obsRequestWrapper) {
    'ngInject'; //NOSONAR

    // Enthält die BLZ der zuletzt gesuchten Bank.
    this._cachedIBAN = null;

    this._$log = $log;
    this._urlParamsService = urlParamsService;
    this._obsRequestWrapper = obsRequestWrapper;
  }
  /**
   * setzt die Bank-Information zurück
   */
  resetBankInfo () {
    this._cachedIBAN = null;
  }

  /**
   * Sucht mit Hilfe der BLZ die in der übergebenen IBAN enthalten ist,
   * nach der entsprechenden Bank.
   *
   * @param iban - aktuelle IBAN
   * @param bankFound - Callback-Funktion, die bei Erfolg des Servercalls aufgerufen wird
   * @param sucheFehlgeschlagen - Callback-Funktion, die bei Fehler des Servercalls aufgerufen wird
   */
  searchBank (iban, bankFound, sucheFehlgeschlagen) {
    const ibanSearch = iban.slice(4, 12);
    if (this._cachedIBAN !== iban) {
      if (ibanSearch.length == 8) {
        this._obsRequestWrapper.sendRequest('searchBank',
          { method: 'POST',
            data: {
              blz: ibanSearch
            }
          }, false).then( (res) => {
            if (bankFound) {
              bankFound(res.data);
            }
            this._cachedIBAN = iban;
          }, (err) => {
            this._$log.debug('Fehler bei Banksuche: ' + err);
            if (sucheFehlgeschlagen) {
              sucheFehlgeschlagen(err.data.errorCode);
            }
            this._cachedIBAN = iban;
          });
      }
      else {
        if (sucheFehlgeschlagen) {
          sucheFehlgeschlagen();
        }
        this._cachedIBAN = iban;
      }
    }
  }

  /**
   * Hinterlegt die übergebene Bank am Model und wenn keine Partner-ID
   * übergeben wurde, wird die BLZ der Bankverbindung des Kunden als
   * Partner-ID übernommen, sofern es sich um eine OBS-Bank handelt.
   *
   * @param bank - aktuelle Bankverbindung des Kunden.
   */
  setPartnerBank (bank) {
    /**
     * Wenn Partner-ID nicht per URL übergeben wird, dann wird die
     * BLZ der Bankverbindung des Kunden als Partner-ID übernommen,
     * sofern es sich um eine OBS-Bank handelt.
     */
    if (this._urlParamsService.partnerId == null ||
        !this._urlParamsService.isPartnerIdSource(PartnerIdSource.URL)) {
      if (bank.isObs == 'true') {
        //Übernahme der BLZ aus der Bankverbindung bei OBS Banken
        this._urlParamsService.partnerId = bank.blz;
        this._urlParamsService.partnerBankName = bank.name;
        this._urlParamsService.partnerIdSource = PartnerIdSource.IBAN;
      }
      else {

        //Reset der Partner-ID bei nicht OBS Bank
        //(wichtig bei nachträglichen Änderungen der IBAN)
        this._urlParamsService.partnerId = null;
        this._urlParamsService.partnerBankName = null;
        this._urlParamsService.partnerIdSource = null;
      }
    }
   
  }
}
