import template from './firmensuche-kriterien.html';

export { FirmensucheKriterienComponent, FirmensucheKriterienController };

const FirmensucheKriterienComponent = {
  template: template,
  controller: FirmensucheKriterienController,
  bindings: {
    onAutoSelection: '&'
  }
};

/**
 * Controller für die Anzeige der Suchkriterien zur Firmensuche.
 *
 * @param formUtilService
 * @param firmenSuchService
 * @param piwikService
 */
function FirmensucheKriterienController(formUtilService, firmenSuchService, piwikService) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.model = firmenSuchService.model;
    ctrl.onSuchkriteriumGeandert = firmenSuchService.onSuchkriteriumGeandert;
    ctrl.sucheAusfuehren = sucheAusfuehren;
    ctrl.isFieldError = isFieldError;
    ctrl.isSubmittedFormError = isSubmittedFormError;
  };

  function validate() {
    ctrl.model.submitted = true;
    return !isSubmittedFormError();
  }

  function sucheAusfuehren() {
    if (validate()) {
      this.model.showErgebnis = false;
      firmenSuchService.sucheAusfuehren().then((suchergebnis) => {
        if (suchergebnis.einIdentischerTreffer) {
          // Wurde ein Treffer gefunden und entsprechen die Firmendaten der
          //Eingabe des Anwenders muss die Rechtsformprüfung ausgeführt werden
          const treffer = suchergebnis.firmen[0];
          firmenSuchService.pruefeRechtsform(treffer)
          .then((pruefungsErgebnis) => {
            if (pruefungsErgebnis.zugelassen) {
              firmenSuchService.auswahlAnzeigen(treffer);
              //Komponenten-Listener aufrufen, Eltern-Komponente über Auswahl informieren
              ctrl.onAutoSelection({ selection: treffer });
            } else {
              firmenSuchService.handleRechtsformPruefungNegativ(pruefungsErgebnis);
            }
          });
        }
      });
    }
    else {
      const invalidFields = formUtilService.getErroneousFields(ctrl.firmenSucheForm);
      piwikService.trackValidationFailure(invalidFields);
    }
  }

  function isFieldError(fieldname) {
    return formUtilService.isFieldError(ctrl.firmenSucheForm, ctrl.model.submitted, fieldname);
  }

  function isSubmittedFormError() {
    return formUtilService.isSubmittedFormError(ctrl.firmenSucheForm, ctrl.model.submitted);
  }

}
