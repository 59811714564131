// Diese Direktive vergleicht den Wert des aktuellen Elements mit einem als Parameter definierten Wert.
// Siehe die Wiederholung der Email
export const CompareToDirective = () => {
  'ngInject'; //NOSONAR

  return {
    // Die Direktive wird als Attribut an dem Element verwendet
    restrict: 'A',

    // Bei der Verwendung dieser Direktive ist die Angabe des ng-model am Element zwingend erforderlich.
    require: 'ngModel',

    // scope - Parent Scope
    // element - Element bei welchem die Direktive verwendet wird
    // attr = Dictionary aller Attribute des Elements
    // ctrl - Controller für das ngModel
    link: (scope, element, attr, ctrl) => {

      ctrl.$validators.compareTo = (val) => {
        return !val || val == scope.$eval(attr.compareTo);
      };

      scope.$watch(attr.compareTo, () => {
        ctrl.$validate();
      });

    }
  };
};
