export default class WaitingService {
  constructor($timeout) {
    'ngInject'; //NOSONAR

    this._$timeout = $timeout;
    this._waiting = 0;
  }

  showWaiting () {
    this._waiting++;
  }

  hideWaiting () {
    this._$timeout(() => {
      this._waiting--;
    }, 250);
  }

  isWaiting () {
    return this._waiting > 0;
  }
}

