export default class URLParamsService {
  constructor() {
    this.partnerId = null;
    this._partnerIdSource = null;
    this.betrag = null;
    this.laufzeit = null;
    this.referrer = null;
    this.extReferenz = null;
    this.domain = null; // VRL-1109: wird nich an den Server übertragen, da die URL nur für einen Redirect zu VRBO benötigt wird!
    this.partnerBankName = null; // VRL-1109: wird nich an den Server übertragen, wird nur für die entsprechenden Popup-Texte benötigt!
    this.u_check = null;
    this.p_check = null;
    this.u_name = null;
    this.u_str = null;
    this.u_plz = null;
    this.u_ort = null;
    this.u_gruendung = null;
    this.p_anrede = null;
    this.p_vorname = null;
    this.p_nachname = null;
    this.p_str = null;
    this.p_plz = null;
    this.p_ort = null;
    this.p_geburtsdatum = null;
    this.p_geburtsort = null;
    this.p_staatsang = null;
    this.p_mobilnr = null;
    this.p_email = null;
    this.p_kontakt = null;
    this.iban = null;
    this.ja_typ = null;
    this.ja_fkzAuspraegung = null;
    this.ja1_datum = null;
    this.ja1_umsatz = null;
    this.ja1_abschreibung = null;
    this.ja1_zinsen = null;
    this.ja1_gewinn = null;
    this.ja2_datum = null;
    this.ja2_umsatz = null;
    this.ja2_abschreibung = null;
    this.ja2_zinsen = null;
    this.ja2_gewinn = null;
  }

  isPartnerIdSource(source) {
    return this._partnerIdSource == source;
  }

  set partnerIdSource(source) {
    if (source === PartnerIdSource.URL || source === PartnerIdSource.SELECTION || source === PartnerIdSource.IBAN) {
      this._partnerIdSource = source;
    }
  }

  getData4Server() {
    return {
      partnerId: this.partnerId,
      referrer: this.referrer,
      extReferenz: this.extReferenz
    };
  }
}

export const PartnerIdSource = { URL: 'url', SELECTION: 'sel', IBAN: 'iban' };
