import _ from 'lodash';

/**
 * Enthält die Service-Funktionen für die Firmensuche,
 * mit Hilfe der die möglichen Firmen ermittelt werden.
 *
 * @param obsRequestWrapper - Enhält die Service-Funktionen für die Kommunikation mit dem Backend
 */
export default class FirmenSuchService {

  constructor(obsRequestWrapper, piwikService, popupService, messagesService, urlParamsService, $q) {
    'ngInject'; //NOSONAR

    this._$q = $q;
    this._obsRequestWrapper = obsRequestWrapper;
    this._piwikService = piwikService;
    this._popupService = popupService;
    this._messagesService = messagesService;
    this._urlParamsService = urlParamsService;

    /** Felder für die Firmen-Adresssuche */
    this.model = {
      daten: {
        name: null,
        strasse: null,
        plz: null,
        ort: null
      },
      submitted: false,
      submittedGruendungsdatum: false,
      suchergebnis: {
        keinTreffer: false,
        einTreffer: false,
        einIdentischerTreffer: false,
        mehrereTreffer: false,
        weitereAdressenVorhanden: false,
        ausgewaehlt: null,
        firmen: [],
        sichtbareFirmen: []
      },
      rechtsformPruefung: {
        zugelassen: false,
        abgelehnt: false,
        //verwendet für den Fall, dass keine bedirect-Nummer bekannt ist:
        ohneErgebnis: false,
        firmaHS:    null,
        rechtsformBWAAkzeptiert: false
      },
      showKriterien: true,
      showErgebnis: false,
      showAuswahl: false,
      showZweigstelleInfo: false,
      showGruendungsdatumEingabe: false
    };
    this.initialized = false;
  }

  initModel() {
    if (!this.initialized) {
      this.model.daten.name = (this._urlParamsService.u_name) ? this._urlParamsService.u_name : null,
        this.model.daten.strasse = (this._urlParamsService.u_str) ? this._urlParamsService.u_str : null,
        this.model.daten.plz = (this._urlParamsService.u_plz) ? this._urlParamsService.u_plz : null,
        this.model.daten.ort = (this._urlParamsService.u_ort) ? this._urlParamsService.u_ort : null,
        this.initialized = true;
    }
  }

  /**
   * Liefert den Validierungsstatus des Modells.
   * Es ist valide, wenn eine Firma ausgewählt wurde und die
   * Rechtsformprüfung erfolgreich war.
   */
  isValid() {
    return !!this.model.suchergebnis.ausgewaehlt &&
      !!this.model.suchergebnis.ausgewaehlt.gruendungsdatum &&
      this.model.rechtsformPruefung.zugelassen;
  }

  /**
   * Blendet die Suchkriterien und das Sucheergebnis ein.
   */
  sucheAnzeigen() {
    this.model.showKriterien = true;
    this.model.showErgebnis = true;
    this.model.showAuswahl = false;
    this.model.showZweigstelleInfo=false;
    this.model.showGruendungsdatumEingabe = false;
  }

  /**
   * Übernimmt die übergebene Adresse als Auswahl und zeigt sie an.
   */
  auswahlAnzeigen(firma) {
    this.model.suchergebnis.ausgewaehlt = firma;
    this.model.showKriterien = false;
    this.model.showErgebnis = false;
    this.model.showAuswahl = true;
    this.model.showGruendungsdatumEingabe = firma.gruendungsdatum === null;
    this.resetAnimation();
  }

  /**
   * Sucht mit Hilfe der für die Suche relevanten Informationen nach
   * existierenden Firmen.
   */
  sucheAusfuehren() {
    this.resetAnimation();
    this._resetErgebnisdaten();
    const model = this.model.daten;
    const requestData = {
      firmenname: model.name,
      ort: model.ort
    };
    if (model.plz) {
      requestData.plz = model.plz;
    }
    if (model.strasse) {
      requestData.strasse = model.strasse;
    }
    return this._obsRequestWrapper.sendRequest('searchCompany', {
      method: 'POST',
      data: requestData,
      contentType: false
    }, true).then((response) => {
      const result = response.data;
      if (this._getResultCount(result) == 0) {
        this._trackTreffer(0);
        this.model.suchergebnis.keinTreffer = true;
        this.model.showErgebnis = true;
      } else if (this._getResultCount(result) == 1 &&
        this._isAktuelleFirma(result[0])) {
        //keine Anzeige des Treffers, Aufrufer ist für
        //Ausführung der Rechtsformprüfung zuständig;
        this._trackTreffer(1, true);
        this.model.suchergebnis.firmen = result;
        this.model.suchergebnis.einIdentischerTreffer = true;
      } else {
        // Es wurde eine oder mehrere Adressen gefunden,
        // in diesem Fall muss der Anwender entscheiden welche
        // die richtige ist.
        this.model.showErgebnis = true;
        this._trackTreffer(result.length, false);
        this.model.suchergebnis.firmen = result;
        this.model.suchergebnis.einTreffer = result.length == 1;
        this.model.suchergebnis.mehrereTreffer = result.length > 1;

        // Die ersten drei Treffer anzeigen
        this.mehrTrefferAnzeigen();
      }
      return this.model.suchergebnis;
    });
  }

  /**
   * Prüft die Rechtsform der übergebenen Firma. Das Prüfungsergebnis
   * wird im Model abgelegt. Die Methode liefert ein Promise, auf das
   * der Aufrufer reagieren kann.
   *
   * @param firma - zu prüfende Firma
   */
  pruefeRechtsform(firma) {
    //Reset Ergebnisse vom letzten Aufruf
    this.model.rechtsformPruefung.zugelassen = false;
    this.model.rechtsformPruefung.abgelehnt = false;
    this.model.rechtsformPruefung.ohneErgebnis = false;
    this.model.rechtsformPruefung.firmaHS = null;
    //falls keine Bedirect-Nummer existiert, Abbruch
    if (!firma.beDirectNr) {
      this.model.rechtsformPruefung.ohneErgebnis = true;
      return this._$q.when(this.model.rechtsformPruefung);
    }
    return this._obsRequestWrapper.sendRequest('rechtsform', null, true, firma.beDirectNr)
      .then((response) => {
        if(response.data.firmaHS != null) {
          this.model.rechtsformPruefung.firmaHS = response.data.firmaHS;
          this.model.showZweigstelleInfo=true;
        }
        this.model.rechtsformPruefung.rechtsformBWAAkzeptiert = response.data.rechtsformBWAAkzeptiert;
        firma.gruendungsdatum = response.data.gruendungsdatum ? new Date(response.data.gruendungsdatum) : ((this._urlParamsService.u_gruendung) ? this._urlParamsService.u_gruendung : null);
        const status = response.data.status;
        this._mapRechtsformstatus(status);
        return this.model.rechtsformPruefung;
      });
  }

  /**
   * Behandlung von negativ-Fällen der Rechtsformprüfung.
   * => Öffnet entsprechendes Popup.
   */
  handleRechtsformPruefungNegativ(pruefungsErgebnis) {
    if (pruefungsErgebnis.abgelehnt) {
      this._popupService.openConfirm('rechtsformAbgelehnt');
    } else if (pruefungsErgebnis.ohneErgebnis) {
      this._popupService.openConfirm('rechtsformPruefungError');
    }
  }

  /**
   * Prüft das Gründungsdatum der übergebenen Firma. Das Prüfungsergebnis
   * wird zurückgegeben
   *
   * @param gruendungsdatum - zu prüfendes Gründungsdatum
   */
  pruefeGruendungsDatum(gruendungsdatum) {
    const maxDate = new Date().setFullYear(new Date().getFullYear() - 2);
    return gruendungsdatum ? gruendungsdatum.getTime() < maxDate : gruendungsdatum;
  }

  /**
   * Behandlung von negativ-Fällen der Unternehmensgründungsdatumprüfung.
   * => Öffnet entsprechendes Popup.
   */
  handleGruendungsdatumNegativ() {
    this._popupService.openConfirm('unternehmensgruendung');
  }

  /**
   * Zeigt die nächsten drei Treffer an.
   */
  mehrTrefferAnzeigen() {
    const neueTreffer = [];
    const animate = this.model.suchergebnis.sichtbareFirmen.length > 0;
    for (let i = 1; i <= 3; i++) {
      const naechsterTreffer = this.model.suchergebnis.firmen.shift();
      if (naechsterTreffer) {
        neueTreffer.push(naechsterTreffer);
        naechsterTreffer.isAnimated = animate;
      }
    }
    this.model.suchergebnis.sichtbareFirmen =
      this.model.suchergebnis.sichtbareFirmen.concat(neueTreffer);
    this.model.suchergebnis.weitereAdressenVorhanden =
      this.model.suchergebnis.firmen.length > 0;
    return neueTreffer;
  }

  /**
   * Setzt das Flag zum Triggern der Scale-Up-Animation an allen Treffern
   * des Suchergebnisses zurück. Beim nächsten Einblenden des Suchergebnisses
   * wird demnach die Animation nicht angezeigt.
   */
  resetAnimation() {
    const sichtbareFirmen = this.model.suchergebnis.sichtbareFirmen;
    _.forEach(sichtbareFirmen, treffer => {
      treffer.isAnimated = false;
    });
  }

  /**
   * Prüft zwei Strings auf Gleichheit, case-insensitve und getrimmt.
   *
   * @param string1
   * @param string2
   */
  _isEqual(string1, string2) {
    const emptyStrings = string1 === '' && string2 === '';
    return (string1 && string2 && string1.trim().toLowerCase() === string2.trim().toLowerCase() || emptyStrings);
  }

  /**
   * Prüft ob die übergebenen Firmendaten mit denen im Daten-Model
   * übereinstimmt.
   *
   * @param firma - zu prüfende Firmendaten
   *
   * @return true - die Daten sind identisch
   */
  _isAktuelleFirma(firma) {
    return this._isEqual(this.model.daten.strasse, firma.strasse) &&
      this._isEqual(this.model.daten.plz, firma.plz) &&
      this._isEqual(this.model.daten.ort, firma.ort) &&
      this._isEqual(this.model.daten.name, firma.nameGesamt);
  }

  /**
   * Liefert die Anzahl der gefundenen Firmen.
   *
   * @param result - Ergebnis-Array der Firmensuche
   *
   * @return count - Anzahl der gefundenen Firmen
   */
  _getResultCount(result) {
    return !result ? 0 : result.length;
  }

  /**
   * Setzt alle bisherigen Suchergebnisse bei Durchführen einer
   * neuen Suche zurück.
   */
  _resetErgebnisdaten() {
    const se = this.model.suchergebnis;
    se.weitereAdressenVorhanden = se.einTreffer = se.einIdentischerTreffer
      = se.keinTreffer = se.mehrereTreffer = false;
    se.ausgewaehlt = null;
    se.firmen = se.sichtbareFirmen = [];
  }

  /**
   * Mappt den Ergebnisstatus des Rechtsform-Service in
   * die Ergebnisstruktur des Modells.
   */
  _mapRechtsformstatus(status) {
    const model = this.model.rechtsformPruefung;
    if (status === 'ANGENOMMEN') {
      model.zugelassen = true;
    } else if (status === 'ABGELEHNT') {
      model.abgelehnt = true;
    } else {
      model.ohneErgebnis = true;
    }
  }

  /**
   * Matomo-Tracking des Treffer Ergebnisses.
   */
  _trackTreffer(count, eindeutig) {
    if (count == 0) {
      this._piwikService.trackServerErgebnis('Unternehmen prüfen', this._messagesService.getTrackEventAktion('adresseNichtGefunden'));
      this._piwikService.trackDisqualifizierungsmerkmal('Unternehmen prüfen', this._messagesService.getTrackEventAktion('adresseNichtGefunden'));
    } else if (count == 1) {
      if (eindeutig) {
        this._piwikService.trackServerErgebnis('Unternehmen prüfen', 'Adresse-gefunden-und-eindeutig');
      } else {
        this._piwikService.trackServerErgebnis('Unternehmen prüfen', 'Eine-Adresse-gefunden-aber-nicht-eindeutig');
      }
    } else {
      this._piwikService.trackServerErgebnis('Unternehmen prüfen', count + '-Adressen-gefunden');
    }
  }
}
