import template from './firmensuche-ergebnis.html';

export { FirmensucheErgebnisComponent, FirmensucheErgebnisController };

const FirmensucheErgebnisComponent = {
  template: template,
  controller: FirmensucheErgebnisController,
  bindings: {
    onSelection: '&'
  }
};

/**
 * Controller für die Anzeige der Suchergebnisse der Firmensuche.
 */
function FirmensucheErgebnisController(firmenSuchService, popupService) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.model = firmenSuchService.model;
    ctrl.popup = popup;
    ctrl.mehrTrefferAnzeigen = mehrTrefferAnzeigen;
    ctrl.trefferAuswaehlen = trefferAuswaehlen;
    ctrl.adresseNichtEnthaltenAnzeigen = adresseNichtEnthaltenAnzeigen;
    ctrl.popupAdresseNichtEnthalten = popupAdresseNichtEnthalten;
  };

  ctrl.$onDestroy = function () {
    firmenSuchService.resetAnimation();
  };

  function popup(msgKey) {
    popupService.openAlert(msgKey, 'Prozessschritt 2 - ');
  }

  function mehrTrefferAnzeigen() {
    firmenSuchService.mehrTrefferAnzeigen();
  }

  function adresseNichtEnthaltenAnzeigen() {
    return !ctrl.model.suchergebnis.weitereAdressenVorhanden &&
      !ctrl.model.suchergebnis.keinTreffer &&
      !ctrl.model.suchergebnis.einIdentischerTreffer;
  }

  /**
   * Scrollt in den Bereich der Firmen-Daten und öffnet ein Popup mit einem
   * entsprechenden Hinweis was der Anwender tun kann, wenn die eigene wenn
   * die eigene Firmenadresse nicht enthalten ist.
   */
  function popupAdresseNichtEnthalten() {
    popupService.openConfirm('adresseNichtGefunden');
  }

  /**
   * Handler bei Auswahl einer Adresse aus dem Suchergebnis.
   * @param {*} treffer
   */
  function trefferAuswaehlen(treffer) {
    firmenSuchService.pruefeRechtsform(treffer).then((pruefungsErgebnis) => {
      if (pruefungsErgebnis.zugelassen) {
        if (pruefungsErgebnis.firmaHS){
          treffer.nameGesamt = pruefungsErgebnis.firmaHS.nameGesamt;
          treffer.strasse= pruefungsErgebnis.firmaHS.strasse;
          treffer.plz= pruefungsErgebnis.firmaHS.plz;
          treffer.ort= pruefungsErgebnis.firmaHS.ort;
          treffer.beDirectNr = pruefungsErgebnis.firmaHS.beDirectNr;
        }
        _pruefeGruendungsDatum(treffer);        
      } else {
        firmenSuchService.handleRechtsformPruefungNegativ(pruefungsErgebnis);
      }
    });
  }

  /**
   * Handler bei positiver Rechtsformprüfung einer Adresse aus dem Suchergebnis.
   * @param {*} treffer
   */

  function _pruefeGruendungsDatum(treffer) {
    const pruefungsErgebnis = firmenSuchService.pruefeGruendungsDatum(treffer.gruendungsdatum);
    if (pruefungsErgebnis || pruefungsErgebnis === null) {
      firmenSuchService.auswahlAnzeigen(treffer);
      //Komponenten-Listener aufrufen, Eltern-Komponente über Auswahl informieren
      ctrl.onSelection({ selection: treffer });
    } else {
      firmenSuchService.handleGruendungsdatumNegativ();
    }
  }

}
