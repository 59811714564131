/**
 * Enthält die Service-Funktionen für die Erfassung der persönlichen Daten
 * des Anfragestellers.
 *
 * @param dateUtilService
 */
export default class PersonDatenService {

  constructor ( dateUtilService, urlParamsService ) {
    'ngInject'; //NOSONAR
    this._urlParamsService= urlParamsService;
    const minGeburtstag = dateUtilService.dayAtMidn(new Date(new Date().setFullYear(new Date().getFullYear() - 20)));

    this.model = {
      daten: {
        geschaeftsfuehrer: '',
        geschlecht: '',
        vorname: '',
        nachname: '',
        strasse: '',
        plz: '',
        ort: '',
        land: 'DE',
        nationalitaet: 'DE',
        geburtstag: '',
        geburtsort: '',
        mobilnummer: '',
        email: '',
        datenweitergabe: null
      },
      gewaehlteFirma: null,
      minDate: dateUtilService.dayAtMidn(new Date(new Date().setFullYear(new Date().getFullYear() - 100))),
      maxDate: dateUtilService.dayAtMidn(new Date()),
      minGeburtstag: minGeburtstag,
      email_wiederholung: '',
      adressenIdentisch: false,
      submitted: false,
      invalidFields: {
        personenDaten: [],
        einwilligungWerbung: []
      }
    };
    this.initialized = false;
  }

  initModel () {
    if (!this.initialized) {
      this.model.daten.geschlecht =  (this._urlParamsService.p_anrede) ? (this._urlParamsService.p_anrede) : '';
      this.model.daten.vorname =  (this._urlParamsService.p_vorname) ? (this._urlParamsService.p_vorname) :  '';
      this.model.daten.nachname = (this._urlParamsService.p_nachname) ? this._urlParamsService.p_nachname :  '';
      this.model.daten.strasse =  (this._urlParamsService.p_str) ? this._urlParamsService.p_str :  '';
      this.model.daten.plz = (this._urlParamsService.p_plz) ? this._urlParamsService.p_plz :  '';
      this.model.daten.ort =  (this._urlParamsService.p_ort) ? this._urlParamsService.p_ort :  '';
      this.model.daten.nationalitaet= (this._urlParamsService.p_staatsang) ? this._urlParamsService.p_staatsang :'DE';
      this.model.daten.geburtstag= (this._urlParamsService.p_geburtsdatum) ? this._urlParamsService.p_geburtsdatum :  '';
      this.model.daten.geburtsort= (this._urlParamsService.p_geburtsort) ? this._urlParamsService.p_geburtsort :  '';
      this.model.daten. mobilnummer= (this._urlParamsService.p_mobilnr) ? this._urlParamsService.p_mobilnr :  '';
      this.model.daten.email= (this._urlParamsService.p_email) ? this._urlParamsService.p_email :  '';
      this.model.email_wiederholung= '';
      this.model.daten.datenweitergabe = this._urlParamsService.p_kontakt;
      this.initialized = true;
    }
  }

  /**
   * Liefert den Validierungsstatus des Modells.
   * Es ist valide, wenn alle Felder des Formulars gültig sind.
   */
  isValid () {
    return this.model.invalidFields.personenDaten.length == 0 &&
      this.model.invalidFields.einwilligungWerbung.length == 0;
  }

  adressenIdentischChanged () {
    if (this.model.adressenIdentisch) {
      this.model.daten.strasse = this.model.gewaehlteFirma.strasse;
      this.model.daten.ort = this.model.gewaehlteFirma.ort;
      this.model.daten.plz = this.model.gewaehlteFirma.plz;
    }
  }

}
