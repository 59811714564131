/**
 * Mit dieser Directive ist es möglich einen HTML-Link (<a>) zu erweitern.
 *
 * Benötigt wird dies zum Beispiel für den Download eines PDFs im Safari,
 * dieser hat sich immer im gleichen Fenster geöffnet, anders als bei den
 * anderen Browsern.
 */
export const DownloadLinkDirective = (IsDemoMode) => {
  'ngInject'; //NOSONAR
  return {
    restrict: 'A',
    link: function (scope, element) {
      var ua = navigator.userAgent;
      var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      var webkit = !!ua.match(/WebKit/i);
      var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

      if (iOSSafari || IsDemoMode) {
        element.attr('target', '_blank');
      }
    }
  };
};


