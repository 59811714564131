import { domMutationObserver } from './dom-mutation-observer';

/**
 * OGR Frame Messaging
 * Setzen der Höhe und der scroll-Position via postMessage.
 *
 * Die initiale Höhe wird automatisch ausgelesen.
 * Später kann "ogrFrameMsg.send()" aufgerufen werden um nachträglich die Höhe anzupassen.
 *
 * Bsp. setzen einer Höhe in Pixel: ogrFrameMsg.send(200, false);
 * Bsp. setzen einer Scrollposition: ogrFrameMsg.send(false, 100);
 * Bsp. setzen einer Höhe und Scrollposition: ogrFrameMsg.send(200, 100);
 */
let eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent',
  eventer = window[eventMethod],
  messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message',
  is_ready = false,
  id,
  source,
  origin,
  current_height;

// auf postMessage-Anfragen hören
function eventHandler(event) {
  // Daten-String, welcher von der webCenter-Seite gesendet wird, z.b. "height-2"
  let data = event.data;

  // falls keine Daten gesendet wurde abbrechen
  if (!data || data.length === 0) {
    return;
  }

  // Daten in ein Array konvertieren
  data = data.toString().split('-');

  // das Array hat immer zwei Einträge
  if (data.length !== 2) {
    return;
  }

  // der erste Wert ist immer "height", falls nicht abbrechen
  if (data[0] !== 'height') {
    return;
  }

  // der zweite Wert ist immer numerisch, falls nicht, abbrechen
  // dies ist der Wert, mit dem wir den iframe identifizieren können
  id = parseInt(data[1], 10);
  if (isNaN(id)) {
    id = undefined;
    return;
  }

  // Quelle speichern für spätere Anfragen
  source = event.source;
  origin = event.origin;

  // alles richtig initiiert
  is_ready = true;

  // aktuelle initiale Höhe als String zur webCenter-Seite senden
  sendPostMessage(Math.ceil(document.documentElement.offsetHeight), false);

  // fortlaufende Anpassung der Höhe bei Änderungen am DOM
  domMutationObserver.start(ogrFrameMsg.resize);

  // fortlaufende Anpassung der Höhe bei window resize event
  // (orientationchange event wird nicht in iframe weitergereicht)
  let timeoutId;
  window.addEventListener('resize', () => {
    window.clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      sendPostMessage(Math.ceil(document.documentElement.offsetHeight), false);
    }, 250);
  });
}

// Format: id-höhe-scrollposition z.b. 2-300-0
function sendPostMessage(height, scroll) {
  if (!is_ready) {
    return;
  }

  // aktuelle höhe speichern
  if (typeof height === 'number') {
    current_height = height;
  }

  // string zusammensetzen
  let sendString = id + '-' + current_height;

  if (typeof scroll === 'number') {
    sendString += '-' + scroll;
  }

  source.postMessage(sendString, origin);
}

export const ogrFrameMsg = {
  initListener: function() {
    eventer(messageEvent, eventHandler, false);
  },
  send: function(height, scroll) {
    sendPostMessage(height, scroll);
  },
  resize: function(height) {
    sendPostMessage(height, false);
  },
  scrollTo: function(element, offset) {
    offset = (offset !== undefined) ? offset : 0;
    let pos = Math.max(element.getBoundingClientRect().top + offset, 0);
    sendPostMessage(false, pos);
  },
  scrollToTop: function() {
    sendPostMessage(false, 0);
  },
  runsInIframe: function() {
    return is_ready;
  }
};

