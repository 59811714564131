/**
 * Service hält das Model für die Seite Finanzkennzahlen sowie die Bankverbindung des Kunden.
 */
export default class FinanzkennzahlenService {

  /**
   * Konstruktor
   */
  constructor(urlParamsService, dateUtilService) {
    'ngInject'; //NOSONAR
    this._urlParamsService = urlParamsService;
    this._dateUtilService = dateUtilService;

    this.model = {
      daten: {
        finanzkennzahlenArt: null,
        fkzAuspraegung: null,
        bilanzstichtag1: null,
        bilanzstichtag2: null,
        umsatz1: null,
        umsatz2: null,
        abschreibung1: null,
        abschreibung2: null,
        zinsaufwand1: null,
        zinsaufwand2: null,
        gewinn1: null,
        gewinn2: null,
        iban: null
      },
      submitted: false,
      invalidFields: {
        einnahmenAusgaben: [],
        iban: []
      }
    };
    this.kdfPositiv = false;
    this.initialized = false;
    this.bwaAkzeptiert = false;
  }

  initModel() {
    if (!this.initialized) {
      this.model.daten.finanzkennzahlenArt = (this._urlParamsService.ja_typ) ? this._urlParamsService.ja_typ : null,
        this.model.daten.fkzAuspraegung = (this._urlParamsService.ja_fkzAuspraegung) ? this._urlParamsService.ja_fkzAuspraegung : null,
        this.model.daten.bilanzstichtag1 = (this._urlParamsService.ja1_datum) ? this._urlParamsService.ja1_datum : this.calculateBilanzstichtage(1),
        this.model.daten.bilanzstichtag2 = (this._urlParamsService.ja2_datum) ? this._urlParamsService.ja2_datum : this.calculateBilanzstichtage(2),
        this.model.daten.umsatz1 = (this._urlParamsService.ja1_umsatz) ? this._urlParamsService.ja1_umsatz : null,
        this.model.daten.umsatz2 = (this._urlParamsService.ja2_umsatz) ? this._urlParamsService.ja2_umsatz : null,
        this.model.daten.abschreibung1 = (this._urlParamsService.ja1_abschreibung) ? this._urlParamsService.ja1_abschreibung : null,
        this.model.daten.abschreibung2 = (this._urlParamsService.ja2_abschreibung) ? this._urlParamsService.ja2_abschreibung : null,
        this.model.daten.zinsaufwand1 = (this._urlParamsService.ja1_zinsen) ? this._urlParamsService.ja1_zinsen : null,
        this.model.daten.zinsaufwand2 = (this._urlParamsService.ja2_zinsen) ? this._urlParamsService.ja2_zinsen : null,
        this.model.daten.gewinn1 = (this._urlParamsService.ja1_gewinn) ? this._urlParamsService.ja1_gewinn : null,
        this.model.daten.gewinn2 = (this._urlParamsService.ja2_gewinn) ? this._urlParamsService.ja2_gewinn : null,
        this.model.daten.iban = (this._urlParamsService.iban) ? this._urlParamsService.iban : null,
        this.initialized = true;
    }
  }

/**
 * Initalisiert die Bilanzstichtage
 * @param {number} delayInJahren
 * @returns {Date} Bilanzstichtage
 */
  calculateBilanzstichtage(delayInJahren){
    delayInJahren += this.isBeforeReportingDate(new Date()) ? 1 : 0;
    return this._dateUtilService.getLastDayOfXYear(delayInJahren);
  }

/**
 * Prüft ob das Datum vor dem 01.10 liegt
 * @param {Date} date
 * @returns {boolean} true/false
 */
  isBeforeReportingDate(date){
    return date.getMonth() < 9;
  }

  /**
   * Prüft ob alle Modelle der Seite valide sind.
   */
  isValid() {
    return this.model.invalidFields.einnahmenAusgaben.length == 0 &&
      this.model.invalidFields.iban.length == 0 &&
      !!this.model.bank;
  }

}
