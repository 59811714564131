import _ from 'lodash';

export default class ErrorMessageService {

  constructor($q) {
    'ngInject'; //NOSONAR
    this._$q = $q;
    this._defaultError = {
      moduleName: 'Ihre Finanzierungsanfrage',
      meldungTitel: 'Der gewünschte Service steht Ihnen derzeit leider nicht zur Verfügung',
      meldungText: '<p class="is-bold is-signal">Liebe Kundin, lieber Kunde,</p>' +
      '<p>leider steht Ihnen unser Service aktuell nicht zur Verfügung. Wir arbeiten bereits an einer Lösung.</p>' +
      '<p>Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder wenden Sie sich einfach an Ihre Volksbank Raiffeisenbank vor Ort – sie steht Ihnen gerne partnerschaftlich bei Ihrer Bestellung zur Seite.</p>' +
      '<p>Vielen Dank für Ihr Verständnis.</p>'
    };
    this._navigationError = {
      moduleName: 'Ihre Finanzierungsanfrage',
      meldungTitel: 'Die gewünschte Seite kann nicht angezeigt werden',
      meldungText: '<p class="is-bold is-signal">Liebe Kundin, lieber Kunde,</p>' +
      '<p>die von Ihnen aufgerufene Seite existiert nicht oder ist nicht mehr aktuell.</p>' +
      '<p>Vielen Dank für Ihr Verständnis.</p>'
    };
    this._errorMessage = null;
  }

  setMessage(messages) {
    if (messages && _.isArray(messages) && messages.length > 0) {
      this._errorMessage = messages[0];
    }
  }

  setNavigationError() {
    this._errorMessage = this._navigationError;
  }

  getMessage() {
    return this._$q.when(this._errorMessage || this._defaultError);
  }
}
