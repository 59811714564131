import _ from 'lodash';
import './_bankauswahl.scss';

/**
 * Bereitet die Anzeige der Bankfilialen für die Anzeige auf und
 * liefert Funktionen wie Selektion und Paging.
 *
 */
export default class BankauswahlService {

  /**
   * Konstruktor
   */
  constructor() {
    'ngInject'; //NOSONAR
    this._banken = []; //Liste der Bankfilialen vom Bankfilialen-Suche-Service
    this.model = {
      banken: [], //Liste der noch nicht angezeigten Bankfilialen 
      sichtbareBanken: [], //Liste der bereits angezeigten Bankfilialen
      selectedBank: null, //die ausgewählte Bankfiliale
      hasMore: false, //Flag steuert, ob weitere Filialen zur Anzeige vorliegen
      error: false //Fehler bei nicht erfolgter Auswahl einer Bankfiliale und Klick auf CTA
    };
  }

  setBanken(banken) {
    //prüfen, ob sich die Daten geändert haben, dann Reset aller Felder
    if (banken && banken !== this._banken) {
      //Bankliste übernehmen und für 3-spaltige Darstellung aufbereiten
      this._banken = banken;
      //flache Kopie des Arrays erzeugen, da Änderungen am Array vorgenommen werden
      this.model.banken = this._banken.slice(); 
      this.model.sichtbareBanken = [];
      this.model.hasMore = this.model.banken.length > 0;
      this.model.selectedBank = null;
      this.model.error = false;
      //erste 3 Treffer anzeigen
      if (this.model.hasMore) {
        this.showMore();
      }
    } else {
      this._resetAnimation();
    }
  }

  showMore() {
    const neueTreffer = [];
    for (let i = 1; i <= 3; i++) {
      const naechsterTreffer = this.model.banken.shift();
      if (naechsterTreffer) {
        neueTreffer.push(naechsterTreffer);
        naechsterTreffer.isAnimated = true;
      }
    }
    this.model.sichtbareBanken = this.model.sichtbareBanken.concat(neueTreffer);
    this.model.hasMore =  this.model.banken.length > 0;
    return neueTreffer;
  }

  isSelected() {
    return this.model.selectedBank != null;
  }

  changeSelection(bank) {
    if (bank) {
      this.model.selectedBank = bank;
      this.model.error = false;
    }
  }

    /**
   * Setzt das Flag zum Triggern der Scale-Up-Animation an allen Treffern 
   * des Suchergebnisses zurück. Beim nächsten Einblenden des Suchergebnisses
   * wird demnach die Animation nicht angezeigt.
   */
  _resetAnimation () {
    _.forEach(this.model.sichtbareBanken, treffer => { 
      treffer.isAnimated = false; 
    });
  }

}
