import template from './persondaten.html';
import './_persondaten.scss';

export {PersonDatenComponent, PersonDatenController};

const PersonDatenComponent = {
  template: template,
  controller: PersonDatenController,
  require: {
    parent: '^^kontaktdaten'
  }
};

function PersonDatenController(scrollService, formUtilService, personDatenService) {
  'ngInject'; //NOSONAR

  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.model = personDatenService.model;
    ctrl.adressenIdentischChanged = adressenIdentischChanged;

    ctrl.isFieldError = isFieldError;
    ctrl.isSubmittedFormError = isSubmittedFormError;

    ctrl.removeOnPaste = removeOnPaste;

    ctrl.parent.addValidator(validate, 2);

    ctrl.popupGeburtsdatumUngueltigOptions = {
      area: 'persoenlicheangaben',
      message: 'inhaberZuJung'
    };
    if (!personDatenService.initialized) {
      personDatenService.initModel();
    }
  };

  function removeOnPaste(event, fieldname) {
    let expression = undefined;

    if (fieldname === 'mobilnummer') {
      // Bei der Telefonnummer müssen alle Leerzeichen entfernt werden und die führende 0
      expression = /\r?\n|\r|\s|\b0+\B/g;
    }

    formUtilService.removeOnPaste(event, ctrl.personenDatenForm, fieldname, expression);
  }

  function adressenIdentischChanged () {
    personDatenService.adressenIdentischChanged();
  }

  function validate() {
    ctrl.model.invalidFields.personenDaten = [];
    if (isFormError()) {
      ctrl.model.invalidFields.personenDaten = formUtilService.getErroneousFields(ctrl.personenDatenForm);
      scrollService.scrollTo('persoenlicheangaben');
      return false;
    }
    return true;
  }

  function isFieldError(fieldname) {
    return formUtilService.isFieldError(ctrl.personenDatenForm, ctrl.model.submitted, fieldname);
  }

  function isFormError() {
    return formUtilService.isFormError(ctrl.personenDatenForm);
  }

  function isSubmittedFormError() {
    return formUtilService.isSubmittedFormError(ctrl.personenDatenForm, ctrl.model.submitted);
  }

}
