export const ValidateAsDateDirective = ($parse, dateUtilService, scrollService, popupService) => {
  'ngInject'; //NOSONAR
  return {
    // Die Direktive wird als Attribut an dem Element verwendet
    restrict: 'A',

    // Bei der Verwendung dieser Direktive ist die Angabe des ng-model am Element zwingend erforderlich.
    require: 'ngModel',

    // scope - Parent Scope
    // element - Element bei welchem die Direktive verwendet wird
    // attr = Dictionary aller Attribute des Elements
    // ngModel - Controller für das ngModel
    link: function (scope, element, attr, ngModel) {

      const popupOnError = $parse(attr.popupOnError)(scope);
      let popupOnMinError = $parse(attr.popupOnMinError)(scope);
      if (!popupOnMinError) {
        popupOnMinError = popupOnError;
      }
      let popupOnMaxError = $parse(attr.popupOnMaxError)(scope);
      if (!popupOnMaxError) {
        popupOnMaxError = popupOnError;
      }
      const format = attr.uiMaskPlaceholder ;

      ngModel.$parsers.unshift(function (value) {
        return dateUtilService.stringToDate(value, format);
      });

      ngModel.$formatters.push(function (value) {
        return dateUtilService.dateToString(value, format);
      });

      const showPopup = function(popup) {
        scrollService.scrollTo(popup.area);
        if (popup.type && popup.type === 'alert') {
          popupService.openAlert(popup.message);
        }
        else {
          popupService.openConfirm(popup.message);
        }
      };

      ngModel.$validators.minDate = function(modelValue) {
        if (modelValue) {
          const minDate = $parse(attr.minDate)(scope);
          if (minDate) {
            const val = dateUtilService.dateWithoutTime(modelValue) >= dateUtilService.dateWithoutTime(minDate);
            if (!val && popupOnMinError) {
              showPopup(popupOnMinError);
            }
            return val;
          }
        }
        return true;
      };

      ngModel.$validators.maxDate = function(modelValue) {
        if (modelValue) {
          const maxDate = $parse(attr.maxDate)(scope);
          if (maxDate) {
            const val = dateUtilService.dateWithoutTime(modelValue) <= dateUtilService.dateWithoutTime(maxDate);
            if (!val && popupOnMaxError) {
              showPopup(popupOnMaxError);
            }
            return val;
          }
        }
        return true;
      };

    }
  };
};
