import _ from 'lodash';

const enabledKeys = [8, 9, 17, 35, 36, 37, 39, 45, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 91, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 109, 110, 173, 188, 189];

export const FinanzkennzahlInputDirective = ($filter) => {
  'ngInject'; //NOSONAR
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      minValue: '<',
      maxValue: '<'
    },
    link: function (scope, element, attr, ngModel) {

      element.bind('keydown', function (e) {
        // enabled keys and ctrl+c, ctrl+v
        const areKeysForCopyAndPastePressed = (e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 67);
        const isControlKeyPressed = (e.ctrlKey === true || e.metaKey === true);
        !_.includes(enabledKeys, e.keyCode) && !(areKeysForCopyAndPastePressed && isControlKeyPressed) &&
        e.preventDefault();
      });

      element.bind('focus', function () {
        var tempValue = ngModel.$viewValue + '';
        if (tempValue) {
          tempValue = tempValue.replace(/\./g, '');
          ngModel.$viewValue = tempValue;
          ngModel.$render();
        }
      });

      element.bind('blur', function () {
        var tempValue = ngModel.$viewValue;
        tempValue = tempValue.replace(/\./g, '').replace(/\,/g, '.');
        if (tempValue) {
          var num = _.toNumber(tempValue);
          if (_.isNumber(num)  && !isNaN(num)) {
            ngModel.$viewValue = $filter('number')(num, 0);
            ngModel.$render();
          }
        }
      });

      function finanzkennzahlParser(viewValue) {
        var tempTxt = viewValue ? (viewValue + '') : '';
        tempTxt = tempTxt.replace(/\./g, '').replace(/\,/g, '.');
        var num = !!tempTxt && _.toNumber(tempTxt);
        if (_.isNumber(num)  && !isNaN(num)) {
          //send round number to server
          return Math.round(num);
        } else {
          return undefined;
        }
      }

      function finanzkennzahlFormatter(modelValue) {
        if (_.isNumber(modelValue) && !isNaN(modelValue)) {
          return $filter('number')(modelValue, 0);
        }
        return '';
      }

      function minMaxValidator(modelValue) {
        if (ngModel.$isEmpty(modelValue)) {
          // consider empty model valid
          return true;
        }
        return modelValue >= scope.minValue && modelValue <= scope.maxValue;
      }

      ngModel.$parsers.push(finanzkennzahlParser);
      ngModel.$formatters.push(finanzkennzahlFormatter);
      ngModel.$validators.minMax = minMaxValidator;
    }
  };
};
