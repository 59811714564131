import { applikationStatusLegitimation } from '../../layout/navigation/navigation.constants';
import { PartnerIdSource } from '../../common/obs-url-params.service';

export { routes, transitionChecks };

/**
 * Definition der UI-Routes des Anfrage-Moduls
 */
function routes($urlRouterProvider, $stateProvider) {
  'ngInject'; //NOSONAR
  $urlRouterProvider.otherwise('/error');
  $stateProvider
    // Aufrufen des Kontaktformulars
    .state('kontaktformular', {
      containerClass: 'kontaktformular',
      url: '/kontaktformular',
      component: 'kontaktformular',
      resolve: {
        banken: function (bankfilialenSucheService, urlParamsService, kontaktformularService) {
          'ngInject'; //NOSONAR
          // Falls das Kontaktformular aus einer Filiale mit Partner-ID aufgerufen wird
          if(urlParamsService.partnerId != null && urlParamsService.isPartnerIdSource(PartnerIdSource.URL)) {
            if(kontaktformularService.model.bankCandidate.bankname == null) {
              kontaktformularService.model.bankCandidate.bankname = urlParamsService.partnerBankName;
              kontaktformularService.model.bankCandidate.blz = urlParamsService.partnerId;
              kontaktformularService.model.partnerId = urlParamsService.partnerId;
            }
          }
          // Falls eine PartnerId durch anwählen einer Bank vorhanden ist.
          else if(urlParamsService.partnerId != null && urlParamsService.isPartnerIdSource(PartnerIdSource.SELECTION)) {
            // Tritt auf der Legitimationsseite auf, wenn die OBS ohne PartnerId aufgerufen wird
            kontaktformularService.model.partnerId = urlParamsService.partnerId;
            return bankfilialenSucheService.getBanken({
              plz: kontaktformularService.model.personModel.gewaehlteFirma.plz,
              strasse: kontaktformularService.model.personModel.gewaehlteFirma.strasse
            });
          }
          // Falls keine PartnerId vorhanden ist und auch keine durch anwählen einer Bank übergeben wurde
          else if(urlParamsService.partnerId == null) {
            if(kontaktformularService.model.daten.absprungpunkt == 'KREDITENTSCHEIDUNG_NEGATIV'){
              return null;
            } else {
              return bankfilialenSucheService.getBanken({
                plz: kontaktformularService.model.personModel.gewaehlteFirma.plz,
                strasse: kontaktformularService.model.personModel.gewaehlteFirma.strasse
              });
            }
          }
        }
      },
      isApplicationInitState: true,
      isModalViewState: true,
      viewTitle: 'Kontaktformular'
    })
    .state('bestaetigung', {
      containerClass: 'kontaktformular',
      url: '/bestaetigung',
      component: 'bestaetigung',
      isApplicationInitState: true,
      isModalViewState: true,
      viewTitle: 'Terminbestätigung'
    });
}

function transitionChecks(navigationService) {
  'ngInject'; //NOSONAR
  navigationService.addTransitionCheck('kontaktformular', (transition, appStatus, fromState) => {
    if (appStatus === applikationStatusLegitimation) {
      //Wenn das Kontaktformular bereits abgesendet wurde, soll ein Rücksprung nicht mehr möglich sein
      transition.abort();
    }
    navigationService.preventDirectNavigation(transition, fromState, true);
  });

  navigationService.addTransitionCheck('bestaetigung', (transition, appStatus, fromState) => {
    if (appStatus !== applikationStatusLegitimation) {
      //Die Kontaktformular-Bestätigungsseite nur im AppStatus obs-legitimation anzeigen
      transition.abort();
    }
    //bei Page Reload Startseite anzeigen
    navigationService.preventDirectNavigation(transition, fromState, true);
  });
}
