/**
 * Service zum Loggen von Nachrichten an den Server.
 */
export default class LoggingService {

  constructor($http, $stateParams, endpointsService, urlParamsService, piwikService) {
    'ngInject'; //NOSONAR

    this._$http = $http;
    this._$stateParams = $stateParams;
    this._endpointsService = endpointsService;
    this._urlParamsService = urlParamsService;
    this._piwikService = piwikService;
  }

  /**
   * Loggt eine Nachricht im Level DEBUG.
   */
  debug(message, contextData) {
    this.log('DEBUG', message, contextData);
  }

  /**
   * Loggt eine Nachricht im Level INFO.
   */
  info(message, contextData) {
    this.log('INFO', message, contextData);
  }

  /**
   * Loggt eine Nachricht im Level WARNING.
   */
  warning(message, contextData) {
    this.log('WARNING', message, contextData);
  }

  /**
   * Loggt eine Nachricht im Level ERROR.
   */
  error(message, contextData) {
    this.log('ERROR', message, contextData);
  }

  /**
   * Sendet das Feedback an den Server und schreibt einen Tracking-Point
   * über die Art des Feedbacks in Matomo.
   *
   * Vorraussetzung ist, dass die Art gewählt und ein Text angegeben wurde.
   */
  log(level, message, contextData) {
    if (!!level && !!message && !this._endpointsService.useDemo) {
      const _contextData = contextData || {};
      _contextData.partnerId = this._urlParamsService.partnerId;
      _contextData.page = this._piwikService.getViewTitle();
      if (this._$stateParams.aCode) {
        _contextData.aktivierungscode = this._$stateParams.aCode;
      }
      const data = {
        level: level,
        message: message,
        logContextData: _contextData
      };
      this._$http.post(this._endpointsService.getUrl('log'), data);
    }
  }

  /**
   * Prüft ob mindestens eine Art gewählt und ein Text angegeben wurde.
   */
  _isFeedbackEmpty() {
    return !this.model.daten.art
        || !this.model.daten.text;
  }
}
