import template from './wunschtermin.html';
import './_wunschtermin.scss';

export {WunschterminComponent, WunschterminController};

const WunschterminComponent = {
  template: template,
  controller: WunschterminController,
  require: {
    parent: '^^kontaktformular'
  }
};

function WunschterminController(kontaktformularService, formUtilService, scrollService, bankauswahlService) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.showNextPageButton = showNextPageButton;
    ctrl.model = kontaktformularService.model;
    ctrl.parent.addValidator(validate);
    ctrl.isFieldError = isFieldError;
    ctrl.isSubmittedFormError = isSubmittedFormError;
    ctrl.zeitSelected = zeitSelected;
    ctrl.bankauswahl = bankauswahlService.model;

    ctrl.zeiten = kontaktformularService.model.zeiten;
  };

  function showNextPageButton() {
    if((ctrl.model.daten.wunschzeitRaeume[0] || ctrl.model.daten.wunschzeitRaeume[1]) && ctrl.model.daten.artTerminBestaetigung != null && ctrl.model.daten.datenWeitergabe) {
      ctrl.model.showNextPageButton = true;
    } else {
      ctrl.model.showNextPageButton = false;
    }
  }

  function zeitSelected(object) {
    // Befüllt das Array kontaktformularService.model.daten.wunschzeitRaeume mit booleans oder undefined.
    // Checked ist true, unchecked ist false. Falls beide unchecked ist das Array undefined.
    return Object.keys(object).some(function (key) {
      return object[key];
    });
  }

  function validate() {
    kontaktformularService.model.invalidFields.wunschtermin = [];
    if (isFormError()) {
      kontaktformularService.model.invalidFields.wunschtermin = formUtilService.getErroneousFields(ctrl.wunschterminDatenForm);
      scrollService.scrollTo('wunschtermin');
      return false;
    }
    return true;
  }

  function isFieldError(fieldname) {
    return formUtilService.isFieldError(ctrl.wunschterminDatenForm, kontaktformularService.model.submitted, fieldname);
  }

  function isFormError() {
    return formUtilService.isFormError(ctrl.wunschterminDatenForm);
  }

  function isSubmittedFormError() {
    return formUtilService.isSubmittedFormError(ctrl.wunschterminDatenForm, kontaktformularService.model.submitted);
  }
}