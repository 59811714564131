/**
 * Service zur Ermittlung der Kalkulationsparameter für Flexibel-Kalkulationen.
 *
 * @param {*} obsRequestWrapper
 */
export default class KalkulationParameterService {
  constructor(obsRequestWrapper) {
    'ngInject'; //NOSONAR
    this.obsRequestWrapper = obsRequestWrapper;
  }

  getKalkulationParameter() {
    if (this.parameterCache) {
      return this.parameterCache;
    } else {
      return this.obsRequestWrapper
        .sendRequest('kalkparams', null)
        .then((response) => {
          this.parameterCache = response.data;
          return this.parameterCache;
        });
    }
  }
}

