import template from './einwilligung-werbung.html';

export { EinwilligungWerbungComponent, EinwilligungWerbungController };

const EinwilligungWerbungComponent = {
  template: template,
  controller: EinwilligungWerbungController,
  require: {
    parent: '^^kontaktdaten'
  }
};

function EinwilligungWerbungController(popupService, scrollService, formUtilService, personDatenService) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.model = personDatenService.model;
    ctrl.popupVRSmartFinanz = popupVRSmartFinanz;
    ctrl.isFieldError = isFieldError;
    ctrl.isSubmittedFormError = isSubmittedFormError;
    ctrl.parent.addValidator(validate, 1);
  };

  function popupVRSmartFinanz() {
    popupService.openAlert('vrSmartFinanz','Prozesschritt 2 - ');
  }

  function validate() {
    ctrl.model.invalidFields.einwilligungWerbung = [];
    if (isFormError()) {
      ctrl.model.invalidFields.einwilligungWerbung = formUtilService.getErroneousFields(ctrl.einwilligungForm);
      scrollService.scrollTo('einwilligung_werbung');
      return false;
    }
    return true;
  }

  function isFieldError(fieldname) {
    return formUtilService.isFieldError(ctrl.einwilligungForm, ctrl.model.submitted, fieldname);
  }

  function isFormError() {
    return formUtilService.isFormError(ctrl.einwilligungForm);
  }

  function isSubmittedFormError() {
    return formUtilService.isSubmittedFormError(ctrl.einwilligungForm, ctrl.model.submitted);
  }
}
