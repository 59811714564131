import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import ngAnimate from 'angular-animate';
import { FooterComponent } from './obs-footer.component';

/**
 * Das Modul obs.footer ist für die Darstellung des Footers zuständig.
 */
export default angular
  .module('obs.footer', [
    ngAnimate,
    uiRouter
  ])
  .component('obsFooter', FooterComponent)
  .name;
