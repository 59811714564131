import angular from 'angular';
import commonModule from '../../common/common.module';
import componentModule from '../../components/components.module';
import { ErrorComponent } from './error-page.component';
import routes from './error-page-routes';

export default angular
  .module('obs.error', [
    commonModule,
    componentModule
  ])
  .component('error', ErrorComponent)
  .config(routes)
  .name;

