export const TrackingDirective = (piwikService) => {
  'ngInject'; //NOSONAR
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      const event = attrs.obsTracking;

      element.bind(event, () => {

        if (event === 'click') {
          piwikService.trackElementClick(attrs.matomoName, attrs.ngValue);
        }
        else if (event === 'change') {
          piwikService.trackElementChange(attrs.matomoName, attrs.ngValue);
        }

      });
    }
  };
};
