export { obsGlobals, obsConfig, obsInit, initStateChange, initErrorHandler, applikationStatus };

/**
 * Ein globales Datenobjekt, in dem anwendungsweite Statusinformationen gehalten werden.
 */
const applikationStatus = {
  status: null,
  initialized: false,
  eingangskanal: 'OBS',
  erfassungsmodus: 'KUNDE',
  lob: 'BANKP',
  ulob: 'BPOBS',
  isEingangskanalOBS: function() { return this.eingangskanal === 'OBS'; },
  isEingangskanalLOS: function() { return this.eingangskanal === 'LOS'; },
  isHvb: function () { return this.lob === 'HVB'; }
};

/**
 * Setzen von globalen Konstanten und Values, die modulübergreifend gelten.
 * @param {*} rootModule
 */
function obsGlobals(rootModule) {
  const isProd = process.env.NODE_ENV == 'production';
  const isDemo = process.env.IS_DEMO_MODE === true;
  const serverUrl = isProd ? '@apigw.url@' : '';
  rootModule.constant('ServerUrl', isDemo ? 'demo' : serverUrl);
  rootModule.constant('trackingUrl', isProd ? '@tracking.url@' : '//stats.vr-smart-finanz.de/');
  rootModule.constant('trackingId', isProd ? '@tracking.id@' : '5');
  rootModule.constant('IsDebug', isProd ? false : true);
  rootModule.constant('IsDemoMode', isDemo);
  rootModule.value('applikationStatus', applikationStatus);
}

/**
 * Konfigurationsroutine beim Start der Anwendung (module.config()).
 */
function obsConfig($compileProvider, $locationProvider, $logProvider, IsDebug) {
  'ngInject'; //NOSONAR
  // Debug-Info abschalten für produktive Builds, siehe
  // https://docs.angularjs.org/guide/production#disabling-debug-data
  $compileProvider.debugInfoEnabled(IsDebug);
  // Das folgende Flag wird benötigt, um die Routing-Probleme
  // die mit dem Wechsel von AngularJS 1.5 auf AngularJS 1.6 resultieren zu lösen.
  $locationProvider.hashPrefix('');
  // wir bekommen evtl. einen String und keinen boolean
  // da @replaceProperty@ keine gültiger Bezeichner ist.
  $logProvider.debugEnabled(IsDebug);
}

/**
 * Initialisierung beim Start der Anwendung, gültig für alle Entry-Points (module.run()).
 */
function obsInit($rootScope, $location, endpointsService, applikationStatus, waiting, IsDemoMode) {
  'ngInject'; //NOSONAR
  //Variablen im root-scope hinterlegen, um auch aus Templates (index.html/teaser.html) darauf zugreifen zu können
  $rootScope.applikationStatus = applikationStatus;
  $rootScope.waiting = waiting;
  $rootScope.cookieconsent = false;

  //Verwendung von Mock-Services unterstützen (Development+Test)
  endpointsService.useMocks = $location.search().MOCK;
  endpointsService.useDemo = IsDemoMode;
  if (!endpointsService.useMocks) {
    endpointsService.useMocks = $location.search().LOCAL_MOCK;
    if (endpointsService.useMocks) {
      endpointsService.localMocks = true;
    }
  }

}

/**
 * Initialisiert die Aktionen, die bei einem State-Change, also einem Wechsel der View, auszuführen sind.
 * Dies beinhaltet:
 * - Behandlung von modalen Seiten (Seiten, die jederzeit aufgerufen werden können und beim Schließen zur vorherigen Seite führen)
 * - Prüfung auf Zulässigkeit der Transition durch Validierung im navigationService
 * - Setzen von Metadaten zur Erkennung der aktuellen View in Templates (z. B. für Styling)
 * - Scrollen zum Anfang der Seite
 * - Matomo Tracking
 *
 * @param {*} $transitions
 * @param {*} $rootScope
 * @param {*} navigationService
 * @param {*} scrollService
 * @param {*} piwikService
 * @param {*} applikationStatus
 */
function initStateChange($transitions, $rootScope, navigationService, scrollService, piwikService, applikationStatus) {
  'ngInject'; //NOSONAR
  let landingPageTracked = false;

  //Listener bei einem State-Change (Seitenwechsel)
  $transitions.onStart({}, function (transition) {
    const stateTo = transition.to();
    const stateFrom = transition.from();
    //Behandlung von modalen Views
    handleModalView(stateFrom, transition.params('from'), stateTo);
    //Prüfe, ob der State-Change erlaubt ist und verhindere ihn falls nötig
    navigationService.canGoToState(transition, stateFrom.name, stateTo.name);
    //bei erfolgreichem State-Change:
    transition.promise.then(function() {
      const stateName = stateTo.name;
      //containerClass ermöglicht seitenspezifische Styles
      const containerClass = stateTo.containerClass;
      if (containerClass) {
        $rootScope.containerClass = containerClass;
      }
      //Hervorhebung des aktiven Navigationspunktes
      navigationService.setActiveItem(stateName);
      //Bei Maskenwechsel an den Seitenanfang scrollen (nicht bei Erstaufruf, da sonst Probleme
      //bei iFrame Einbindung)
      if (applikationStatus.status != null) {
        scrollService.scrollToTop();
      }
      //Matomo Tracking (bei Landingpage = Aufruf-URL die komplette URL mitgeben, für Kampagnen-Parameter etc.)
      let pageUrl = stateTo.name;
      if (!landingPageTracked) {
        pageUrl = window.location.href;
        landingPageTracked = true;
      }
      piwikService.pageTrack(stateTo.viewTitle || stateTo.name, pageUrl);
      //Beim ersten Laden der Anwendung: Ausblenden des Wartesymbols nach Erreichen des
      //finalen Routing-States
      if (stateTo.isApplicationInitState === true) {
        applikationStatus.initialized = true;
      }
    }, angular.noop);
  });
}

/**
 * Initialisiert den Default-Error-Handler, der im Falle eines Fehlers auf
 * die Fehlerseite navigiert.
 * @param {} $state
 */
function initErrorHandler($state) {
  'ngInject'; //NOSONAR
  //Default Error-Handler -> auf Fehlerseite navigieren
  const defaultErrorHandler = $state.defaultErrorHandler();
  $state.defaultErrorHandler(function(error) {
    defaultErrorHandler(error);
    $state.go('error');
  });
}

/**
 * Behandelt State-Wechsel von und zu modalen Views, d.h. Masken, die nicht
 * im Prozess-Screenflow enthalten sind, sondern jederzeit geöffnet werden können und
 * beim Schließen den vorhergehenden View wiederherstellen.
 *
 * @param {*} stateFrom
 * @param {*} stateFromParams
 * @param {*} stateTo
 */
function handleModalView(stateFrom, stateFromParams, stateTo) {
  //bei Wechsel zu einem modalen View (= nicht Bestandteil des Prozess-Screenflows, z.B. Fehlerseite)
  //soll der bisherige View-Zustand gemerkt werden, um diesen später wiederherstellen zu können
  if (stateTo.isModalViewState && !stateTo.previousUiState) {
    stateTo.previousUiState = stateFrom.name;
    stateTo.previousUiStateParams = stateFromParams;
  }
  //bei Wechsel von einem modalen View zurück zur Vorgängermaske muss die Historie im modalen View gelöscht werden
  if (stateFrom.isModalViewState && stateTo.name == stateFrom.previousUiState) {
    stateFrom.previousUiState = null;
    stateFrom.previousUiStateParams = null;
  }
}
