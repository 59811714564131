
export const ACCESS_TOKEN_NAME = 'OBS-API-ACCESS-TOKEN';

export default class ObsTokenService {

  constructor() {
    'ngInject'; //NOSONAR
    this.zugangscode = null;
  }

  getAccessTokenHeader(zugangscode){
    return {
      [ACCESS_TOKEN_NAME]: this.encodeZugangscode(zugangscode ? zugangscode : this.zugangscode)
    };
  }

  encodeZugangscode(zugangscode){
    return btoa(zugangscode);
  }

  hasZugangscode(){
    return !!this.zugangscode;
  }

  setZugangscode(zugangscode){
    this.zugangscode = zugangscode;
  }
}
