import template from './obs-prozessstatus.html';

export {ProzessstatusComponent, ProzessstatusController};

const ProzessstatusComponent = {
  controller: ProzessstatusController,
  bindings: {
    name: '@',
    hideContact:'='
  },
  template: template,
  transclude: {
    'footer': '?statusFooter'
  }
};

function ProzessstatusController(messagesService, popupService) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.messagesService = messagesService;
    ctrl.popupVRSmartFinanz = popupVRSmartFinanz;
  };

  function popupVRSmartFinanz() {
    popupService.openAlert('vrSmartFinanz');
  }
}
