import template from './obs-info-card.html';
import './_obs-info-card.scss';

// Komponenten, welche auf der Bootstrap CARD-Komponente basiert
export const InfoCardComponent = {
  bindings: {
    title: '@',
    text: '@',
    boxStyle: '@',
    boxIcon: '@',
    showHaken: '@'
  },
  template: template,
  transclude: true
};
