export const NumberFilter = ($filter) => {
  'ngInject'; //NOSONAR
  return function (input, places) {
    if (isNaN(input)) {
      return input;
    }
    places = input == Math.floor(input) ? 0 : places;
    return $filter('number')(input, places);
  };
};

