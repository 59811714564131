import _ from 'lodash';
import { applikationStatusBeantragung, applikationStatusAbschluss } from './navigation.constants';

export default class NavigationService {

  constructor ($state, popupService, errorMessageService, applikationStatus) {
    'ngInject'; //NOSONAR

    this._state = $state;
    this._popupService = popupService;
    this._errorService = errorMessageService;
    this._applikationStatus = applikationStatus;
    this._transitionChecks = {};

    /**
     * Definition der Prozessanzeigeleiste inkl. Logik, welche Prozessschritte
     * navigierbar sind. Ein Prozessschritt kann über mehrere Masken laufen (z.B. "finanzkennzahlen").
     * Für jeden Prozessschritt wird ein Eintrag erstellt:
     *
     * name:    eindeutiger Bezeichner des Prozessschritts bzw. der dazugehörigen Maske.
     *          Ist dem Prozessschritt nur eine Maske zugeordnet, dann
     *          entspricht dieser Name auch dem zugehörigen UI-Router Status
     *          (siehe $stateProvider.state in routes.js)
     * title:   Bezeichnung des Prozessschritts in der Prozessanzeigeleiste
     * stepId: Nummer des Prozessschritts, der in der Prozessanzeigeleiste sichtbar ist
     * pageId: alle am Prozess beteiligten Masken erhalten eine fortlaufende Numerierung, um
     *          die Einhaltung der Reihenfolge beim Navigieren überprüfen zu können; alternativ
     *          anzuzeigende Masken wie kdfPositiv und kdfNegativ erhalten dieselbe Nummer
     * states:  Optional. Sind dem Prozssschritt mehrere Masken zugeordnet, dann sind im Array
     *          die zugehörigen UI-Router States enthalten (siehe $stateProvider.state in routes.js)
     *
     */
    this.processSteps = [
      {name: 'finanzierungsvorhaben', title: 'Finanzierungsvorhaben', stepId: 1, pageId: 1},
      {name: 'kontaktdaten', title: 'Kontaktdaten', stepId: 2, pageId: 2},
      {name: 'finanzkennzahlen', title: 'Unternehmen', stepId: 3,
        states: [
          { name: 'finanzkennzahlen', pageId: 3 },
          { name: 'kdfNegativ', pageId: 4 }
        ]
      },
      {name: 'legitimation', title: 'Legitimation', stepId: 4,
        states: [
          { name: 'kdfPositiv', pageId: 4 },
          { name: 'legitimation', pageId: 5 },
          { name: 'videoLegitimationFailover', pageId: 5 }
        ]
      },
      {name: 'abschluss', title: 'Abschluss', stepId: 5, pageId: 6}
    ];

    this.activeStepId = 0; //Index des zur Zeit aktiven Prozessschritts
  }

  /**
   * Bindet eine Prüffunktion an einen gegebenen state.
   * Diese Prüffunkfion muss dann erfolgreich durchlaufen werden, um den Übergang
   * in diesen state zu erlauben.
   */
  addTransitionCheck (toState, checkFn) {
    if (toState && _.isFunction(checkFn)) {
      this._transitionChecks[toState] = checkFn;
    }
  }

  canGoToState (transition, fromState, toState) {
    this._popupService.close();
    const appStatus = this._applikationStatus.status;
    if (toState && this._transitionChecks.hasOwnProperty(toState) &&
      this._isNavigable(transition, fromState, toState)) {
      this._transitionChecks[toState](transition, appStatus, fromState);
    } else {
      transition.abort();
    }
  }

  /**
   * Liefert die Nummer des Prozessschritts in der Prozessanzeigeleiste
   * für einen UI-Router State Namen. Falls der UI State nicht gefunden wird,
   * gibt die Funktion 0 zurück.
   * @param {*} stateName
   */
  _getStepIdByStateName (stateName) {
    if (stateName) {
      const step = _.find(this.processSteps, function (processStep) {
        return (processStep.states && _.find(processStep.states, { 'name': stateName }) ||
          processStep.name === stateName);
      });
      if (step) {
        return step.stepId;
      }
    }
    return 0;
  }

  /**
   * Liefert die Nummer der Prozess-Maske für einen UI-Router State Namen;
   * falls dieser nicht gefunden wird (UI State nicht definiert oder gehört
   * nicht dem Prozess an), gibt die Funktion 0 zurück.
   *
   * @param {*} stateName
   */
  _getPageIdByStateName (stateName) {
    let pageId = 0;
    if (stateName) {
      _.forEach(this.processSteps, function (processStep) {
        if (processStep.states) {
          const subState = _.find(processStep.states, { 'name': stateName });
          if (subState) {
            pageId = subState.pageId;
            return;
          }
        } else if (processStep.name === stateName) {
          pageId = processStep.pageId;
          return;
        }
      });
    }
    return pageId;
  }

  /**
   * Prüft, ob eine Navigation von einem Prozessschritt zu einem anderen Prozessschritt erlaubt ist.
   * Die Navigation zurück zu einem früheren Prozessschritt ist immer erlaubt.
   * Die Navigation nach vorn ist nur zum nächsten Prozessschritt erlaubt, nicht jedoch das Überspringen
   * von Prozessschritten. Unterbunden wird außerdem die Navigation per Eingabe in der Adresszeile des Browsers, falls
   * sie über den maximalen bisher erreichten Prozessschritt hinausführt.
   * Damit soll vermieden werden, dass Validierungslogiken übersprungen werden, die normalerweise durch
   * Klick auf die Navigationsbuttons in der Maske (z.B. "Zurück", "Weiter") ausgeführt werden.
   * Die Navigation von und zu nicht Prozess-relevanten UI-States (z.B. error, partner) ist immer erlaubt.
   * @param {*} transition
   * @param {*} fromStateName
   * @param {*} toStateName
   */
  _isNavigable (transition, fromStateName, toStateName) {
    const idFrom = this._getPageIdByStateName(fromStateName);
    const idTo = this._getPageIdByStateName(toStateName);
    const isProcessNavigation = this.activeStepId > 0 && !transition.from().isModalViewState && idTo > 0;
    const transitionSource = transition.options().source; //'unknown' bei direktem API-Aufruf $state.go()
    //bei Navigation über die Anwendung darf max. der nächste Process-Step erreicht werden:
    const isNextProcessStep = transitionSource === 'unknown' && idTo <= (idFrom + 1);
    //bei Navigation über Back/Forward/Adresszeile im Browser darf nur zurücknavigiert werden:
    const isPreviousProcessStep = transitionSource != 'unknown' && idTo <= idFrom;

    return !isProcessNavigation || isNextProcessStep || isPreviousProcessStep;
  }

  /*
    * Setzt den Index des selektierten Hauptmenueintrags.
    * Als Parameter wird der UI-Router Statusname des neuen Prozessschritts übergeben.
    * Dieser wird mit der Konfiguration (processSteps) abgeglichen.
    * Dabei wird unterschieden, ob ein Prozessschritt einen oder mehrere Status besitzt.
    */
  setActiveItem (toStateName) {
    this.activeStepId = this._getStepIdByStateName(toStateName);
  }

  /**
   * Schließt einen modalen View und kehrt in den vorher angezeigten
   * View zurück. Falls es keinen vorherigen View gibt, wird die Startseite der Anwendung
   * angesteuert.
   */
  closeModalView () {
    if (this._state.current.previousUiState) {
      this._state.go(this._state.current.previousUiState, this._state.current.previousUiStateParams || {});
    } else {
      this.navigateToStartPage();
    }
  }

  /**
   * Navigiert zur ersten Seite (Finanzierungsvorhaben) der Anwendung.
   * Dies ist nur im Anfragebereich der Anwendung sinnvoll.
   */
  navigateToStartPage () {
    //wenn wir über den Entry-Point abschluss.entry.js eingestiegen sind, können wir nicht
    //zur Startseite der Anwendung navigieren, da sie nicht im Webpack Bundle enthalten ist
    //und es auch keinen Sinn macht, den Anwender aus dem Abschlussbereich zurück in den
    //Anfragebereich zu schicken; daher als Fallback eine Fehlerseite ("die aufgerufene Seite
    //kann nicht angezeigt werden") anzeigen
    if (process.env.IS_ABSCHLUSS_ENTRY) {
      this._applikationStatus.status = applikationStatusAbschluss;
      this._errorService.setNavigationError();
      this._state.go('error');
    } else {
      this._applikationStatus.status = applikationStatusBeantragung;
      this._state.go('finanzierungsvorhaben');
    }
  }

  /**
   * Verhindert, dass per UI-Routing direkt eine Seite angesteuert wird.
   * Dies kann durch manuelle Eingabe der URL oder durch Page-Refresh im Browser
   * passieren. Bei Angabe von redirect === true wird auf die Startseite
   * der Anwendung umgeleitet. Ansonsten verbleibt die Anwendung auf der aktuellen Seite.
   */
  preventDirectNavigation (transition, fromState, redirectToStartPage) {
    //wenn kein fromState vorhanden ist, handelt es sich um eine direkte Navigation
    if (!fromState) {
      transition.abort();
      if (redirectToStartPage) {
        this.navigateToStartPage();
      }
    }
  }

}
