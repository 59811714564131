/**
 * Dieser Service enthält Funktionen für das Kontaktformular und
 * dient als Wrapper für untergeordnete Services, die den einzelnen
 * Seitenbereichen (Bankfiliale, Kontaktpraeferenz etc.) zugeordnet sind.
 */
export default class KontaktformularService {

  constructor(obsRequestWrapper, $q, urlParamsService, uuidService, $log, applikationStatus, interessentenService) {
    'ngInject'; //NOSONAR

    this._obsRequestWrapper = obsRequestWrapper;
    this._$q = $q;
    this._$log = $log;
    this._urlParamsService = urlParamsService;
    this._uuidService = uuidService;
    this._applikationStatus = applikationStatus;
    this._interessentenService =interessentenService;

    // daten.anfragedaten wird in kdf-positiv.components.js gesetzt
    // personModel wird benötigt für die Banksuche in kontaktformular-routes.js
    this.model = {
      partnerId: null,
      partnerBankName: null,
      showWunschtermin: false,
      showNextPageButton: false,
      submitted: false,
      cachedPartnerId: null,
      cachedGetBankInfoAnfrage: null,
      abschlussVorgang: null,
      aCode: null,
      eingangskanal: 'OBS',
      personModel: {
        gewaehlteFirma: {
          plz: '',
          strasse: ''
        }
      },
      bankCandidate: {
        bankname: '',
        blz: '',
        ort: '',
        plz: '',
        strasse: '',
        thumbnailUrl: 'https://www.vr-dive.de/images/bank_logos/vr_bank.png'
      },
      daten: {
        anfragedaten: '',
        absprungpunkt: '',
        wunschzeitRaeume: [],
        artTerminBestaetigung: '',
        datenWeitergabe: false,
        nachricht: ''
      },
      invalidFields: {
        filiale: [],
        wunschtermin: []
      },
      zeiten: [
        {
          title: '09:30 - 13:00'
        },
        {
          title: '14:30 - 18:00'
        }
      ]
    };
  }

  sendKontaktanfrage() {
    this.model.daten.wunschzeitRaeume.forEach((item, i) => {
      if (item && (i == 0 || i == 1)) {
        this.model.daten.wunschzeitRaeume[i] = this.model.zeiten[i].title;
      } else {
        this.model.daten.wunschzeitRaeume[i] = '';
      }
    });

    let interessentenAnfrageRequest;
    const uuidv4 = this._uuidService.uuidv4();

    // Im Absprungpunkt LEGITIMATION UND KDF_NEGATIV
    if (this.model.daten.absprungpunkt != 'KREDITENTSCHEIDUNG_NEGATIV') {
      const requestData = this.model.daten.anfragedaten;
      interessentenAnfrageRequest = {
        'vertriebsPartnerBlz': this.model.partnerId,
        'anfrageContainer': {
          finanzierung: requestData.finanzierung,
          firma: requestData.firma,
          anfrageSteller: requestData.anfrageSteller,
          jahresabschlussDaten: requestData.jahresabschlussDaten
        },
        'kontaktDaten': {
          'absprungpunkt': this.model.daten.absprungpunkt,
          'wunschzeitRaeume': this.model.daten.wunschzeitRaeume,
          'artTerminBestaetigung': this.model.daten.artTerminBestaetigung,
          'datenWeitergabe': this.model.daten.datenWeitergabe,
          'nachrichtAnBank': this.model.daten.nachricht
        },
        'anfrageID': uuidv4,
        'iCode': this._interessentenService.iCode
      };
    }
    // Absprungpunkt KREDITENTSCHEIDUNG_NEGATIV im Abschluss
    else {

      interessentenAnfrageRequest = {
        'vertriebsPartnerBlz': this.model.partnerId,
        'kontaktDaten': {
          'absprungpunkt': this.model.daten.absprungpunkt,
          'wunschzeitRaeume': this.model.daten.wunschzeitRaeume,
          'artTerminBestaetigung': this.model.daten.artTerminBestaetigung,
          'datenWeitergabe': this.model.daten.datenWeitergabe,
          'nachrichtAnBank': this.model.daten.nachricht,
          'aktivierungsCode': this.model.aCode
        },
        'anfrageID': uuidv4
      };
    }

    return this._obsRequestWrapper.sendRequest('kontaktanfrage', {
      method: 'POST',
      timeout: 120000,
      useCustomErrorHandler: false,
      data: interessentenAnfrageRequest,
      addServerInfo: true
    })
      .then((responseKontaktanfrage) => {
        return responseKontaktanfrage;
      })
      .catch((responseKontaktanfrage) => {
        return this._$q.reject(responseKontaktanfrage);
      });
  }

  /**
   * setzt die PartnerId zurück
   */
  resetCachedPartnerId() {
    this.model.cachedPartnerId = null;
  }

  /**
   * setzt die cachedGetBankInfoAnfrage zurück
   */
  resetCachedGetBankInfoAnfrage() {
    this.model.cachedGetBankInfoAnfrage = null;
  }

  /**
   * Mit dieser Methode bekommen wir die Informationen der gesuchten Bank.
   * @param partnerId - Die PartnerId oder BLZ mit der die Informationen der Bank abgerufen werden.
   */
  getBankinformationen(partnerId) {
    if (this.model.cachedGetBankInfoAnfrage !== partnerId) {
      this._obsRequestWrapper.sendRequest('searchBank', {
        method: 'POST',
        data: {
          blz: partnerId
        }
      }).then((response) => {
        this.model.cachedGetBankInfoAnfrage = partnerId;
        this.model.bankCandidate.bankname = response.data.name;
        this.model.bankCandidate.blz = response.data.blz;

        // Die BMw liefert den Ort mit PLZ und die Straße mit Nr
        if (response.data.ort != null && response.data.strasse != null) {
          this.model.bankCandidate.ort = response.data.ort;
          this.model.bankCandidate.strasse = response.data.strasse;
        }

        this._$log.debug('[SUCCESS] searchBank response: ');
        this._$log.debug(response.data);
        return response;
      }, (err) => {
        this._$log.debug('Fehler bei Banksuche: ' + err);
        // Falls die Anfrage nicht funktioniert, wähle die Werte aus den urlParams
        if (this._urlParamsService.partnerId != null) {
          this.model.bankCandidate.bankname = this._urlParamsService.partnerBankName;
          this.model.bankCandidate.blz = this._urlParamsService.partnerId;
        }
        // Falls im Abschluss wähle die Werte aus dem Vorgang
        else if (this.model.abschlussVorgang.partnerId != null) {
          this.model.bankCandidate.bankname = this.model.abschlussVorgang.partnerBankName;
          this.model.bankCandidate.blz = this.model.abschlussVorgang.partnerId;
        }

        this.resetCachedGetBankInfoAnfrage();
      });
    }
  }

  /**
   * Liefert den Validierungsstatus des Modells.
   * Es ist valide, wenn alle Felder des Formulars gültig sind.
   */
  isValid() {
    return this.model.invalidFields.filiale.length == 0 &&
      this.model.invalidFields.wunschtermin.length == 0;
  }

  starteKontaktformular(state, window, kontaktdatenService) {
    // Falls keine PartnerId angegeben ist und noch keine Bank ausgewählt wurde, speichere die PLZ und Straße der gewählten Firma
    // Die Daten werden im kontaktformular-routes.js für die Banksuche verwendet
    this.model.personModel.gewaehlteFirma.plz = kontaktdatenService.model.personModel.gewaehlteFirma.plz;
    this.model.personModel.gewaehlteFirma.strasse = kontaktdatenService.model.personModel.gewaehlteFirma.strasse;
    state.go('kontaktformular');
  }
}
