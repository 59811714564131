import template from './kdf-negativ.html';

export {KdfNegativComponent, KdfNegativController};


const KdfNegativComponent = {
  template: template,
  controller: KdfNegativController,
  bindings: {
    banken: '<'
  }
};

/**
 * Controller für die Maske bei negativer KDF-Prüfung.
 */
function KdfNegativController($state, $window, urlParamsService, anfragedatenService, piwikService, kontaktformularService, kontaktdatenService, bankauswahlService) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.model = kontaktformularService.model;
    ctrl.goToPrevPage = goToPrevPage;
    ctrl.goToNextPage = goToNextPage;
    ctrl.buttonText = 'Jetzt Terminanfrage starten';
    ctrl.getAnsprache = getAnsprache;

    // Schreibe die aktuelle PartnerId in den Kontaktformularservice
    ctrl.model.partnerId = urlParamsService.partnerId;
    // Falls keine Bank mit PartnerId aufgerufen wurde setzte die nächstgelegene Bank zur Adresse des Kunden als Kontaktbank
    if(ctrl.banken != null){
      ctrl.model.partnerId = ctrl.banken[0].blz;
      bankauswahlService.setBanken(ctrl.banken);
      bankauswahlService.changeSelection(ctrl.banken[0]);
    }
    // Setze die Bank für das Kontaktformular
    kontaktformularService.getBankinformationen(ctrl.model.partnerId);
  };

  /*
    * Zurück-Button
    */
  function goToPrevPage() {
    $state.go('finanzkennzahlen');
  }

  /*
   * Weiter zum Kontaktformular
   */
  function goToNextPage() {
    piwikService.trackElementClick(ctrl.buttonText);
    starteKontaktformular();
  }

  function starteKontaktformular() {
    ctrl.model.daten.absprungpunkt = 'KDF_NEGATIV';

    // Übergebe Anfragedaten an Kontaktformular
    ctrl.model.daten.anfragedaten = anfragedatenService.getAnfrageRequest();

    kontaktformularService.starteKontaktformular($state, $window, kontaktdatenService);
  }

  function getAnsprache() {
    const data = anfragedatenService.getAnfrageRequest();
    const vorname = data.anfrageSteller.vorname;
    const nachname = data.anfrageSteller.nachname;
    return 'Guten Tag ' + vorname + ' ' + nachname + ',';
  }

}
