import template from './obs-bonimanager.html';
import './_obs-bonimanager.scss';

export { BoniManagerComponent, BoniManagerController };

const BoniManagerComponent = {
  bindings: {
    matomoAction: '@',
    campaignKeyword: '@'
  },
  template: template,
  controller: BoniManagerController
};

/**
 * Controller für die Komponente zur Anzeige des Absprungs zum Bonitätsmanager
 *
 * @param popupService
 * @param messagesService
 *
 * @returns
 */
function BoniManagerController(popupService, messagesService) {
  'ngInject'; //NOSONAR
  const ctrl = this;
  const boniManagerBaseUrl = 'https://www.bonitaetsmanager.de/?pk_campaign=OBS_Abspruenge_zum_BoniM';

  ctrl.$onInit = function () {
    ctrl.messagesService = messagesService;
    ctrl.popup = popup;
    ctrl.matomoAction = ctrl.matomoAction || 'Absprung zum Bonitätsmanager';
    ctrl.boniManagerUrl = boniManagerBaseUrl + (ctrl.campaignKeyword ? '&pk_kwd=' + ctrl.campaignKeyword : '');
  };

  function popup(type) {
    popupService.openAlert(type);
  }
}
