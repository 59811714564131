/**
 * Service zum Halten der asynchron erhaltenen URL zur Legitimierung.
 */
export default class VideoLegitimationService {

  /**
   * Konstruktor
   */
  constructor() {
    this.legitimierungLink = null;
  }

}
