import template from './finanzierung-hinweise.html';

/**
 * Am Anfang der Strecke werden dem Anwender notwendige Hinweise zu Dokumenten und Datenschutz angezeigt,
 * welche er für den weiteren Verlauf der OBS benötigt.
 */
export { FinanzierungHinweiseComponent, FinanzierungHinweiseController };

const FinanzierungHinweiseComponent = {
  template: template,
  controller: FinanzierungHinweiseController
};

function FinanzierungHinweiseController(popupService) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.popupSteuerberaterClicked = popupSteuerberaterClicked;
  };

  function popupSteuerberaterClicked() {
    popupService.openAlert('steuerberater', 'Prozesschritt 1 - ');
  }

}
