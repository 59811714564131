import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import ngAnimate from 'angular-animate';
import componentModule from '../../components/components.module';
import commonModule from '../../common/common.module';
import { InteressentComponent } from './interessent.component';
import { InteressentCodeUngueltigComponent } from './interessent-code-ungueltig.component';
import finanzierungsvorhabenModule from '../../anfrage/finanzierungsvorhaben/finanzierungsvorhaben.module';
import unternehmenModule from '../../anfrage/unternehmen/unternehmen.module';
import kontaktdatenModule from '../../anfrage/kontaktdaten/kontaktdaten.module';

/**
 * Modul obs.finanzierungsvorhaben stellt die Einstiegsmaske der OBS bereit.
 * Der Anwender gibt seinen Finanzierungswunsch ein.
 */
export default angular
  .module('obs.interessent', [
    ngAnimate,
    uiRouter,
    commonModule,
    componentModule,
    finanzierungsvorhabenModule,
    kontaktdatenModule,
    unternehmenModule
  ])
  .component('interessent', InteressentComponent)
  .component('interessentCodeUngueltig', InteressentCodeUngueltigComponent)
  .name;

