/**
 * Service für die Ermittlung der Höhe der Finanzierung ("Finanzierungswunsch")
 * anhand einer näherungsweisen Kalkulation.
 */
export default class FinanzierungswunschService {

  constructor(financialService, urlParamsService, pricingService){
    'ngInject'; //NOSONAR

    this._financialService = financialService;
    this._urlParamsService = urlParamsService;
    this._pricingService = pricingService;

    this.daten = {
      finanzierungsparameter: {
        betragSlider: null,
        rateSlider: null,
        laufzeitSlider: null,
        konditionen: null,
        berechnungsartBudgetrechner: false
      },
      kalkulation: {
        zinssatz: 0,
        laufzeit: 0,
        result: 0
      }
    };
    this.initialized = false;
  }

  /**
   * Initialisiert das Model des Services mit den Kalkulationsparametern vom Server.
   */
  initModel (kalkulationParameter) {
    if (!this.initialized) {
      //Models für die Slider Finanzierungsbetrag, Wunschrate und Bonität erzeugen
      const finParams = this.daten.finanzierungsparameter;
      finParams.betragSlider = this._createSliderModel(false, false, false, kalkulationParameter.betragParameter);
      finParams.rateSlider = this._createSliderModel(false, false, false, kalkulationParameter.rateParameter);
      finParams.laufzeitSlider = this._createSliderModel(false, false, false, kalkulationParameter.laufzeitParameter);
      //Konditionen zur Kalkulation von Wunschrate / Finanzierungsbetrag
      finParams.konditionen = kalkulationParameter.konditionen;
      //Übernahme des Finanzierungsbetrags bei Übergabe als URL-Parameter oder Cookie;
      if (this._urlParamsService.betrag) {
        finParams.berechnungsartBudgetrechner = false;
        let partnerBetrag = this._urlParamsService.betrag;
        //bei Verletzung der Grenzwerte auf Defaultwert gehen
        if (partnerBetrag < finParams.betragSlider.options.floor ||
          partnerBetrag > finParams.betragSlider.options.ceil) {
          partnerBetrag = kalkulationParameter.betragParameter.selectedValue;
        }
        this._setSliderValue(finParams.betragSlider, partnerBetrag);
      }
      //Übernahme der Laufzeit bei Übergabe als URL-Parameter oder Cookie;
      if (this._urlParamsService.laufzeit) {
        let lz = this._urlParamsService.laufzeit;
        //bei Verletzung der Grenzwerte auf Defaultwert gehen
        if (lz < finParams.laufzeitSlider.options.floor ||
          lz > finParams.laufzeitSlider.options.ceil) {
          lz = kalkulationParameter.laufzeitParameter.selectedValue;
        }
        this._setSliderValue(finParams.laufzeitSlider, lz);
      }
      //Initiale Laufzeit für Kalkulation setzen
      this.daten.kalkulation.laufzeit = finParams.laufzeitSlider.value;
      //initialen Zins aus der Zinsliste ermitteln und damit kalkulieren
      this._pricingService.setZinstableau(finParams.konditionen.zinsTableau);
      this._initKalkulation();
      this.initialized = true;
    }
  }

  /**
   * Die Berechnungsart hat sich geändert.
   * -> erneute Kalkulation durchführen
   */
  berechnungsartGeaendert () {
    this._kalkulieren();
  }

  /**
   * Der Wert eines Betrag-Schiebereglers (Wunschrate oder Finanzierungsbetrag) hat sich geändert;
   * -> erneute Kalkulation durchführen
   */
  betragGeaendert () {
    this._kalkulieren();
  }

  /**
   * Bei einer Änderung der Laufzeit werden die Grenzen für den Wunschrate-Slider
   * neu berechnet/gesetzt und neu kalkuliert.
   */
  laufzeitGeaendert () {
    const laufzeitAlt = this.daten.kalkulation.laufzeit;
    const laufzeitNeu = this.daten.finanzierungsparameter.laufzeitSlider.value;
    //Erkennung von Laufzeitänderung, nur dann neu kalkulieren
    if (laufzeitAlt !== laufzeitNeu) {
      this.daten.kalkulation.laufzeit = this.daten.finanzierungsparameter.laufzeitSlider.value;
      //Neuberechnung der min/max-Werte des Wunschrate Sliders
      this._setMinMaxWunschrate();
      //Kalkulation mit geänderten Parametern durchführen
      this._kalkulieren();
    }
  }

  /**
   * Berechnet die Konditionen für die aktuelle Auswahl an Kalkulationsparametern
   */
  _kalkulieren() {
    //debugging: console.log('kalkulieren');
    const laufzeit = this.daten.kalkulation.laufzeit;
    let betrag = this.daten.finanzierungsparameter.betragSlider.value;
    const zinssatz = this.daten.kalkulation.zinssatz = this._getZinssatz(betrag, laufzeit);
    if (this.daten.finanzierungsparameter.berechnungsartBudgetrechner) {
      const rateMtl = this.daten.finanzierungsparameter.rateSlider.value;
      const gerundetAuf = this.daten.finanzierungsparameter.konditionen.rundungBetrag;
      const result = this._financialService.berechneBarwert(rateMtl, zinssatz, laufzeit, gerundetAuf);
      this.daten.kalkulation.result = result;
      //Übernahme des Ergebnisses als Voreinstellung für den Betrag-Slider;
      //dabei müssen jedoch die min/max-Werte des Sliders berücksichtigt werden
      this._setSliderValue(this.daten.finanzierungsparameter.betragSlider, result);
    } else {
      const finBetrag = this.daten.finanzierungsparameter.betragSlider.value;
      const gerundetAuf = this.daten.finanzierungsparameter.konditionen.rundungRate;
      const result = this._financialService.berechneAnnuitaet(finBetrag, zinssatz, laufzeit, gerundetAuf);
      this.daten.kalkulation.result = result;
      //Übernahme des Ergebnisses als Voreinstellung für den Rate-Slider;
      //dabei müssen jedoch die min/max-Werte des Sliders berücksichtigt werden
      this._setSliderValue(this.daten.finanzierungsparameter.rateSlider, result);
    }
  }

  /**
   * Erzeugt ein leeres Model für einen Schieberegler (Slider); es werden nur Slider-Options
   * gesetzt, die von den globalen Options (siehe app.js) abweichen
   */
  _createSliderModel(hideMinLimitLabel, hideMaxLimitLabel, hideMaxLimitValue, values) {
    const model = {
      options: {
        hideMinLimitLabel: hideMinLimitLabel,
        hideMaxLimitLabel: hideMaxLimitLabel,
        hideMaxLimitValue: hideMaxLimitValue
      }
    };
    if (values) {
      model.value = values.selectedValue;
      model.options.step = values.intervall;
      model.options.floor = values.minValue;
      model.options.ceil = values.maxValue;
    }
    return model;
  }

  /**
   * Erstmalige Kalkulation bei Aufruf.
   * Ermittelt das Zins-Objekt aus der Zinsliste mit dem niedrigesten Zinssatz
   * und belegt das Kalkulationsobjekt vor. Anschließend werden die Min/Max-Werte
   * des Wunschrate-Schiebereglers neu berechnet und eine erste Kalkulation durchgeführt.
   */
  _initKalkulation() {
    this.daten.kalkulation.zinssatz = this.daten.finanzierungsparameter.konditionen.zinssatz;
    //Neuberechnung der min/max-Werte des Wunschrate Sliders
    this._setMinMaxWunschrate();
    //Kalkulation mit geänderten Parametern durchführen
    this._kalkulieren();
  }

  /**
   * Setzt im Model die Grenzwerte (min / max) für den Wunschrate-Slider, damit die Ergebnisse einer
   * Berechnung nicht die vorgegebenen Grenzen des flexibel Produkts bezüglich der Darlehenssumme
   * unter-/übersteigen. Zur Ermittlung werden zwei Kalkulationen (berechneWunschrate) mit dem aktuellen
   * Zinssatz, der aktuellen Laufzeit und den Grenzwerten der Darlehenssumme (5000/60000) durchgeführt und
   * das Ergebnis in das Slider-Model übernommen.
   */
  _setMinMaxWunschrate() {
    const rateSchrittweite = this.daten.finanzierungsparameter.rateSlider.options.step;
    const minBetrag = this.daten.finanzierungsparameter.betragSlider.options.floor;
    const maxBetrag = this.daten.finanzierungsparameter.betragSlider.options.ceil;
    const laufzeit = this.daten.kalkulation.laufzeit;
    let minWunschrate = this._kalkuliereWunschrate(minBetrag, laufzeit);
    let maxWunschrate = this._kalkuliereWunschrate(maxBetrag, laufzeit);
    minWunschrate = this._financialService.runden(minWunschrate, rateSchrittweite, 1); //Ergebnis aufgerundet auf nächsten Schritt
    maxWunschrate = this._financialService.runden(maxWunschrate, rateSchrittweite, -1); //Ergebnis abgerundet auf nächsten Schritt
    this.daten.finanzierungsparameter.rateSlider.options.floor = minWunschrate;
    this.daten.finanzierungsparameter.rateSlider.options.ceil = maxWunschrate;
    //der aktuelle Wert des Rate-Sliders muss ggf. geändert werden, wenn er nicht innerhalb
    //der neuen Grenzwerte liegt
    const wunschrate = this.daten.finanzierungsparameter.rateSlider.value;
    this._setSliderValue(this.daten.finanzierungsparameter.rateSlider, wunschrate);
  }

  _kalkuliereWunschrate(betrag, laufzeit) {
    return this._financialService.berechneAnnuitaet(betrag, this._getZinssatz(betrag, laufzeit), laufzeit);
  }

  _getZinssatz(betrag, laufzeit) {
    let zinssatz;
    if(this._isZinsTableuVorhanden()){
      zinssatz = this._pricingService.getZinssatz(betrag, laufzeit);
    }
    return !zinssatz ? this.daten.kalkulation.zinssatz : zinssatz;
  }

  /**
   * Setzt den aktuellen Wert eines Sliders und berücksichtigt die min/max-Grenzen.
   * Der Wert wird ggf. angepasst, um in die Begrenzungen zu fallen.
   * @param {*} sliderModel
   * @param {*} value
   */
  _setSliderValue(sliderModel, value) {
    value = Math.max(sliderModel.options.floor, value);
    value = Math.min(sliderModel.options.ceil, value);
    sliderModel.value = value;
  }

  _isZinsTableuVorhanden() {
    return !!this.daten.finanzierungsparameter.konditionen.zinsTableau;
  }
}
