import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import ngAnimate from 'angular-animate';
import uiMask from 'angular-ui-mask';
import componentModule from '../../components/components.module';
import commonModule from '../../common/common.module';
import KontaktdatenService from './kontaktdaten.service';
import FirmenSuchService from './unternehmen/firmensuche.service';
import PersonDatenService from './person/persondaten.service';
import { KontaktdatenComponent } from './kontaktdaten.component';
import { UnternehmensangabenComponent } from './unternehmen/unternehmensangaben.component';
import { FirmensucheKriterienComponent } from './unternehmen/firmensuche-kriterien.component';
import { FirmensucheErgebnisComponent } from './unternehmen/firmensuche-ergebnis.component';
import { FirmensucheAuswahlComponent } from './unternehmen/firmensuche-auswahl.component';
import { PersonDatenComponent } from './person/persondaten.component';
import { EinwilligungWerbungComponent } from './person/einwilligung-werbung.component';

/**
 * Das Modul obs.kontaktdaten stellt die Kontaktdatenseite zur Verfügung.
 */
export default angular
  .module('obs.kontaktdaten', [
    ngAnimate,
    uiRouter,
    commonModule,
    componentModule,
    uiMask
  ])
  .service('kontaktdatenService', KontaktdatenService)
  .service('firmenSuchService', FirmenSuchService)
  .service('personDatenService', PersonDatenService)
  .component('kontaktdaten', KontaktdatenComponent)
  .component('unternehmensangaben', UnternehmensangabenComponent)
  .component('firmensucheKriterien', FirmensucheKriterienComponent)
  .component('firmensucheErgebnis', FirmensucheErgebnisComponent)
  .component('firmensucheAuswahl', FirmensucheAuswahlComponent)
  .component('personDaten', PersonDatenComponent)
  .component('einwilligungWerbung', EinwilligungWerbungComponent)
  .name;
