import _ from 'lodash';

export default class EndpointsService {
  constructor(ServerUrl) {
    'ngInject'; //NOSONAR
    this._serverUrl = ServerUrl;
    this._endpoints = {
      searchCompany: { url: '/api/firma/suche/v1' },
      searchBank: { url: '/api/bank/suche/v1' },
      countries: { url: '/api/stammdaten/laenderliste/v1' },
      flexibel: { url: '/api/anfrage/flexibel/v1' },
      kdfPruefung: { url: '/api/anfrage/flexibel/kdfPruefung/v1' },
      aktivierung: { url: '/api/aktivierung/v1' },
      rechtsform: { url: '/api/anfrage/flexibel/rechtsformPruefung/v1' },
      kalkulation: { url: '/api/kalkulation/flexibel/v1' },
      kalkparams: { url: '/api/kalkulation/flexibel/parameter/v1' },
      bestellung: { url: '/api/bestellung/flexibel/v1' },
      einwilligung: { url: '/api/einwilligung/flexibel/v1' },
      abschluss: { url: '/api/abschluss/flexibel/v1' },
      upload: { url: '/api/upload/v1' },
      pdfStatic: { url: '/api/druck/static/v1' },
      pdfVorgang: { url: '/api/druck/vorgang/v1' },
      pdfTemp: { url: '/api/druck/pdf/v1' },
      bankauswahl: { url: '/api/bank/filialsuche/obs/v1' },
      kontaktanfrage: { url: '/api/interessentenAnfrage/transfer/v1' },
      kalkulationRspPakete: { url: '/api/kalkulation/flexibel/rspPakete/v1' },
      log: { url: '/api/log/v1' },
      interessent: { url: '/api/anfrage/flexibel/interessent/v1' }
    };
    this.useDemo = false;
    this.useMocks = false;
    this.localMocks = false;
  }

  _getMockUrl(path, endpointName, useMocks, localMocks) {
    if (useMocks === true || useMocks === 'true' || _.includes(useMocks, endpointName)) {
      var pathArr = path.split('/');
      var version = pathArr.pop();
      pathArr.push(localMocks === true ? 'LOCAL_MOCK' : 'MOCK');
      pathArr.push(version);
      return pathArr.join('/');
    } else {
      return path;
    }
  }

  getUrl(endpointName) {
    var path = this._endpoints[endpointName].url;
    return this._serverUrl + ((this.useMocks) ? this._getMockUrl(path, endpointName, this.useMocks, this.localMocks) : path);
  }

  getDocumentUrl(endpointName, documentType, pathSuffix) {
    let url = this.getUrl(endpointName) + '/' + documentType;
    if (pathSuffix) {
      url = url + '/' + pathSuffix;
    }
    if (this.useDemo) {
      url = url + '.pdf';
    }
    return url;
  }

  getDemoEndpointHandler(endpointName) {
    return this._endpoints[endpointName].demoHandler;
  }

  addDemoEndpointHandler(endpointName, handler) {
    this._endpoints[endpointName].demoHandler = handler;
  }

}
