export const SpecialCharacterValidateDirective = () => {
  'ngInject'; //NOSONAR
  return {
    // Die Direktive wird als Attribut an dem Element verwendet
    restrict: 'A',

    // Bei der Verwendung dieser Direktive ist die Angabe des ng-model am Element zwingend erforderlich.
    require: 'ngModel',

    // scope - Parent Scope
    // element - Element bei welchem die Direktive verwendet wird
    // attr = Dictionary aller Attribute des Elements
    // ctrl - Controller für das ngModel
    link: function (scope, element, attr, ctrl) {
      ctrl.$validators.validateSpecialCharacter = (val) => {
        if(!val){
          return true;
        }
        const specialCharacter = /[<>()$%@#&§|+*~^]/g;
        const globalRegex = new RegExp(specialCharacter);
        return !globalRegex.test(val);
      };
    }
  };
};
