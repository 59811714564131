export const land = [
  {
    isocode: 'AF',
    land: 'Afghanistan'
  },{
    isocode: 'EG',
    land: 'Ägypten'
  },{
    isocode: 'AL',
    land: 'Albanien'
  },{
    isocode: 'DZ',
    land: 'Algerien'
  },{
    isocode: 'VI',
    land: 'Amerikanische Jungferninseln'
  },{
    isocode: 'AS',
    land: 'Amerikanisch-Samoa'
  },{
    isocode: 'AD',
    land: 'Andorra'
  },{
    isocode: 'AO',
    land: 'Angola'
  },{
    isocode: 'AI',
    land: 'Anguilla'
  },{
    isocode: 'AG',
    land: 'Antigua und Barbuda'
  },{
    isocode: 'GQ',
    land: 'Äquatorialguinea'
  },{
    isocode: 'SY',
    land: 'Arabische Republik Syrien'
  },{
    isocode: 'AR',
    land: 'Argentinien'
  },{
    isocode: 'AM',
    land: 'Armenien'
  },{
    isocode: 'AW',
    land: 'Aruba'
  },{
    isocode: 'AZ',
    land: 'Aserbaidschan'
  },{
    isocode: 'ET',
    land: 'Äthiopien'
  },{
    isocode: 'AU',
    land: 'Australien'
  },{
    isocode: 'BS',
    land: 'Bahamas'
  },{
    isocode: 'BH',
    land: 'Bahrain'
  },{
    isocode: 'BD',
    land: 'Bangladesch'
  },{
    isocode: 'BB',
    land: 'Barbados'
  },{
    isocode: 'BE',
    land: 'Belgien'
  },{
    isocode: 'BZ',
    land: 'Belize'
  },{
    isocode: 'BJ',
    land: 'Benin'
  },{
    isocode: 'BM',
    land: 'Bermuda'
  },{
    isocode: 'BT',
    land: 'Bhutan'
  },{
    isocode: 'VE',
    land: 'Bolivarische Republik Venezuela'
  },{
    isocode: 'BO',
    land: 'Bolivien'
  },{
    isocode: 'BA',
    land: 'Bosnien und Herzegowina'
  },{
    isocode: 'BW',
    land: 'Botsuana'
  },{
    isocode: 'BV',
    land: 'Bouvetinsel'
  },{
    isocode: 'BR',
    land: 'Brasilien'
  },{
    isocode: 'VG',
    land: 'Britische Jungferninseln'
  },{
    isocode: 'IO',
    land: 'Britisches Territorium im Indischen Ozean'
  },{
    isocode: 'BN',
    land: 'Brunei Darussalam'
  },{
    isocode: 'BG',
    land: 'Bulgarien'
  },{
    isocode: 'BF',
    land: 'Burkina Faso'
  },{
    isocode: 'BI',
    land: 'Burundi'
  },{
    isocode: 'CV',
    land: 'Cabo Verde'
  },{
    isocode: 'CL',
    land: 'Chile'
  },{
    isocode: 'CN',
    land: 'China'
  },{
    isocode: 'CK',
    land: 'Cookinseln'
  },{
    isocode: 'CR',
    land: 'Costa Rica'
  },{
    isocode: 'CI',
    land: 'Côte d’Ivoire'
  },{
    isocode: 'DK',
    land: 'Dänemark'
  },{
    isocode: 'KP',
    land: 'Demokratische Volksrepublik Korea'
  },{
    isocode: 'LA',
    land: 'Demokratische Volksrepublik Laos'
  },{
    isocode: 'DE',
    land: 'Deutschland'
  },{
    isocode: 'DM',
    land: 'Dominica'
  },{
    isocode: 'DO',
    land: 'Dominikanische Republik'
  },{
    isocode: 'DJ',
    land: 'Dschibuti'
  },{
    isocode: 'EC',
    land: 'Ecuador'
  },{
    isocode: 'MK',
    land: 'ehemalige jugoslawische Republik Mazedonien'
  },{
    isocode: 'SV',
    land: 'El Salvador'
  },{
    isocode: 'ER',
    land: 'Eritrea'
  },{
    isocode: 'EE',
    land: 'Estland'
  },{
    isocode: 'FK',
    land: 'Falklandinseln (Malwinen)'
  },{
    isocode: 'FO',
    land: 'Färöer'
  },{
    isocode: 'FJ',
    land: 'Fidschi'
  },{
    isocode: 'FI',
    land: 'Finnland'
  },{
    isocode: 'FM',
    land: 'Föderierte Staaten von Mikronesien'
  },{
    isocode: 'FR',
    land: 'Frankreich'
  },{
    isocode: 'TF',
    land: 'Französische Süd- und Antarktisgebiete'
  },{
    isocode: 'GF',
    land: 'Französisch-Guayana'
  },{
    isocode: 'PF',
    land: 'Französisch-Polynesien'
  },{
    isocode: 'GA',
    land: 'Gabun'
  },{
    isocode: 'GM',
    land: 'Gambia'
  },{
    isocode: 'GE',
    land: 'Georgien'
  },{
    isocode: 'GH',
    land: 'Ghana'
  },{
    isocode: 'GI',
    land: 'Gibraltar'
  },{
    isocode: 'GD',
    land: 'Grenada'
  },{
    isocode: 'GR',
    land: 'Griechenland'
  },{
    isocode: 'GL',
    land: 'Grönland'
  },{
    isocode: 'GP',
    land: 'Guadeloupe'
  },{
    isocode: 'GU',
    land: 'Guam'
  },{
    isocode: 'GT',
    land: 'Guatemala'
  },{
    isocode: 'GN',
    land: 'Guinea'
  },{
    isocode: 'GW',
    land: 'Guinea-Bissau'
  },{
    isocode: 'GY',
    land: 'Guyana'
  },{
    isocode: 'HT',
    land: 'Haiti'
  },{
    isocode: 'HM',
    land: 'Heard und McDonaldinseln'
  },{
    isocode: 'HN',
    land: 'Honduras'
  },{
    isocode: 'HK',
    land: 'Hongkong'
  },{
    isocode: 'IN',
    land: 'Indien'
  },{
    isocode: 'ID',
    land: 'Indonesien'
  },{
    isocode: 'IQ',
    land: 'Irak'
  },{
    isocode: 'IE',
    land: 'Irland'
  },{
    isocode: 'IR',
    land: 'Islamische Republik Iran'
  },{
    isocode: 'IS',
    land: 'Island'
  },{
    isocode: 'IL',
    land: 'Israel'
  },{
    isocode: 'IT',
    land: 'Italien'
  },{
    isocode: 'JM',
    land: 'Jamaika'
  },{
    isocode: 'JP',
    land: 'Japan'
  },{
    isocode: 'YE',
    land: 'Jemen'
  },{
    isocode: 'JO',
    land: 'Jordanien'
  },{
    isocode: 'KY',
    land: 'Kaimaninseln'
  },{
    isocode: 'KH',
    land: 'Kambodscha'
  },{
    isocode: 'CM',
    land: 'Kamerun'
  },{
    isocode: 'CA',
    land: 'Kanada'
  },{
    isocode: 'KZ',
    land: 'Kasachstan'
  },{
    isocode: 'QA',
    land: 'Katar'
  },{
    isocode: 'KE',
    land: 'Kenia'
  },{
    isocode: 'KG',
    land: 'Kirgisistan'
  },{
    isocode: 'KI',
    land: 'Kiribati'
  },{
    isocode: 'CC',
    land: 'Kokosinseln'
  },{
    isocode: 'CO',
    land: 'Kolumbien'
  },{
    isocode: 'KM',
    land: 'Komoren'
  },{
    isocode: 'CG',
    land: 'Kongo'
  },{
    isocode: 'XK',
    land: 'Kosovo'
  },{
    isocode: 'HR',
    land: 'Kroatien'
  },{
    isocode: 'CU',
    land: 'Kuba'
  },{
    isocode: 'KW',
    land: 'Kuwait'
  },{
    isocode: 'LS',
    land: 'Lesotho'
  },{
    isocode: 'LV',
    land: 'Lettland'
  },{
    isocode: 'LB',
    land: 'Libanon'
  },{
    isocode: 'LR',
    land: 'Liberia'
  },{
    isocode: 'LY',
    land: 'Libyen'
  },{
    isocode: 'LI',
    land: 'Liechtenstein'
  },{
    isocode: 'LT',
    land: 'Litauen'
  },{
    isocode: 'LU',
    land: 'Luxemburg'
  },{
    isocode: 'MO',
    land: 'Macau'
  },{
    isocode: 'MG',
    land: 'Madagaskar'
  },{
    isocode: 'MW',
    land: 'Malawi'
  },{
    isocode: 'MY',
    land: 'Malaysia'
  },{
    isocode: 'MV',
    land: 'Malediven'
  },{
    isocode: 'ML',
    land: 'Mali'
  },{
    isocode: 'MT',
    land: 'Malta'
  },{
    isocode: 'MA',
    land: 'Marokko'
  },{
    isocode: 'MH',
    land: 'Marshallinseln'
  },{
    isocode: 'MQ',
    land: 'Martinique'
  },{
    isocode: 'MR',
    land: 'Mauretanien'
  },{
    isocode: 'MU',
    land: 'Mauritius'
  },{
    isocode: 'YT',
    land: 'Mayotte'
  },{
    isocode: 'MX',
    land: 'Mexiko'
  },{
    isocode: 'MC',
    land: 'Monaco'
  },{
    isocode: 'MN',
    land: 'Mongolei'
  },{
    isocode: 'ME',
    land: 'Montenegro'
  },{
    isocode: 'MS',
    land: 'Montserrat'
  },{
    isocode: 'MZ',
    land: 'Mosambik'
  },{
    isocode: 'MM',
    land: 'Myanmar'
  },{
    isocode: 'NA',
    land: 'Namibia'
  },{
    isocode: 'NR',
    land: 'Nauru'
  },{
    isocode: 'NP',
    land: 'Nepal'
  },{
    isocode: 'NC',
    land: 'Neukaledonien'
  },{
    isocode: 'NZ',
    land: 'Neuseeland'
  },{
    isocode: 'NI',
    land: 'Nicaragua'
  },{
    isocode: 'NL',
    land: 'Niederlande'
  },{
    isocode: 'AN',
    land: 'Niederländische Antillen'
  },{
    isocode: 'NE',
    land: 'Niger'
  },{
    isocode: 'NG',
    land: 'Nigeria'
  },{
    isocode: 'NU',
    land: 'Niue'
  },{
    isocode: 'MP',
    land: 'Nördliche Marianen'
  },{
    isocode: 'NF',
    land: 'Norfolkinsel'
  },{
    isocode: 'NO',
    land: 'Norwegen'
  },{
    isocode: 'AQ',
    land: 'Norwegisches Antarktis-Territorium'
  },{
    isocode: 'OM',
    land: 'Oman'
  },{
    isocode: 'AT',
    land: 'Österreich'
  },{
    isocode: 'PK',
    land: 'Pakistan'
  },{
    isocode: 'PS',
    land: 'Palästinensisches Gebiet'
  },{
    isocode: 'PW',
    land: 'Palau'
  },{
    isocode: 'PA',
    land: 'Panama'
  },{
    isocode: 'PG',
    land: 'Papua-Neuguinea'
  },{
    isocode: 'PY',
    land: 'Paraguay'
  },{
    isocode: 'PE',
    land: 'Peru'
  },{
    isocode: 'PH',
    land: 'Philippinen'
  },{
    isocode: 'PN',
    land: 'Pitcairninseln'
  },{
    isocode: 'PL',
    land: 'Polen'
  },{
    isocode: 'PT',
    land: 'Portugal'
  },{
    isocode: 'PR',
    land: 'Puerto Rico'
  },{
    isocode: 'KR',
    land: 'Republik Korea'
  },{
    isocode: 'MD',
    land: 'Republik Moldau'
  },{
    isocode: 'RE',
    land: 'Réunion'
  },{
    isocode: 'RW',
    land: 'Ruanda'
  },{
    isocode: 'RO',
    land: 'Rumänien'
  },{
    isocode: 'RU',
    land: 'Russische Föderation'
  },{
    isocode: 'SB',
    land: 'Salomonen'
  },{
    isocode: 'ZM',
    land: 'Sambia'
  },{
    isocode: 'WS',
    land: 'Samoa'
  },{
    isocode: 'SM',
    land: 'San Marino'
  },{
    isocode: 'ST',
    land: 'São Tomé und Príncipe'
  },{
    isocode: 'SA',
    land: 'Saudi-Arabien'
  },{
    isocode: 'SE',
    land: 'Schweden'
  },{
    isocode: 'CH',
    land: 'Schweiz'
  },{
    isocode: 'SN',
    land: 'Senegal'
  },{
    isocode: 'RS',
    land: 'Serbien'
  },{
    isocode: 'SC',
    land: 'Seychellen'
  },{
    isocode: 'SL',
    land: 'Sierra Leone'
  },{
    isocode: 'ZW',
    land: 'Simbabwe'
  },{
    isocode: 'SG',
    land: 'Singapur'
  },{
    isocode: 'SK',
    land: 'Slowakei'
  },{
    isocode: 'SI',
    land: 'Slowenien'
  },{
    isocode: 'SO',
    land: 'Somalia'
  },{
    isocode: 'ES',
    land: 'Spanien'
  },{
    isocode: 'LK',
    land: 'Sri Lanka'
  },{
    isocode: 'SH',
    land: 'St. Helena, Ascension und Tristan da Cunha'
  },{
    isocode: 'KN',
    land: 'St. Kitts und Nevis'
  },{
    isocode: 'LC',
    land: 'St. Lucia'
  },{
    isocode: 'PM',
    land: 'St. Pierre und Miquelon'
  },{
    isocode: 'VC',
    land: 'St. Vincent und die Grenadinen'
  },{
    isocode: 'ZA',
    land: 'Südafrika'
  },{
    isocode: 'SD',
    land: 'Sudan'
  },{
    isocode: 'GS',
    land: 'Südgeorgien und die südlichen Sandwichinseln'
  },{
    isocode: 'SR',
    land: 'Suriname'
  },{
    isocode: 'SJ',
    land: 'Svalbard und Jan Mayen'
  },{
    isocode: 'SZ',
    land: 'Swasiland'
  },{
    isocode: 'TJ',
    land: 'Tadschikistan'
  },{
    isocode: 'TW',
    land: 'Taiwan'
  },{
    isocode: 'TH',
    land: 'Thailand'
  },{
    isocode: 'TL',
    land: 'Timor-Leste'
  },{
    isocode: 'TG',
    land: 'Togo'
  },{
    isocode: 'TK',
    land: 'Tokelau'
  },{
    isocode: 'TO',
    land: 'Tonga'
  },{
    isocode: 'TT',
    land: 'Trinidad und Tobago'
  },{
    isocode: 'TD',
    land: 'Tschad'
  },{
    isocode: 'CZ',
    land: 'Tschechien'
  },{
    isocode: 'TN',
    land: 'Tunesien'
  },{
    isocode: 'TR',
    land: 'Türkei'
  },{
    isocode: 'TM',
    land: 'Turkmenistan'
  },{
    isocode: 'TC',
    land: 'Turks- und Caicosinseln'
  },{
    isocode: 'TV',
    land: 'Tuvalu'
  },{
    isocode: 'UG',
    land: 'Uganda'
  },{
    isocode: 'UA',
    land: 'Ukraine'
  },{
    isocode: 'HU',
    land: 'Ungarn'
  },{
    isocode: 'UY',
    land: 'Uruguay'
  },{
    isocode: 'UZ',
    land: 'Usbekistan'
  },{
    isocode: 'VU',
    land: 'Vanuatu'
  },{
    isocode: 'VA',
    land: 'Vatikanstadt'
  },{
    isocode: 'AE',
    land: 'Vereinigte Arabische Emirate'
  },{
    isocode: 'TZ',
    land: 'Vereinigte Republik Tansania'
  },{
    isocode: 'US',
    land: 'Vereinigte Staaten'
  },{
    isocode: 'GB',
    land: 'Vereinigtes Königreich'
  },{
    isocode: 'VN',
    land: 'Vietnam'
  },{
    isocode: 'WF',
    land: 'Wallis und Futuna'
  },{
    isocode: 'CX',
    land: 'Weihnachtsinsel'
  },{
    isocode: 'BY',
    land: 'Weißrussland'
  },{
    isocode: 'EH',
    land: 'Westsahara'
  },{
    isocode: 'CF',
    land: 'Zentralafrikanische Republik'
  },{
    isocode: 'CY',
    land: 'Zypern'
  }
];
