/**
 * Bietet finanzmathematische Funktionen an.
 */
export default class FinancialService {

  /**
   * Führt eine nachschüssige Barwertberechnung anhand der gewünschten Rate mit gegebenen Zins und Laufzeit aus
   * und rundet (optional) das Ergebnis auf die gegebene Genauigkeit.
   * Mit dem Parameter abweichungErstesIntervall wird ein verkürzter oder verlängerter Zeitraum vom Start der 
   * Berechnung bis zur ersten Zinszahlung dargestellt (Beispiele siehe Funktion _berechneBarwert)
   * 
   * @param {*} rate die monatliche Rate als Betrag, z. B. 250
   * @param {*} zinssatz der jährliche Zinssatz in Prozent, z. B. 6.25
   * @param {*} laufzeit die Laufzeit des Vertrags in Monaten, z. B. 36
   * @param {*} gerundetAuf optional: Rundungsgenauigkeit, z. B. 250
   * @param {*} abweichungErstesIntervall optional: gibt an, um welchen Faktor die Länge des ersten Intervalls
   * vom Standard (d.h. ein Monat) abweicht
   */
  berechneBarwert(rate, zinssatz, laufzeit, gerundetAuf, abweichungErstesIntervall) {
    if (abweichungErstesIntervall) {
      return this.runden(this._berechneBarwert(rate, zinssatz, laufzeit, abweichungErstesIntervall), gerundetAuf);
    }
    var zinssatzMtl = this.getZinssatzMtl(zinssatz); //Umrechnung des Jahreszinses auf mtl. Zahlungen
    var result = rate * ( (1 - Math.pow(1 + zinssatzMtl, -laufzeit)) / zinssatzMtl ); 
    //console.log('barwert:' + result);
    return this.runden(result, gerundetAuf);
  }

  /**
   * Führt eine nachschüssige Barwertberechnung mit abweichendender Länge des ersten Intervalls aus.
   * 
   * Beispiele zur Berechnung der Abweichung nach den Regeln von SAP CML: 
   * 
   * Beispiel 1:
   * das Darlehen beginnt mit dem 15.01.; da 10 Arbeitstage Vorlauffrist eingerechnet werden, kann die erste Rate 
   * noch im Januar erfolgen, am 31.01.; es besteht also ein verkürztes erstes Intervall, vom 15.01. bis 31.01.;
   *   abweichungErstesIntervall = (31 - 15) / 30 = 0.5333333
   * Hinweis: ein Standard-Intervall wird immer auf Basis von 30 Tagen gerechnet
   * 
   * Beispiel 2:
   * das Darlehen beginnt mit dem 22.01.; durch die Vorlauffrist kann die erste Rate erst im Februar erfolgen, am 28.02.; 
   * es besteht also ein verlängertes erstes Intervall, vom 22.01. bis 28.02.
   *   abweichungErstesIntervall = ((31 - 22) / 30) + 1 = 1.3
   * Hinweis: "+ 1" in der Gleichung steht für ein ganzes Intervall (der Februar)
   * 
   * Beispiel 3:
   * das Darlehen beginnt mit dem 31.01.; durch die Vorlauffrist kann die erste Rate erst im Februar erfolgen, am 28.02.; 
   * es besteht also ein verlängertes erstes Intervall, vom 31.01. bis 28.02.
   *   abweichungErstesIntervall = ((31 - 30) / 30) + 1 = 1.0333
   * Hinweis: der 31. des Monats ist ein Sonderfall; er wird wie der 30. behandelt, also (31 - 30), nicht (31 - 31)
   * 
   * @param {*} rate die monatliche Rate als Betrag, z. B. 250
   * @param {*} zinssatz der jährliche Zinssatz in Prozent, z. B. 6.25
   * @param {*} laufzeit die Laufzeit des Vertrags in Monaten, z. B. 36
   * @param {*} abweichungErstesIntervall gibt an, um welchen Faktor die Länge des ersten Intervalls
   * vom Standard (d.h. ein Monat) abweicht
   */
  _berechneBarwert(rate, zinssatz, laufzeit, abweichungErstesIntervall) {
    abweichungErstesIntervall = abweichungErstesIntervall || 1;
    var abweichenderZinssatz = this.getZinssatzMtl(zinssatz) * abweichungErstesIntervall;
    //Zerlegung der Ratenzahlungen in Rate 1 (mit abweichendem Zinssatz) und Raten 2 - n (mit normalen Zinssatz)
    //Der Barwert der Raten 2 - n (= Restschuld nach Zahlung von Rate 1) ergibt sich aus einer 
    //Barwertberechnung aus laufzeit - 1 Intervallen mit normalem Zins.
    var restschuld = this.runden(this.berechneBarwert(rate, zinssatz, laufzeit - 1), 0.01, 1);
    //der gesuchte Barwert ergibt sich aus der Summe von Restschuld und Rate 1, abgezinst mit dem abweichenden 
    //Zinssatz des ersten Intervalls
    return (restschuld + rate) / (1 + abweichenderZinssatz);
  }

  /**
   * Führt eine nachschüssige Annuitätenberechnung anhand des Finanzierungsbetrags mit gegebenen Zins 
   * und Laufzeit aus und rundet (optional) das Ergebnis auf die gegebene Genauigkeit.
   * Mit dem Parameter abweichungErstesIntervall wird ein verkürzter oder verlängerter Zeitraum vom Start der 
   * Berechnung bis zur ersten Zinszahlung dargestellt.
   * 
   * @param {*} finanzierungsbetrag die gewünschte Darlehenssumme, z. B. 30000
   * @param {*} zinssatz der jährliche Zinssatz in Prozent, z. B. 6.25
   * @param {*} laufzeit die Laufzeit des Vertrags in Monaten, z. B. 36
   * @param {*} gerundetAuf optional: Rundungsgenauigkeit, z. B. 250
   * @param {*} abweichungErstesIntervall optional: gibt an, um welchen Faktor die Länge des ersten Intervalls
   * vom Standard (d.h. ein Monat) abweicht
   */
  berechneAnnuitaet(finanzierungsbetrag, zinssatz, laufzeit, gerundetAuf, abweichungErstesIntervall) {
    if (abweichungErstesIntervall) {
      return this.runden(this._berechneAnnuitaet(finanzierungsbetrag, zinssatz, laufzeit, abweichungErstesIntervall), gerundetAuf);
    }
    var zinssatzMtl = this.getZinssatzMtl(zinssatz); //Umrechnung des Jahreszinses auf mtl. Zahlungen
    var result = finanzierungsbetrag * (zinssatzMtl * Math.pow(1 + zinssatzMtl, laufzeit)) / (Math.pow(1 + zinssatzMtl, laufzeit) - 1); 
    //console.log('rate:' + result);
    return this.runden(result, gerundetAuf);
  }

  /**
   * Führt eine nachschüssige Annuitätenberechnung anhand des Finanzierungsbetrags mit gegebenen Zins 
   * und Laufzeit aus.
   * Mit dem Parameter abweichungErstesIntervall wird ein verkürzter oder verlängerter Zeitraum vom Start der 
   * Berechnung bis zur ersten Zinszahlung dargestellt. Zum Erhalt einer einheitlichen Rate über die
   * gesamte Laufzeit ist ein iteratives Näherungsverfahren notwendig.
   * 
   * @param {*} finanzierungsbetrag die gewünschte Darlehenssumme, z. B. 30000
   * @param {*} zinssatz der jährliche Zinssatz in Prozent, z. B. 6.25
   * @param {*} laufzeit die Laufzeit des Vertrags in Monaten, z. B. 36
   * @param {*} abweichungErstesIntervall optional: gibt an, um welchen Faktor die Länge des ersten Intervalls
   * vom Standard (d.h. ein Monat) abweicht; Default: 1 (keine Abweichung)
   */
  _berechneAnnuitaet(finanzierungsbetrag, zinssatz, laufzeit, abweichungErstesIntervall) {
    abweichungErstesIntervall = abweichungErstesIntervall || 1;
    var abweichenderZinssatz = this.getZinssatzMtl(zinssatz) * abweichungErstesIntervall;
    var abweichenderZins = this.runden(finanzierungsbetrag * abweichenderZinssatz, 0.01);
    //console.log('abweichenderZins:' + abweichenderZins);
    var rateOhneAbweichung = this.runden(this.berechneAnnuitaet(finanzierungsbetrag, zinssatz, laufzeit), 0.01, -1);
    //das Ergebnis als Basis zur Anpassung der Tilgung der ersten Rate verwenden und solange iterieren,
    //bis die Ergebnisse sich um maximal einen Cent unterscheiden.
    var maxIterationen = 20;
    var iter = 1;
    var rate = rateOhneAbweichung;
    var restschuld, tilgungErsteRate, neueRate;
    while (iter <= maxIterationen) {
      tilgungErsteRate = rate - abweichenderZins;
      restschuld = finanzierungsbetrag - tilgungErsteRate;
      //Ergebnis wird auf Cent-Ebene abgerundet; SAP CML arbeitet so und kumuliert abschließend die
      //"verlorenen" Cent-Bruchteile in die letzte Rate (die dann geringfügig höher ausfällt);
      //da hier jedoch nur die Standardrate gefragt ist, kann dieser Schritt vernachlässigt werden
      neueRate = this.runden(this.berechneAnnuitaet(restschuld, zinssatz, laufzeit - 1), 0.01, -1);
      if (Math.abs(rate - neueRate) <= 0.01) {
        break;
      } else {
        rate = neueRate;
      }
      iter++;
    }
    //console.log('rate (mit Abweichung im ersten Intervall):' + neueRate + ', ' + iter + ' Iterationen');
    return neueRate;
  }
  
  /**
   * Führt eine Umwandlung des jährlichen in Prozent angegebenen Zinssatzes (z.B. 6.18) in
   * einen Zinsatz auf monatlicher Basis durch:
   * ZinssatzMtl = Zinssatz / 12 / 100
   * @param {*} zinssatz
   */
  getZinssatzMtl(zinsfuss) {
    return zinsfuss / 12 / 100;
  }

  /**
   * Liefert den Betrag gerundet auf die Genauigkeit des zweiten Parameters. Wird dieser nicht mitgeliefert,
   * erfolgt keine Rundung. Der dritte (optionale) Parameter gibt den Rundungstyp an: 0 = kaufmännisch runden,
   * -1 = abrunden, +1 = aufrunden.
   * @param {*} betrag 
   * @param {*} gerundetAuf 
   * @param {*} rundungsTyp 
   */
  runden(betrag, gerundetAuf, rundungsTyp) {
    if (gerundetAuf) {
      if (rundungsTyp === -1) {
        return Math.floor(betrag / gerundetAuf) * gerundetAuf;
      } else if (rundungsTyp === 1) {
        return Math.ceil(betrag / gerundetAuf) * gerundetAuf;
      } else {
        return Math.round(betrag / gerundetAuf) * gerundetAuf;
      }
    } else {
      return betrag;
    }
  }
}

