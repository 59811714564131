import _ from 'lodash';

const enabledKeys = [8, 9, 17, 35, 36, 37, 39, 45, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 67, 86, 91, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 109, 110, 188];

export const NumberInputDirective = ($filter) => {
  'ngInject'; //NOSONAR
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      minValue: '<',
      maxValue: '<',
      decimalPlaces: '<'
    },
    link: function (scope, element, attr, ngModel) {
      const decimalPlaces = _.isFinite(scope.decimalPlaces) ? scope.decimalPlaces : 2;

      element.bind('keydown', function (event) {
        !_.includes(enabledKeys, event.keyCode) &&
        event.preventDefault();
      });

      element.bind('focus', function () {
        var tempValue = ngModel.$viewValue + '';
        if (tempValue) {
          tempValue = tempValue.replace(/\./g, '');
          ngModel.$viewValue = tempValue;
          ngModel.$render();
        }
      });

      element.bind('blur', function () {
        var tempValue = ngModel.$viewValue;
        tempValue = tempValue.replace(/\./g, '').replace(/\,/g, '.');
        if (tempValue) {
          var num = _.toNumber(tempValue);
          if (_.isNumber(num)  && !isNaN(num)) {
            ngModel.$viewValue = $filter('obsNumber')(num, decimalPlaces);
            ngModel.$render();
          }
        }
      });

      function numericParser(viewValue) {
        var tempTxt = (viewValue + '').replace(/\,/g, '.');
        var num = _.toNumber(tempTxt);
        if (!isNaN(num)) {
          if (_.isInteger(num)) {
            return num;
          } else {
            return num.toFixed(decimalPlaces);
          }
        } else {
          return undefined;
        }
      }

      function numericFormatter(modelValue) {
        if (_.isNumber(modelValue) && !isNaN(modelValue)) {
          return $filter('obsNumber')(modelValue, decimalPlaces);
        }
        return '';
      }

      function minMaxValidator(modelValue) {
        if (ngModel.$isEmpty(modelValue)) {
          // consider empty model valid
          return true;
        }
        return (!_.isFinite(scope.minValue) || modelValue >= scope.minValue) &&
          (!_.isFinite(scope.maxValue) || modelValue <= scope.maxValue);
      }

      ngModel.$parsers.push(numericParser);  //value in model
      ngModel.$formatters.push(numericFormatter); //display
      ngModel.$validators.minMax = minMaxValidator; //validate
    }
  };
};
