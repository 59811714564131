import _ from 'lodash';

/**
 * Upload-Service
 */
export default class UploadService {

  constructor(endpointsService, obsRequestWrapper, popupService, applikationStatus, $q) {
    'ngInject'; //NOSONAR

    this._endpointsService = endpointsService;
    this._obsRequestWrapper = obsRequestWrapper;
    this._popupService = popupService;
    this._applikationStatus = applikationStatus;
    this._$q = $q;
    this._uploadData;

    this.daten = {};
    this.initialized = false;
    this.uploadConstraints = null;
    this.filesOnServer = null;
    this.uploadTypen = null;
    this.aktivierungsCode = null;
  }

  initUploadService (aktivierungsCode, uploadData) {
    if (!this.initialized) {
      this.aktivierungsCode = aktivierungsCode;
      if (!uploadData) {
        return this._loadUploadDataFromServer().then((uploadData) => {
          this._initUploadData(uploadData);
          return uploadData;
        });
      } else {
        this._initUploadData(uploadData);
      }
    }
    return this._$q.when(this._uploadData);
  }

  _initUploadData (uploadData) {
    this._uploadData = uploadData;
    this.filesOnServer = uploadData.uploads || [];
    this.uploadRequirements = uploadData.uploadRequirements;
    this.uploadConstraints = uploadData.uploadConstraints;
    this.uploadTypen = uploadData.uploadTypen;
    this.uploadsAktiv = uploadData.uploadsAktiv;
    this._initUploader();
    this.initialized = true;
  }

  _loadUploadDataFromServer() {
    return this._obsRequestWrapper
      .sendRequest('upload', { useCustomErrorHandler: true },
        false, this.aktivierungsCode)
      .then((response) => {
        return response.data;
      })
      .catch((response) => { //error handler
        return this._$q.reject(response);
      });
  }

  showUploadType(uploadType) {
    return _.includes(this.uploadTypen, uploadType);
  }

  validate () {
    let valid = true;
    _.forEach(this.uploadTypen, (uploadType) => {
      const isUploadTypValid = this.isUploadTypeValid(uploadType);
      this.daten[uploadType].error = !isUploadTypValid;
      valid = valid && isUploadTypValid;
    });
    return this.isValid();
  }

  isValid () {
    let valid = true;
    _.forEach(this.uploadTypen, (uploadType) => {
      valid = valid && this.isUploadTypeValid(uploadType);
    });
    return valid;
  }

  isUploadsAktiv () {
    return this.uploadsAktiv;
  }

  getUploadRequirements() {
    return this.uploadRequirements;
  }

  getUploadConstraints () {
    return this.uploadConstraints;
  }

  isUploadTypeValid(uploadType) {
    return !this.daten[uploadType].isUploadRequired() || this.daten[uploadType].uploadDone;
  }

  //private Funktionen

  _initUploader() {
    _.forEach(this.uploadTypen, (uploadType) => {
      const uploadHandler = {};
      const uploadsOnServer = this._getUploadsOnServer(uploadType);
      uploadHandler.uploadDone = uploadsOnServer.length > 0;
      uploadHandler.uploadUrl = this._endpointsService.getUrl('upload') + '/' + this.aktivierungsCode + '/' +
      uploadType + '/' + this._applikationStatus.erfassungsmodus;
      uploadHandler.uploadInitList = uploadsOnServer;
      _.forEach(uploadHandler.uploadInitList, (uploadItem) => {
        uploadItem.success = true;
      });
      uploadHandler.error = false;
      uploadHandler.onUpload = onUpload.bind(this);
      uploadHandler.onDelete = onDelete.bind(this);
      uploadHandler.isUploadRequired = () => {
        return ('aufenthaltsbescheinigung' !== uploadType);
      };
      this.daten[uploadType] = uploadHandler;

      function onUpload(item) {
        if (item.isSuccess) {
          uploadHandler.uploadDone = true;
        }
        uploadHandler.uploadInitList.push({
          uploadType: uploadType,
          herkunftType: this._applikationStatus.erfassungsmodus,
          filename: item.file.name,
          fileSize: item.file.size,
          mimeType: item.file.type,
          id: item.id,
          success: item.isSuccess
        });
        //erneute Validierung, um Fehlermeldungen auszublenden, wenn sie nicht mehr zutreffen
        if (uploadHandler.error) {
          this.validate();
        }
      }

      function onDelete(item) {
        return this._obsRequestWrapper
          .sendRequest('upload', { method: 'DELETE', useCustomErrorHandler: true },
            false, this.aktivierungsCode + '/' + item.id)
          .then( () => {
            // bei erfolgreichem Löschen soll der Eintrag auch client-seitig entfernt werden...
            // aus der aktuellen Uploader Queue (transient in der Upload-Komponente)
            const queueCount = item.removeFromQueue();
            // und aus der Liste der Uploads, die im Application-Scope gehalten wird,
            // damit der aktuelle Status aller Uploads auch bei Maskenwechsel wiederhergestellt werden kann
            _.remove(uploadHandler.uploadInitList, function(listItem) {
              return listItem.filename === item.file.name;
            });
            uploadHandler.uploadDone = queueCount > 0;
          }, () => { //error handler
            this._popupService.openAlert('uploadLoeschenError');
          });
      }
    });
  }

  _getUploadsOnServer(filterByUploadType) {
    if (this.filesOnServer) {
      return _.filter(this.filesOnServer, { 'uploadType': filterByUploadType});
    } else {
      return [];
    }
  }

}
