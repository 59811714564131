import template from './bankfiliale.html';
import './_bankfiliale.scss';
import { PartnerIdSource } from '../../../common/obs-url-params.service';

export {BankfilialeComponent, BankfilialeController};

const BankfilialeComponent = {
  template: template,
  controller: BankfilialeController,
  bindings: {
    banken: '<'
  }
};

function BankfilialeController(bankauswahlService, kontaktformularService, bankfilialenSucheService, formUtilService, urlParamsService) {
  'ngInject'; //NOSONAR
  const ctrl = this;
  
  ctrl.$onInit = function () {

    ctrl.bankCandidate = null;
    
    // OBS ohne PartnerId
    if(ctrl.banken != null){
      if(bankauswahlService.model.selectedBank != null) {
        ctrl.bankCandidate = bankauswahlService.model.selectedBank;
      } else {
        bankauswahlService.setBanken(ctrl.banken);
        ctrl.bankCandidate = bankauswahlService._banken[0];
      }
    } else {
      const vrDummy = [{
        bankname: '',
        blz: '',
        id: '',
        ort: '',
        plz: ' ',
        strasse: '',
        thumbnailUrl: 'https://www.vr-dive.de/images/bank_logos/vr_bank.png'
      }];
      // OBS mit PartnerId
      // Schreibe die erhaltenen Infos aus kontaktformularService.getBankinformationen() in bankCandidate
      if(kontaktformularService.model.bankCandidate.bankname != '' || kontaktformularService.model.bankCandidate.blz != '') {
        vrDummy[0].bankname = kontaktformularService.model.bankCandidate.bankname;
        vrDummy[0].blz = kontaktformularService.model.bankCandidate.blz;
      }
      if(kontaktformularService.model.bankCandidate.strasse != '' || kontaktformularService.model.bankCandidate.ort != ''){
        vrDummy[0].strasse = kontaktformularService.model.bankCandidate.strasse;
        vrDummy[0].ort = kontaktformularService.model.bankCandidate.ort;
      }
      if(kontaktformularService.model.bankCandidate.plz != ''){
        vrDummy[0].plz = kontaktformularService.model.bankCandidate.plz;
      }
      bankauswahlService.setBanken(vrDummy);
      ctrl.bankCandidate = vrDummy[0];
    }
    
    ctrl.model = bankauswahlService.model;
    ctrl.bankauswahl = bankauswahlService;
    ctrl.kontaktformular = kontaktformularService.model;
    
    ctrl.showFilialeSuchen = false;
    ctrl.showSearchMessage = false;
    ctrl.onSelectedFiliale = onSelectedFiliale;
    ctrl.toggleFilialeSuchen = toggleFilialeSuchen;
    ctrl.startFilialeSuchen = startFilialeSuchen;
    ctrl.isFieldError = isFieldError;
    ctrl.changeSelection = changeSelection;
    ctrl.isBankSelected = isBankSelected;
    ctrl.isAbsprungpunktLegitimation = isAbsprungpunktLegitimation;
   
    
    
    //bereits getroffene Auswahl der Partner-Bank zurücksetzen, wenn neue Filialliste geliefert wird
    if (urlParamsService.partnerId && urlParamsService.isPartnerIdSource(PartnerIdSource.SELECTION) && 
        !bankauswahlService.isSelected()) {
      ctrl.kontaktformular.partnerId = null;
      ctrl.kontaktformular.partnerBankName = null;
    }
  };
  
  function onSelectedFiliale() {
    ctrl.kontaktformular.showWunschtermin = true;
  }
  
  function toggleFilialeSuchen() {
    ctrl.showFilialeSuchen = !ctrl.showFilialeSuchen;
    ctrl.model.submitted = false;
  }

  function validate() {
    ctrl.model.submitted = true;
    return !isSubmittedFormError();
  }

  function isFieldError(fieldname) {
    return formUtilService.isFieldError(ctrl.filialeSucheForm, ctrl.model.submitted, fieldname);
  }
  
  function isSubmittedFormError() {
    return formUtilService.isSubmittedFormError(ctrl.filialeSucheForm, ctrl.model.submitted);
  }

  function changeSelection(bank) {
    bankauswahlService.changeSelection(bank);
    if (bank &&
      (urlParamsService.partnerId == null || urlParamsService.isPartnerIdSource(PartnerIdSource.SELECTION) || urlParamsService.isPartnerIdSource(PartnerIdSource.URL)))  {
      ctrl.kontaktformular.partnerId = bank.blz;
      ctrl.kontaktformular.partnerBankName = bank.bankname;

      // überschreibe die PartnerId in den Anfragedaten
      if(ctrl.kontaktformular.daten.absprungpunkt == 'KREDITENTSCHEIDUNG_NEGATIV'){
        ctrl.kontaktformular.abschlussVorgang.partnerId = ctrl.kontaktformular.partnerId;
        ctrl.kontaktformular.abschlussVorgang.partnerBankName = ctrl.kontaktformular.partnerBankName;
      } else {
        ctrl.kontaktformular.daten.anfragedaten.vertriebsPartnerBlz = ctrl.kontaktformular.partnerId;
      }
    }
    ctrl.bankCandidate = bank;
    ctrl.kontaktformular.showWunschtermin = true;
  }

  function startFilialeSuchen() {
    if (validate()) {
      const bankenAuswahl = bankfilialenSucheService.getBanken({
        plz: ctrl.model.daten.plz,
        strasse: ctrl.model.daten.strasse
      });
      // Rufe die Methode setBanken auf, sobald der Promise eine response liefert
      bankenAuswahl.then((response) => {
        bankauswahlService.setBanken(response);
      });
      ctrl.showSearchMessage = true;
    }
  }

  function isBankSelected() {
    return bankauswahlService.isSelected();
  }

  function isAbsprungpunktLegitimation() {
    return !ctrl.model.error && ctrl.kontaktformular.daten.absprungpunkt == 'LEGITIMATION';
  }
}