
/**
 * Service for PageTracking via Matomo
 */
export default class PiwikService {
  constructor($log, cookieService, trackingUrl, trackingId) {
    'ngInject'; //NOSONAR
    this._$log = $log;
    this._cookieService = cookieService;
    this._trackingUrl = trackingUrl;
    this._trackingId = trackingId;
    this._viewTitle = '';
    this._piwikInitialized = false;
  }

  initPiwik() {
    const _paq = window._paq = window._paq || [];
    _paq.push(['setTrackerUrl', this._trackingUrl + 'matomo.php']);
    _paq.push(['setSiteId', this._trackingId]);
    const s = document.createElement('script'); // use global document since Angular's $document is weak
    s.src = this._trackingUrl +'matomo.js';
    window.document.body.appendChild(s);
    this._piwikInitialized = true;
  }

  getViewTitle() {
    return this._$state ? this._$state.current.viewTitle : this._viewTitle;
  }

  setViewTitle(title) {
    this._viewTitle = title;
  }

  setStateService($state) {
    this._$state = $state;
  }

  setSegmentBlz(blz) {
    this._setCustomVariable(1, 'BLZ', blz, 'visit');
  }

  setEingangskanal(eingangskanal) {
    this._setCustomVariable(2, 'Eingangskanal', eingangskanal, 'visit');
  }

  _setCustomVariable(index, name, value, scope) {
    if (this._piwikInitialized && this._cookieService.isEinwilligungAkzeptiert() && value) {
      window._paq.push(['setCustomVariable', index, name, value, scope]);
    }
  }

  /**
   * Die visitorId wird über die Matomo API per getVisitorId() ausgelesen
   * und dann ebenfalls über diese API per setUserId() gesetzt.
   * Da die Anwendung keinen Benutzer Login besitzt wird die userId gleich der visitorId gesetzt.
   */
  readAndSetVisitorId() {
    const ctrl = this;
    if (this._piwikInitialized) {
      window._paq.push([ function () {
        ctrl.visitorId = this.getVisitorId();
        this.setUserId(ctrl.visitorId);
      }]);
    }
  }

  /**
   * Die visitorId wird im Abschluss-Bereich gesetzt, um eine Verknüpfung zum Anfrage-Bereich herzustellen
   * Der Benutzer wird über diese visitorId in Matomo identifiziert.
   */
  setVisitorId(visitorId) {
    if (this._piwikInitialized && visitorId) {
      window._paq.push(['setUserId', visitorId]);
    }
  }

  pageTrack(pageName, pageUrl) {
    if (this._piwikInitialized && this._cookieService.isEinwilligungAkzeptiert()) {
      this._$log.debug('[tracking page]:' + pageName + ' - ' + pageUrl);
      window._paq.push(['setDocumentTitle', pageName]);
      window._paq.push(['setCustomUrl', pageUrl]);
      window._paq.push(['trackPageView']);
    }
  }

  eventTrack(category, action, name) {
    if (this._piwikInitialized) {
      this._$log.debug('[tracking event]:' + category + ' - ' + action + ' - ' + name);
      window._paq.push(['trackEvent', category, action, name]);
    }
  }

  goalTrack(goalId) {
    if (this._piwikInitialized && this._cookieService.isEinwilligungAkzeptiert()) {
      this._$log.debug('[tracking goal]:' + goalId);
      window._paq.push(['trackGoal', goalId]);
    }
  }

  goalTrackAmount(goalId, amount) {
    if (this._piwikInitialized && this._cookieService.isEinwilligungAkzeptiert()) {
      this._$log.debug('[tracking goal]:' + goalId + ' - ' + amount);
      window._paq.push(['trackGoal', goalId, amount]);
    }
  }

  customDimensionTrack(dimension, value) {
    if (this._piwikInitialized && this._cookieService.isEinwilligungAkzeptiert()) {
      this._$log.debug('[tracking custom dimension]:' + dimension + ' - ' + value);
      window._paq.push(['setCustomDimension', dimension, value]);
    }
  }

  trackDisqualifizierungsmerkmal(eventName, value) {
    this._trackEvent(eventName, 'Disqualifizierungsmerkmal', value, false);
  }

  trackPopup(popupName, actionType, value) {
    this._trackEvent(popupName, actionType, value, false);
  }

  trackElementClick(field) {
    this._trackEvent(field, 'Klick', undefined, false);
  }

  trackElementChange(field, value) {
    this._trackEvent(field, 'Eingabe', value, false);
  }

  trackStart(eventName, value) {
    this._trackEvent(eventName, 'Start', value, false);
  }

  trackServerErgebnis(eventName, value) {
    this._trackEvent(eventName, 'Ergebnis', value, false);
  }

  trackServerError(errorMessage) {
    this._trackEvent(null, 'Server-Error', errorMessage, true);
  }

  trackValidationSuccess(fields) {
    const fieldArrayString = this.formatFields(fields);
    this._trackEvent(null, 'Validierung erfolgreich', fieldArrayString, false);
  }

  trackValidationFailure(fields) {
    const fieldArrayString = this.formatFields(fields);
    this._trackEvent(null, 'Validierung fehlgeschlagen', fieldArrayString, false);
  }

  _trackEvent(eventName, actionTyp, value, alwaysAllowTracking = false) {
    const category = 'Seite ' + this.getViewTitle();
    const action = '[' + actionTyp + ']' + ((eventName) ? ' ' + eventName : '');
    const name = this.convertValue(value);

    if (alwaysAllowTracking) {
      this.eventTrack(category, action, name);
    } else {
      if (this._cookieService.isEinwilligungAkzeptiert()) {
        this.eventTrack(category, action, name);
      }
    }
  }

  formatFields(fieldArray) {
    fieldArray = Array.isArray(fieldArray) ? fieldArray : [ fieldArray ];
    return fieldArray.join(' - ');
  }

  convertValue(value) {
    if (value) {
      if (value === 'true') {
        return 'Ja';
      }
      else if (value === 'false') {
        return 'Nein';
      }
    }
    return value;
  }
}
