import uiRouter from '@uirouter/angularjs';
import ngAnimate from 'angular-animate';
import uiMask from 'angular-ui-mask';
import componentModule from '../../components/components.module';
import commonModule from '../../common/common.module';
import FinanzkennzahlenService from './finanzkennzahlen/finanzkennzahlen.service';
import { FinanzkennzahlenComponent } from './finanzkennzahlen/finanzkennzahlen.component';
import { EinnahmenAusgabenComponent } from './finanzkennzahlen/einnahmen-ausgaben/einnahmen-ausgaben.component';
import { FinanzkennzahlInputDirective } from './finanzkennzahlen/finanzkennzahl-input.directive';
import { BankverbindungComponent } from './finanzkennzahlen/bankverbindung/bankverbindung.component';
import { KdfNegativComponent } from './kdf-negativ/kdf-negativ.component';

/**
 * Das Modul obs.unternehmen enthält Masken und Funktionalitäten, die
 * dem Menüpunkt "Unternehmen" zugeordnet werden.
 */
export default angular
  .module('obs.unternehmen', [
    ngAnimate,
    uiRouter,
    commonModule,
    componentModule,
    uiMask
  ])
  .service('finanzkennzahlenService', FinanzkennzahlenService)
  .component('finanzkennzahlen', FinanzkennzahlenComponent)
  .component('einnahmenAusgaben', EinnahmenAusgabenComponent)
  .component('kdfNegativ', KdfNegativComponent)
  .component('bankverbindung', BankverbindungComponent)
  .directive('finanzkennzahlInput', FinanzkennzahlInputDirective)
  .name;

