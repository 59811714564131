/**
 * Service für die Einstiegsmaske der OBS zur Auswahl des Finanzierungsvorhabens.
 * Wrapper für die untergeordneten Services, die einzelnen Maskenbereichen
 * zugeordnet sind (Finanzierungswunsch, Checkliste)
 */
export default class FinanzierungsvorhabenService {

  constructor(finanzierungswunschService) {
    'ngInject'; //NOSONAR

    this._finanzierungswunschService = finanzierungswunschService;

    this.finanzierungswunschModel = this._finanzierungswunschService.daten;
  }

  /**
   * Liefert den aktuell gewählten Finanzierungsbetrag
   */
  getFinanzierungsbetrag () {
    return this._finanzierungswunschService.daten.finanzierungsparameter.betragSlider.value;
  }

  /**
   * Liefert die aktuell gewählte Wunschrate
   */
  getWunschrate () {
    return this._finanzierungswunschService.daten.finanzierungsparameter.rateSlider.value;
  }

  /**
   * Liefert die aktuell gewählte Laufzeit
   */
  getLaufzeit () {
    return this._finanzierungswunschService.daten.finanzierungsparameter.laufzeitSlider.value;
  }

  /**
   * Liefert die aktuell gewählte Berechnungsart als enum-Wert der REST-API,
   * 'budgetrechner' oder 'finanzierungsbedarf'
   */
  getBerechnungsart () {
    return this._finanzierungswunschService.daten.finanzierungsparameter.berechnungsartBudgetrechner ?
      'budgetrechner' : 'finanzierungsbedarf';
  }

}
