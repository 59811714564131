/**
 * DOM-Observer, prüft auf Änderungen des aktuellen DOM-Baums und liefert
 * an eine callback-Funktion die Höhe des gesamten HTML-Dokuments in Pixel,
 * wenn sich aus der DOM-Änderung eine Veränderung des Höhe ergeben hat.
 *
 *
 * Idee basierend auf: http://davidjbradshaw.github.io/iframe-resizer/
 */
let
  observerInstance      = null, // per start()-Aufruf gestartete Instanz
  throttledTimer        = 16, // max. 1 callback Aufruf pro x Millisekunden
  height                = 1, // zuletzt gemessene Höhe des Dokuments
  callbackFunc          = null, //per start()-Aufruf übergebener Callback Listener
  tolerance             = 3 // Toleranz in Pixel für eine Höhenänderung, nur bei Überschreitung wird der Callback angesprochen
  ;

function _init(callback) {

  let
    elements         = [],
    MutationObserver = window.MutationObserver || window.WebKitMutationObserver,
    observer         = createMutationObserver();

  if (typeof callback === 'function') {
    callbackFunc = callback;
  }

  function addImageLoadListeners(mutation) {
    function addImageLoadListener(element) {
      if (false === element.complete) {
        log('Attach listeners to ' + element.src);
        element.addEventListener('load', imageLoaded, false);
        element.addEventListener('error', imageError, false);
        elements.push(element);
      }
    }

    if (mutation.type === 'attributes' && mutation.attributeName === 'src') {
      addImageLoadListener(mutation.target);
    } else if (mutation.type === 'childList') {
      Array.prototype.forEach.call(
        mutation.target.querySelectorAll('img'),
        addImageLoadListener
      );
    }
  }

  function removeFromArray(element) {
    elements.splice(elements.indexOf(element), 1);
  }

  function removeImageLoadListener(element) {
    log('Remove listeners from ' + element.src);
    element.removeEventListener('load', imageLoaded, false);
    element.removeEventListener('error', imageError, false);
    removeFromArray(element);
  }

  function imageEventTriggered(event,type,typeDesc) {
    removeImageLoadListener(event.target);
    log('sendPostMessage imageEvent');
    sendSize(type, typeDesc + ': ' + event.target.src);
  }

  function imageLoaded(event) {
    imageEventTriggered(event,'imageLoad','Image loaded');
  }

  function imageError(event) {
    imageEventTriggered(event,'imageLoadFailed','Image load failed');
  }

  function mutationObserved(mutations) {
    sendSize('mutationObserver', 'mutationObserver: ' + mutations[0].target + ' ' + mutations[0].type);
    //Deal with WebKit asyncing image loading when tags are injected into the page
    mutations.forEach(addImageLoadListeners);
  }

  function createMutationObserver() {
    let
      target = document.querySelector('body'),

      config = {
        attributes            : true,
        attributeOldValue     : false,
        characterData         : true,
        characterDataOldValue : false,
        childList             : true,
        subtree               : true
      };

    observer = new MutationObserver(mutationObserved);

    log('Create body MutationObserver');
    observer.observe(target, config);

    return observer;
  }

  return {
    disconnect: function () {
      if ('disconnect' in observer) {
        log('Disconnect body MutationObserver');
        observer.disconnect();
        elements.forEach(removeImageLoadListener);
      }
    }
  };
}

let getNow = Date.now || function() {
  return new Date().getTime();
};

//Based on underscore.js
function throttle(func) {
  let
    context, args, result,
    timeout = null,
    previous = 0,
    later = function() {
      previous = getNow();
      timeout = null;
      result = func.apply(context, args);
      if (!timeout) {
        context = args = null;
      }
    };

  return function() {
    let now = getNow();
    if (!previous) {
      previous = now;
    }
    let remaining = throttledTimer - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > throttledTimer) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) {
        context = args = null;
      }
    } else if (!timeout) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}

function sizeIFrame(triggerEventDescr) {

  let currentHeight;

  function getHeight() {
    return Math.ceil(document.documentElement.offsetHeight);
  }

  function isSizeChangeDetected() {
    function checkTolerance(a,b) {
      let retVal = Math.abs(a-b) <= tolerance;
      return !retVal;
    }
    currentHeight = getHeight();
    return checkTolerance(height, currentHeight);
  }

  if (isSizeChangeDetected()) {
    height = currentHeight;
    log('sendMessage for mutationEvent [' + triggerEventDescr + '], new iframe height: ' + height);
    if (callbackFunc) {
      callbackFunc(height);
    }
  }
}

let sizeIFrameThrottled = throttle(sizeIFrame);

function sendSize(type, triggerEventDescr) {
  sizeIFrameThrottled(triggerEventDescr);
}

function log(msg) {
  if ('object' === typeof window.console) {
    /*eslint no-console: off*/
    console.log(msg); // NOSONAR
  }
}

//Export des DOM-Observers mit Start/Stop-Funktion
export const domMutationObserver = {
  start: function(callback) {
    if (observerInstance == null) {
      observerInstance = _init(callback);
    }
  },
  stop: function() {
    if (observerInstance != null) {
      observerInstance.disconnect();
      observerInstance = null;
    }
  }
};
