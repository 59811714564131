import template from './obs-module.html';
import './_obs-module.scss';

/**
 * A gridded row containing the title on the left and transcluding the content on the right.
 */
export const UModuleComponent = {
  bindings: {
    moduleName: '@',
    moduleSubtitle: '@',
    module: '@'
  },
  template: template,
  transclude: {
    'title': '?moduleTitle',
    'subtitle': '?moduleSubtitle'
  }
};
