/**
 * Definition der UI-Routes des Errorpage-Moduls
 */
export default function routes($urlRouterProvider, $stateProvider) {
  'ngInject'; //NOSONAR
  //Fehlerseite
  $stateProvider
    .state('error', {
      containerClass: 'error',
      url: '/error',
      component: 'error',
      resolve: {
        errorMessage: function (errorMessageService) {
          'ngInject'; //NOSONAR
          return errorMessageService.getMessage();
        }
      },
      isModalViewState: true,
      isApplicationInitState: true,
      viewTitle: 'Es ist ein Fehler aufgetreten'
    });
}
