import angular from 'angular';
import angularScrollModule from 'angular-scroll';
import angularSliderModule from 'angularjs-slider';
import ngLocale from 'angular-i18n/de-de';
import ngAnimate from 'angular-animate';
import ratingModule from 'angular-ui-bootstrap/src/rating';
import { UModuleComponent } from './obs-module.component';
import { SliderComponent, initSlider } from './obs-slider.component';
import { ButtonsComponent } from './obs-buttons.component';
import { UploadComponent, DistinctFilesFilter } from './obs-upload.component';
import { CollapseComponent } from './obs-collapse.component';
import { ProzessstatusComponent } from './obs-prozessstatus.component';
import { SterneComponent } from './obs-sterne.component';
import { BoniManagerComponent } from './obs-bonimanager.component';
import {InfoCardComponent} from './obs-info-card.component';

export default angular
  .module('obs.component', [
    ngLocale,
    ngAnimate,
    angularScrollModule,
    angularSliderModule,
    ratingModule
  ])
  .component('obsUModule', UModuleComponent)
  .component('obsSliderComponent', SliderComponent)
  .component('obsButtons', ButtonsComponent)
  .component('obsUpload', UploadComponent)
  .component('obsProzessstatus', ProzessstatusComponent)
  .component('obsCollapse', CollapseComponent)
  .component('obsSterne', SterneComponent)
  .component('obsBonitaetsManager', BoniManagerComponent)
  .component('obsInfoCard', InfoCardComponent)
  .filter('distinctFiles', DistinctFilesFilter)
  .run(initSlider)
  .name;

