/**
 * Dieser Service enthält Funktionen für die generierung von UUIDS, 
 * die in Anfragen als Unique Identifier benutzt werden können
 */
export default class UuidService {

  /**
   * Returns a random v4 UUID of the form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx,
   * where each x is replaced with a random hexadecimal digit from 0 to f, and 
   * y is replaced with a random hexadecimal digit from 8 to b
   */
  uuidv4() { 
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); //NOSONAR
      return v.toString(16);
    });
  }

}