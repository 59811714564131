import angular from 'angular';
import _ from 'lodash';
import { obsGlobals, obsConfig } from './app.init';

/**
 * Bootstrapping der Anwendung. Unterstützt die Initialisierung
 * mehrerer Module (Entry Points) gleichzeitig. Damit wird die Ausführung
 * von Unit-Tests mit Karma auf der gesamten Anwendung ermöglicht.
 *
 * @param {*} appModules
 */
export default function bootstrapAngular(appModules) {
  const firstModule =
    Array.isArray(appModules) ? appModules[0] : appModules;
  const appModuleNames =
    Array.isArray(appModules) ? _.map(appModules, 'name') : [ appModules.name ];
  // globale Konstanten und Values setzen
  obsGlobals(firstModule);
  // globaler Config-Block
  firstModule.config(obsConfig);
  // manually bootstrap angular
  angular.bootstrap(document, appModuleNames, {
    // enable strictDi in production env, see
    // https://docs.angularjs.org/guide/di#using-strict-dependency-injection
    strictDi: process.env.NODE_ENV == 'production'
  });
}
