import template from './error-page.html';

export {ErrorComponent, ErrorPageController};

const ErrorComponent = {
  template: template,
  controller: ErrorPageController,
  bindings: {
    errorMessage: '<'
  }
};

function ErrorPageController($window, $sce, urlParamsService, $state) {
  'ngInject'; //NOSONAR
  const ctrl = this;
  this.$state = $state;

  ctrl.$onInit = function () {
    ctrl.titel = ctrl.errorMessage.meldungTitel;
    ctrl.text = $sce.trustAsHtml(ctrl.errorMessage.meldungText);
    ctrl.goToNextPage = goToNextPage;
    ctrl.buttonText = (urlParamsService.domain) ? 'Zurück zur Bankseite' : 'Zur Filialsuche';
  };

  function goToNextPage() {
    if (urlParamsService.domain) {
      $window.open(urlParamsService.domain, '_blank');
    }
    else {
      $window.open('https://www.vr-smart-finanz.de/filialsuche/', '_blank');
    }
  }
}
