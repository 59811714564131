import _ from 'lodash';

/**
 * Service für die ortsabhängige Suche nach VR Bankfilialen, die das Produkt
 * flexibel anbieten.
 */
export default class BankfilialenSucheService {

  /**
   * Konstruktor
   * @param {*} obsRequestWrapper 
   */
  constructor(obsRequestWrapper, $q) {
    'ngInject'; //NOSONAR
    this._$q = $q;
    this._obsRequestWrapper = obsRequestWrapper;
    this._searchParams = {
      plz: null,
      strasse: null
    };
    this._banken = [];
  }

  /**
   * Liefert ein Promise auf das Ergebnis der Filialsuche oder bei
   * bereits aufgelöstem Promise und unveränderten Suchparametern 
   * das Ergebnis des letzten Aufrufs (Liste der Filialen).
   * @param {*} searchParams 
   */
  getBanken(searchParams) {
    if (this._banken &&
        _.isEqual(searchParams, this._searchParams)) {
      return this._$q.when(this._banken);
    } else {
      this._searchParams.plz = searchParams.plz;
      this._searchParams.strasse = searchParams.strasse;
      return this._obsRequestWrapper.sendRequest('bankauswahl', {
        method: 'POST',
        data: this._searchParams
      }).then((response) => {
        return this._banken = response.data;
      });
    }
  }

}
