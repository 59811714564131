import './_obs-cookie.scss';


export default class CookieService {


  constructor($rootScope, $window) {
    'ngInject'; //NOSONAR

    this._$rootScope = $rootScope;
    this._$window = $window;

    this.partnerIdCookie = 'ObsPartnerId';
    this.teaserBetragCookie = 'ObsTeaserAmount';
    this.teaserLaufzeitCookie = 'ObsTeaserLz';
    this.cookieconsentStatusCookie = 'cookieconsent_status'; // Cookie welches den Status der Cookie Einstellung über cookieconsent beinhaltet
  }

  initialize() {
    const cookieConsent = this._$window.cookieconsent;
    if (cookieConsent) {
      cookieConsent.initialise({
        container: document.getElementById('cookieconsent'),
        palette: {
          popup: {
            background: '#f5f5f5',
            text: '#5a5a5a'
          },
          button: {
            background: '#ff6600',
            text: '#ffffff'
          }
        },
        layout: 'basic-header',
        type: 'opt-in',
        position: 'bottom-right',
        elements: {
          header: '<span class="cc-header">Cookie-Einstellungen</span>',
          message: '<span id="cookieconsent:desc" class="cc-message">Auf dieser Website werden Cookies verwendet. Mit Ihrer Zustimmung erlauben Sie die Verwendung von Cookies zu Analysezwecken durch die VR Smart Finanz. Einzelheiten zur Nutzung von Cookies finden Sie in der</span>',
          link: '<a aria-label="learn more about cookies" tabindex="0" class="underline is-signal" href="https://www.vr-smart-finanz.de/onlinefinanzierung/datenschutz" target="_blank">Datenschutzerklärung der VR Smart Finanz.</a>',
          dismiss:
              '<div class="col-md-6">'
            +   '<a role="button" class="cc-btn cc-dismiss">Ablehnen</a>'
            + '</div>',
          allow:
              '<div class="col-md-6">'
            +   '<a role="button" class="cc-btn cc-allow">Zustimmen</a>'
            + '</div>'
        },
        law: {
          regionalLaw: false
        },
        onPopupOpen : () => this._onPopupOpen(),
        onPopupClose : () => this._onPopupClose()
      }, (cookieconsentBanner) => { this.cookieconsentBanner = cookieconsentBanner; });
    }
  }

  openCookieConsentBanner() {
    if (this.cookieconsentBanner) {
      this.cookieconsentBanner.open();
    }
  }

  /**
   * Methode gibt an, ob der Benutzer seine Einwilligung für die Verwendung von Cookies gegeben hat oder nicht.
   * Diese Einwilligung wird ebenfalls in einem Cookie gespeichert (per cookieconsent Plugin).
   * Der Wert dieses Cookies wird geprüft und für den Fall einer Einwilligung des Benutzers wird true zurückgegeben.
   */
  isEinwilligungAkzeptiert() {
    const cookieStatus = this.getCookieValue(this.cookieconsentStatusCookie);
    return cookieStatus === 'allow';
  }

  readPartnerIdCookie() {
    return this.getCookieValue(this.partnerIdCookie);
  }

  writePartnerIdCookie(partnerId) {
    if (partnerId) {
      this.setCookieValue(this.partnerIdCookie, partnerId);
    }
  }

  readTeaserBetragCookie() {
    return this.getCookieValue(this.teaserBetragCookie);
  }

  writeTeaserBetragCookie(betrag) {
    if (betrag) {
      this.setCookieValue(this.teaserBetragCookie, betrag);
    }
  }

  readTeaserLaufzeitCookie() {
    return this.getCookieValue(this.teaserLaufzeitCookie);
  }

  writeTeaserLaufzeitCookie(laufzeit) {
    if (laufzeit) {
      this.setCookieValue(this.teaserLaufzeitCookie, laufzeit);
    }
  }

  getCookieValue(name) {
    if (this._$window.document.cookie) {
      const cookies = this._$window.document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split('=');
        if (cookie[0].trim() === name) {
          return cookie[1];
        }
      }
    }

    return undefined;
  }

  /**
   * Sets a cookie (key/value -pair) in the browser.
   * SameSite=None; secure: https://jira/browse/BUG-11194
   * -> To also allow setting cookies in cross-browser environments (OBS is running in a different Domain, in iFrame)
   * @param {*} name
   * @param {*} value
   */
  setCookieValue(name, value) {
    this._$window.document.cookie = name + '=' + value + '; SameSite=None; Secure; path=/';
  }

  removeCookieValue(name) {
    let value = this.getCookieValue(name);
    if (value) {
      this._$window.document.cookie = name + '=' + value + ';max-age=0';
    }
  }

  _onPopupOpen() {
    this._$rootScope.cookieconsent = true;
  }

  _onPopupClose() {
    this._$rootScope.cookieconsent = false;
    this._$rootScope.$apply();
  }
}
