import template from './bankverbindung.html';
import { PartnerIdSource } from '../../../../common/obs-url-params.service';

export {BankverbindungComponent, BankverbindungController};

const BankverbindungComponent = {
  template: template,
  controller: BankverbindungController,
  require: {
    parent: '^^finanzkennzahlen'
  }
};

function BankverbindungController(formUtilService, urlParamsService, finanzkennzahlenService, bankService, scrollService) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.model = finanzkennzahlenService.model;
    ctrl.sucheBank = sucheBank;
    ctrl.isFieldError = isFieldError;
    ctrl.isShowPartnerBankInfo = isShowPartnerBankInfo;
    ctrl.isSubmittedFormError = isSubmittedFormError;
    ctrl.removeOnPaste = removeOnPaste;
    ctrl.parent.addValidator(validate, 3);
    ctrl.sucheBank();
  };

  function removeOnPaste(event, fieldname) {
    formUtilService.removeOnPaste(event, ctrl.bankverbindungForm, fieldname);
  }

  /**
   * Prüft den Validierungsstatus der Komponente.
   */
  function validate() {
    finanzkennzahlenService.model.invalidFields.iban = [];
    if (isFormError() || !finanzkennzahlenService.model.bank) {
      finanzkennzahlenService.model.invalidFields.iban =
        formUtilService.getErroneousFields(ctrl.bankverbindungForm);
      scrollService.scrollTo('bankverbindung');
      return false;
    }
    return true;
  }

  /**
   * Liefert den Validierungsstatus eines Formularfelds zur Anzeige von Feld-spezifischen Fehlern.
   */
  function isFieldError(fieldname) {
    return formUtilService.isFieldError(ctrl.bankverbindungForm, finanzkennzahlenService.model.submitted, fieldname);
  }

  /**
   * Liefert den Validierungsstatus einer zusammengehörigen Gruppe von Formularfeldern zur Anzeige
   * einer Gruppen-Fehlermeldung.
   */
  function isFormError() {
    return formUtilService.isFormError(ctrl.bankverbindungForm);
  }

  /**
   * Liefert den Validierungsstatus einer zusammengehörigen Gruppe von Formularfeldern zur Anzeige
   * einer Gruppen-Fehlermeldung.
   */
  function isSubmittedFormError() {
    return formUtilService.isSubmittedFormError(ctrl.bankverbindungForm, finanzkennzahlenService.model.submitted);
  }

  /**
   * Sucht mit Hilfe der eingegebenen IBAN nach der aktuellen Bank.
   */
  function sucheBank() {
    if (finanzkennzahlenService.model.daten.iban) {
      bankService.searchBank(finanzkennzahlenService.model.daten.iban,
        _setBankInfo,
        _resetBankInfo);
    }
    else {
      _resetBankInfo();
    }
  }


  /**
   * Setzt die Bank-Informationen
   */
  function _setBankInfo(bank) {
    finanzkennzahlenService.model.bank = bank;
    bankService.setPartnerBank(bank);
  }

  /**
   * Resettet die Bank-Informationen
   */
  function _resetBankInfo() {
    finanzkennzahlenService.model.bank = null;
    bankService.resetBankInfo();
  }

  /**
   * Ermittelt, ob dem Kunden ein Hinweis angezeigt werden soll, dass seine Bank (durch seine
   * IBAN ermittelt) als betreuende Bank für den Vertrag verwendet wird.
   */
  function isShowPartnerBankInfo() {
    return urlParamsService.isPartnerIdSource(PartnerIdSource.IBAN) && !!urlParamsService.partnerId;
  }

  return ctrl;

}

