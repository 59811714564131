import template from './navigation.html';

export const NavigationComponent = {
  template: template,
  controller: NavigationController
};

function NavigationController($state, navigationService, popupService, messagesService, applikationStatus) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.messagesService = messagesService;
    ctrl.navigationService = navigationService;
    ctrl.getViewTitle = getViewTitle;
    ctrl.isShowNavigation = isShowNavigation;
    ctrl.isEingangskanalOBS = isEingangskanalOBS;
    ctrl.isContactItemVisible = isContactItemVisible;
    ctrl.showContact = showContact;
  };

  function getViewTitle () {
    return $state.current.viewTitle || '';
  }

  function isShowNavigation () {
    return !$state.current.isModalViewState && applikationStatus.eingangskanal === 'OBS';
  }

  function isEingangskanalOBS () {
    return applikationStatus.eingangskanal === 'OBS';
  }

  function isContactItemVisible () {
    return applikationStatus.erfassungsmodus === 'KUNDE' && applikationStatus.eingangskanal === 'OBS';
  }

  function showContact () {
    popupService.openAlert('Kontakt');
  }

}
