import template from './bestaetigung.html';

export {
    BestaetigungComponent, BestaetigungController
};

const BestaetigungComponent = {
  template: template,
  controller: BestaetigungController
};

function BestaetigungController(kontaktformularService, IsDemoMode, popupService) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function() {
    ctrl.getAnsprache = getAnsprache;
    ctrl.getKontaktart = getKontaktart;
    ctrl.getauswahlKontaktart = getauswahlKontaktart;
    ctrl.model = kontaktformularService.model;
    ctrl.isDemo = IsDemoMode;
    ctrl.popupVRSmartFinanz = popupVRSmartFinanz;
    ctrl.keNegativ = ctrl.model.daten.absprungpunkt == 'KREDITENTSCHEIDUNG_NEGATIV';
  };

  function getAnsprache() {
    if(ctrl.model.daten.absprungpunkt != 'KREDITENTSCHEIDUNG_NEGATIV'){
      const data = ctrl.model.daten.anfragedaten;
      const vorname = data.anfrageSteller.vorname;
      const nachname = data.anfrageSteller.nachname;
      return 'Guten Tag ' + vorname + ' ' + nachname + ',';
    } else {
      return 'Guten Tag ' + kontaktformularService.model.abschlussVorgang.vorname + ' ' + kontaktformularService.model.abschlussVorgang.nachname + ',';
    }
  }

  function getKontaktart() {
    return ctrl.model.daten.artTerminBestaetigung;
  }

  function popupVRSmartFinanz() {
    popupService.openAlert('vrSmartFinanz');
  }

  function getauswahlKontaktart(){
    const data = ctrl.model.daten.artTerminBestaetigung;
    if(data != ''){
      return (data === 'E-Mail' ? 'Mailadresse' : 'Telefonnummer');
    } else {
      return '';
    }
  }
}
