import IBAN from 'iban';

export const ValidateIbanDirective = (popupService) => {
  'ngInject'; //NOSONAR
  return {
    // Die Direktive wird als Attribut an dem Element verwendet
    restrict: 'A',

    // Bei der Verwendung dieser Direktive ist die Angabe des ng-model am Element zwingend erforderlich.
    require: 'ngModel',

    // scope - Parent Scope
    // element - Element bei welchem die Direktive verwendet wird
    // attr = Dictionary aller Attribute des Elements
    // ctrl - Controller für das ngModel
    link: function (scope, element, attr, ctrl) {
      ctrl.$validators.iban = function(modelValue, viewValue) {
        var value = modelValue || viewValue;

        if (!value) {
          return false;
        }

        // falscher Anfangsbuchstabe
        if (!/[a-zA-Z]{1}/g.test(value)) {
          return false;
        }
        else {
          value = value.replace(/\s/g, '').toUpperCase();

          // ausländische IBAN?
          if ((value.length === 2 || value.length % 2 == 0) && value.substring(0, 2) !== 'DE') {
            popupService.openConfirm('foreignBank');
            return false;
          }
          // isPruefzifferAb3Ziffer
          else if (!(value.length > 2 && /^..[0-9 ]+$/.test(value))) {
            return false;
          }
        }

        if (!IBAN.isValid(value)) {
          return false;
        }

        return true;
      };
    }
  };
};
