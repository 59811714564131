import template from './kontaktformular.html';
import _ from 'lodash';
import {ValidatorContainer} from '../../common/obs-form-utils.service';
import {
  applikationStatusBeantragung,
  applikationStatusLegitimation
} from '../../layout/navigation/navigation.constants';

export {KontaktformularComponent, KontaktformularController};

const KontaktformularComponent = {
  template: template,
  controller: KontaktformularController,
  bindings: {
    banken: '<'
  }
};

/**
 * Controller für die Kontaktformularseite.
 *
 * @param {*} $state
 * @param {*} kontaktformularService
 */
function KontaktformularController($state, kontaktformularService, navigationService, applikationStatus) {
  'ngInject'; //NOSONAR
  const ctrl = this;
  const validatorContainer = new ValidatorContainer();

  ctrl.$onInit = function () {
    ctrl.model = kontaktformularService.model;

    ctrl.goToNextPage = goToNextPage;
    ctrl.goToPrevPage = goToPrevPage;
    ctrl.isBankauswahl = isBankauswahl;
    ctrl.showWunschterminInBank = showWunschterminInBank;
    ctrl.isAbsprungpunktLegitimationInBank = isAbsprungpunktLegitimationInBank;
    ctrl.addValidator = addValidator;
  };

  function addValidator(validator, executionOrder) {
    validatorContainer.addValidator(validator, executionOrder);
  }

  /**
   * Wechselt zurück zur Kdf-Positiv Seite
   */
  function goToPrevPage() {
    ctrl.model.showWunschtermin = false;
    ctrl.model.showNextPageButton = false;
    ctrl.model.daten.absprungpunkt = '';
    applikationStatus.status = applikationStatusBeantragung;
    navigationService.closeModalView();
  }

  /**
   * Terminanfrage-senden-Button
   */
  function goToNextPage() {
    ctrl.model.submitted = true;
    const isValid = validatorContainer.validate();
    if (isValid) {
      kontaktformularService.sendKontaktanfrage()
        .then(() => {
          // Legitimation in der Bank
          applikationStatus.status = applikationStatusLegitimation;
          $state.go('bestaetigung');
        });
    }
  }

  /**
   * Entscheidet, ob die Liste der Flexibel Banken zur Auswahl angezeigt wird.
   */
  function isBankauswahl() {
    return !_.isEmpty(ctrl.banken);
  }

  function showWunschterminInBank() {
    return ctrl.model.showWunschtermin;
  }

  function isAbsprungpunktLegitimationInBank() {
    return ctrl.model.daten.absprungpunkt == 'LEGITIMATION';
  }
}
