export default class RequestWrapperService {

   /**
   * @param $http
   * @param $log
   * @param $state
   * @param $q
   * @param $location
   * @param ServerUrl
   * @param waiting
   * @param {EndpointsService} endpointsService
   * @param {PiwikService} piwikService
   * @param {PopupService} popupService
   * @param {ErrorMessageService} errorMessageService
   * @param {MessagesService} messagesService
   * @param {LoggingService} loggingService
   * @param {ObsTokenService} obsTokenService
   */
  constructor($http, $log, $state, $q, $location, ServerUrl, waiting, endpointsService, piwikService, popupService,
    errorMessageService, messagesService, loggingService, obsTokenService) {
    'ngInject'; //NOSONAR

    this.defaultOption = {
      method: 'GET',
      timeout: 60000
    };

    this.$http = $http;
    this.$log = $log;
    this.$state = $state;
    this.$q = $q;
    this.$location = $location;

    this.ServerUrl = ServerUrl;
    this.waiting = waiting;
    this.endpointsService = endpointsService;
    this.piwikService = piwikService;
    this.popupService = popupService;
    this.errorMessageService = errorMessageService;
    this.messagesService = messagesService;
    this.loggingService = loggingService;
    this.obsTokenService = obsTokenService;
  }

  sendRequest(endpointName, options, withMask, param) {
    const showMask = withMask === true || withMask === undefined;
    if (showMask && !this.endpointsService.useDemo) {
      this.waiting.showWaiting();
    }

    let requestUrl = this.endpointsService.getUrl(endpointName);
    options = options || {};
    if (param) {
      requestUrl += '/' + param;
      options.urlParam = param;
    }
    options.url = requestUrl;

    if(this.obsTokenService.hasZugangscode()){
      options.headers = angular.extend({}, options.headers, this.obsTokenService.getAccessTokenHeader());
    }

    if (this.endpointsService.useDemo) {
      /*eslint no-console: off*/
      console.log('Mock request ' + requestUrl + (options.data ? ' with data ' + JSON.stringify(options.data) : '')); // NOSONAR
      return this.$q.when(this.endpointsService.getDemoEndpointHandler(endpointName)(options, this.$q),
        this._logResponse, !options.useCustomErrorHandler ? this._handleError.bind(this) : null);
    }

    return this._sendRequest(options, showMask);
  }

  _logResponse(response){
    console.log('Mock response', response); // NOSONAR
    return response;
  }

  downloadUrl(endpointName, param) {
    const ua = navigator.userAgent;
    const ieEDGE = ua.match(/Edge/g);
    const ie = ua.match(/.NET/g); // IE 11+
    const oldIE = ua.match(/MSIE/g);
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    //VRL-1261
    //Mobile Safari öffnet eine Blob-URL im aktuellen Fenster und überschreibt
    //damit die SPA; das a.download-Attribut wird nicht unterstützt
    //daher neues Fenster öffnen und PDF dort laden
    if (iOSSafari || this.endpointsService.useDemo) {
      let requestUrl = this.endpointsService.getUrl(endpointName) + '/' + param;
      if (this.endpointsService.useDemo) {
        //bei Auslieferung über Webserver muss Dateiendung zwecks Zuordnung des Mime-Types erfolgen
        requestUrl = requestUrl + '.pdf';
      }
      window.open(requestUrl);
    } else {
      return this.sendRequest(endpointName, {
        responseType: 'arraybuffer',
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'If-Modified-Since': 0
        }
      }, true, param)
        .then( (response) => {
          const responseHeaders = response.headers();
          const contentDispositionHeader = response.headers('Content-Disposition');
          const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
          const filename = result.replace(/"/g, '');

          const blob = new Blob([response.data], { type: responseHeaders['content-type'] });

          if (ie || oldIE || ieEDGE) {
            window.navigator.msSaveBlob(blob, filename);
          }
          else {
            const uri = window.URL.createObjectURL(blob);

            const anchor = document.createElement('a');
            document.body.appendChild(anchor);
            anchor.href = uri;
            anchor.download = filename;
            anchor.click();
            document.body.removeChild(anchor);
          }
        }, (err) => {
          this.$log.debug(err);
        });
    }
  }

  _sendRequest(optionParams, showMask) {
    const options = angular.extend({}, this.defaultOption, optionParams);
    const requestMethod = options.method.toUpperCase();

    if (requestMethod === 'GET') {
      options.params = options.params || {};
    }

    if (this.endpointsService.useMocks && options.addServerInfo && options.data) {
      options.data.webserver = this.$location.protocol() + '://' + this.$location.host() + ':' +
        this.$location.port();
      options.data.apiserver = this.ServerUrl || options.data.webserver;
    }

    return this.$http(options)
      .then( (response) => {
        this.$log.debug('[INFO] Response ok!');
        if (showMask) {
          this.waiting.hideWaiting();
        }
        return response;
      })
      .catch( (response) => {
        if (showMask) {
          this.waiting.hideWaiting();
        }
        this._logError(options, response);
        if (!options.useCustomErrorHandler) {
          return this._handleError(response);
        } else {
          return this.$q.reject(response);
        }
      });
  }

  _handleError(response) {
    if (response.data && response.data.cancelProcess === false){ //cancelProcess or not
      const msg = response.data.meldungen && response.data.meldungen[0];

      // Ist die Meldung in obs-messages beschrieben, wird diese verwendet!
      if (!!response.data.errorCode && this.messagesService.isMessageAvailable(response.data.errorCode)) {
        if (this.messagesService.isMessageWithReDirect(response.data.errorCode)) {
          this.popupService.openConfirm(response.data.errorCode);
        }
        else {
          this.popupService.openAlert(response.data.errorCode);
        }
      }
      else {
        this.popupService.openAlertFromServer(msg);
      }
    } else {
      if (response.data && response.data.meldungen) {
        this.errorMessageService.setMessage(response.data.meldungen);
      }
      this.$state.go('error');
    }
    return this.$q.reject(response);
  }

  _logError(options, response) {
    try {
      const responseMsg = response && response.data ? JSON.stringify(response.data) :
        (response.status === -1 && response.data == null) ? 'not available, could be a CORS issue or a client side timeout' : 'empty';
      const errorMsg = `https ${options.method.toUpperCase()} ${options.url} returned status ${response.status}, response: ${responseMsg}`;

      this.$log.debug('[ERROR]: ' + errorMsg);
      if (!response.data || response.data.cancelProcess) {
        this.piwikService.trackServerError(errorMsg);
        this.loggingService.error(errorMsg);
      }
    }
    catch (error) {
      this.$log.debug('[ERROR]: Logging failed: ' + JSON.stringify(error));
    }
  }
}
