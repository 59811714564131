import template from './finanzierungsvorhaben.html';
import { applikationStatusBeantragung } from '../../layout/navigation/navigation.constants';

export { FinanzierungsvorhabenComponent, FinanzierungsvorhabenController };

const FinanzierungsvorhabenComponent = {
  template: template,
  controller: FinanzierungsvorhabenController,
  bindings: {
    kalkulationParameter: '<'
  }
};

/**
 * Controller für die UI-Komponente zur Darstellung der Finanzierungsvorhaben-Maske
 * (Einstiegsmaske)
 */
function FinanzierungsvorhabenController($state, finanzierungsvorhabenService, applikationStatus, piwikService, interessentenService) {
  'ngInject'; //NOSONAR
  const ctrl = this;
  ctrl.$onInit = function () {
    ctrl.goToNextPage = goToNextPage;
    applikationStatus.status = applikationStatusBeantragung;
  };

  /*
    * Weiter-Button
    */
  function goToNextPage() {
    trackProcessStep();
    $state.go('kontaktdaten').then(() => {
      if (interessentenService.iCode != null){   
        interessentenService.save();
      }}
    );    
  }

  /**
   * Piwik-Tracking bei Wechsel zum nächsten Prozess-Schritt
   */
  function trackProcessStep() {
    const finParams = finanzierungsvorhabenService.finanzierungswunschModel.finanzierungsparameter;
    piwikService.trackValidationSuccess([finParams.berechnungsartBudgetrechner ? 'Wunschrate' : 'Wunschbetrag',
      finParams.betragSlider.value, finParams.laufzeitSlider.value, finParams.rateSlider.value]);
  }

}

