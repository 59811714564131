import template from './obs-footer.html';
import './_obs-footer.scss';

export {FooterComponent, FooterController};

const FooterComponent = {
  template: template,
  controller: FooterController
};

function FooterController(cookieService) {
  'ngInject'; //NOSONAR
  const ctrl = this;


  ctrl.$onInit = () => {
    ctrl.openCookieBanner = openCookieBanner;
  };

  function openCookieBanner() {
    cookieService.openCookieConsentBanner();
  }
}
