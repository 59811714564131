import './_obs-themes-vrl.scss';
import './_obs-themes-hvb.scss';

/**
 * Service zur Aktivierung verschiedener Themes (Designvarianten),
 * die anhand des URL-Parameters referrer ermittelt werden.
 */
export default class ThemesService {
  constructor($rootScope, defaultTheme) {
    'ngInject'; //NOSONAR
    this._$rootScope = $rootScope;
    this._defaultTheme = defaultTheme;
    //Mapping von Referrer zu Theme
    this._themeMapping = {
      vrb: 'ogr_theme', //Referrer: VR Bank
      vrbo: 'ogr_theme', //Referrer: VR Business Online
      vrl: 'vrl_theme', //Referrer: VR-Smart Finanz
      hvb: 'hvb_theme' //Referrer: HVB
    };

  }
  applyTheme (referrer) {
    var theme = this._defaultTheme;
    referrer = referrer || '';
    referrer = referrer.toLowerCase();
    if (this._themeMapping.hasOwnProperty(referrer)) {
      theme = this._themeMapping[referrer];
    }
    this._$rootScope.appliedTheme = theme;
  }

}

