import template from './finanzkennzahlen.html';
import './_finanzkennzahlen.scss';
import { ValidatorContainer } from '../../../common/obs-form-utils.service';


export {FinanzkennzahlenComponent, FinanzkennzahlenController};

const FinanzkennzahlenComponent = {
  template: template,
  controller: FinanzkennzahlenController,
  bindings: {
    kalkulationParameter: '<'
  }
};
/**
 * Controller für die Maske des Menüpunkts "Unternehmen" (Eingabe der Unternehmenskennzahlen).
 */
function FinanzkennzahlenController($state, finanzkennzahlenService, anfragedatenService, piwikService, interessentenService, kontaktdatenService) {
  'ngInject'; //NOSONAR
  const ctrl = this;
  const validatorContainer = new ValidatorContainer();

  ctrl.$onInit = function () {
    ctrl.goToPrevPage = goToPrevPage;
    ctrl.goToNextPage = goToNextPage;
    ctrl.addValidator = addValidator;
    ctrl.canGoToNextPage = canGoToNextPage;
    if (!finanzkennzahlenService.initialized) {
      finanzkennzahlenService.initModel();
    }
    if (ctrl.kalkulationParameter) {
      finanzkennzahlenService.bwaAkzeptiert = ctrl.kalkulationParameter.bwaAkzeptiert
       && kontaktdatenService.model.firmaModel.rechtsformPruefung.rechtsformBWAAkzeptiert;
    }
  };

  function addValidator(validator, executionOrder) {
    validatorContainer.addValidator(validator, executionOrder);
  }

  /*
    * Zurück-Button
    */
  function goToPrevPage() {
    $state.go('kontaktdaten');
  }

  /*
    * Weiter-Button
    */
  function goToNextPage() {
    finanzkennzahlenService.model.submitted = true;
    const isValid = validatorContainer.validate();
    trackProcessStep(isValid);
    if (isValid) {
      anfragedatenService.pruefeKdf()
      .then(function () {
        if (finanzkennzahlenService.kdfPositiv) {
          $state.go('kdfPositiv').then(() => {
            interessentenService.save();
          });
        } else {
          $state.go('kdfNegativ').then(() => {
            interessentenService.save();
          });
        }
      });
    }
  }

  /**
   * Piwik-Tracking bei Wechsel zum nächsten Prozess-Schritt
   */
  function trackProcessStep(success) {
    if (success) {
      piwikService.trackValidationSuccess([ finanzkennzahlenService.model.daten.finanzkennzahlenArt ]);
    }
    else {
      piwikService.trackValidationFailure(finanzkennzahlenService.model.invalidFields.einnahmenAusgaben
        .concat(finanzkennzahlenService.model.invalidFields.iban));
    }
  }

  /**
   *
   */
  function canGoToNextPage() {
    return finanzkennzahlenService.model.daten.finanzkennzahlenArt != null;
  }

}
