import _ from 'lodash';
import template from './obs-upload.html';
import './_obs-upload.scss';
// Import für angular-file-upload Komponente ist in abschluss.module.js, da
// Upload-Funktionalität nur im Abschlussbereich verwendet wird und die Bundlegröße
// reduziert werden soll; falls Upload anwendungsübergreifend genutzt werden sollte,
// muss der Import hier erfolgen.

/**
 * Upload Komponente
 * https://github.com/nervgh/angular-file-upload
 */
export const UploadComponent = {
  template: template,
  controller: UploadComponentController,
  controllerAs: 'vm',
  bindings: {
    titel: '@',
    uploadhinweis: '@',
    beschreibung: '@',
    iconClass: '@',
    quelle: '<',
    uploadUrl: '<',
    uploadConstraints: '<',
    uploadInitList: '<',
    showErrorOnEmptyList: '<',
    onUploadFile: '&',
    onDeleteFile: '&'
  },
  transclude: true
};
export const DistinctFilesFilter = () => {
  return distinctFiles;
};

//Default Constraints, können mit Input-Parameter uploadConstraints überschrieben werden
let acceptedFileTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/tiff'];
let maxFileSize = 10 * 1024 * 1024; // 10 MB
let maxFiles = 10;

// Löscht alle Queue-Entries mit dem gegebenen filename aus der Queue
// und gibt anschließend die Anzahl verbleibender eindeutiger
// Queue-Einträge zurück
function removeFromQueueByName(uploader, filename) {
  _.forEach(
    _.filter(uploader.queue, function(queueItem) {
      return queueItem.file.name === filename;
    }),
    function(item) {
      uploader.removeFromQueue(item);
    });
  return distinctFiles(uploader.queue).length;
}

// Fügt dem Uploader bereits hochgeladene Dateien in die Queue hinzu,
// damit diese angezeigt werden können
function addUploadedFiles(FileUploader, uploader, uploadInitList) {
  function removeItem(filename) {
    return function() {
      return removeFromQueueByName(uploader, filename);
    };
  }
  //bereits hochgeladene Dateien in die Queue stellen um sie anzuzeigen
  if (uploadInitList) {
    for (var i = 0; i < uploadInitList.length; i++) {
      var uploadQueueItem = new FileUploader.FileItem(uploader, {
        lastModifiedDate: new Date(),
        name: uploadInitList[i].filename,
        size: uploadInitList[i].fileSize,
        type: uploadInitList[i].mimeType
      });
      uploadQueueItem.id = uploadInitList[i].id,
      uploadQueueItem.progress = 100;
      uploadQueueItem.quelle = uploadInitList[i].herkunftType;
      uploadQueueItem.isUploaded = uploadInitList[i].success;
      uploadQueueItem.isSuccess = uploadInitList[i].success;
      uploadQueueItem.isError = !uploadInitList[i].success;
      uploadQueueItem.removeFromQueue = removeItem(uploadInitList[i].filename);
      uploader.queue.push(uploadQueueItem);
    }
  }
}

// Fügt dem Uploader Filter hinzu, die beschränken, welche Dateien angenommen werden
function addFilters(FileUploader, uploader, popupService) {
  uploader.filters.push({
    name: 'maxFiles',
    fn: function(item) {
      var queueClone = uploader.queue.slice(0);
      queueClone.push({
        file: {
          name: item.name
        }
      });
      return distinctFiles(queueClone).length <= maxFiles;
    }
  });
  uploader.filters.push({
    name: 'imageFilter',
    fn: function(item) {
      return acceptedFileTypes.indexOf(item.type) !== -1;
    }
  });
  uploader.filters.push({
    name: 'sizeFilter',
    fn: function(item) {
      return item.size <= maxFileSize && item.size > 0;
    }
  });
  uploader.filters.push({
    name: 'overwriteFilter',
    fn: function(item, options, deferred) {
      const isDuplicate = _.find(uploader.queue, (queueItem) => {
        return (queueItem.file.name === item.name);
      });
      if (isDuplicate) {
        popupService.openConfirm('uploadUeberschreiben', [item.name])
        .then(() => { deferred.resolve(); })
        .catch(() => { deferred.reject(); });
      } else {
        deferred.resolve();
      }
    }
  });
  uploader.filters.push({
    name: 'demoFilter',
    fn: function() {
      return !FileUploader.demoMode;
    }
  });
}

// Fügt dem Uploader Lifecycle Hooks hinzu, um auf Events zu reagieren
function addEventListeners(uploader, vm, popupService, loggingService) {
  uploader.onAfterAddingFile = function(item) {
    vm.clearFilterErrors();
    item.quelle = vm.quelle;
  };
  uploader.onWhenAddingFileFailed = function(item, filter) {
    if (filter && filter.name) {
      if (filter.name == 'demoFilter') {
        //Upload im Demo-Modus nur simulieren durch Hinzufügen eines Eintrags in der Uploader-Queue
        item.isSuccess = true;
        item.isUploaded = true;
        item.quelle = vm.quelle;
        item.file = {
          name: item.name
        };
        item._destroy = () => {
          //ohne Funktionalität in der Demo; Funktion wird von der
          //Uploader-Komponente aufgerufen und muss daher definiert sein
        };
        uploader.onSuccessItem(item, { id: 1 });
        uploader.queue.push(item);
      } else {
        vm.error[filter.name] = true;
      }
    }
  };
  uploader.onSuccessItem = function(item, response) {
    if (response.id) {
      item.id = response.id;
      item.removeFromQueue = function() {
        return removeFromQueueByName(uploader, item.file.name);
      };
    }
    vm.onUploadFile({item: item});
  };
  uploader.onErrorItem = function(item, response, status) {
    if (response && response.meldungen && response.meldungen[0]) {
      popupService.openAlertFromServer(response.meldungen[0]);
    } else {
      popupService.openAlert('uploadError');
    }
    if (!response || response.cancelProcess) {
      const responseMsg = response ? JSON.stringify(response) :
        (status === -1 && response == null) ? 'not available, could be a CORS issue or a client side timeout' : 'empty';
      loggingService.error(`https POST ${uploader.url} returned status ${status}, response: ${responseMsg}`);
    }
    vm.onUploadFile({item: item});
  };
}

function UploadComponentController(FileUploader, popupService, loggingService) {
  'ngInject'; //NOSONAR
  const vm = this;
  vm.$onInit = function() {
    if (vm.uploadConstraints) {
      acceptedFileTypes = vm.uploadConstraints.validMimeTypes || acceptedFileTypes;
      maxFileSize = vm.uploadConstraints.maxFileSize || maxFileSize;
      maxFiles = vm.uploadConstraints.maxFileCount || maxFiles;
    }
    vm.filterNames = 'maxFiles, imageFilter, sizeFilter, overwriteFilter, demoFilter';
    vm.maxFileSizeMB = maxFileSize / 1024 / 1024;
    vm.maxFileCount = maxFiles;
    vm.acceptTypes = getAcceptedFileTypes(acceptedFileTypes);
    vm.error = {};
    vm.clearFilterErrors = function() {
      _.forEach(vm.filterNames.split(','), function(filter) {
        vm.error[filter.trim()] = false;
      });
    };

    // Uploader instanziieren
    vm.uploader = new FileUploader({
      url: vm.uploadUrl,
      autoUpload: true
    });
    //bereits hochgeladene Dateien in die Queue stellen um sie anzuzeigen
    addUploadedFiles(FileUploader, vm.uploader, vm.uploadInitList);
    //Validierungsfilter erzeugen
    addFilters(FileUploader, vm.uploader, popupService);
    //Event Listener registrieren
    addEventListeners(vm.uploader, vm, popupService, loggingService);
    //Löschfunktion definieren
    vm.deleteUpload = function(uploadItem) {
      //BUG-6988 durch Löschen werden bestehende Fehlermeldungen ggf. obsolet
      vm.clearFilterErrors();
      vm.onDeleteFile({item: uploadItem});
    };
  };
}

// Filtert die Dateien unique by file.name.
// Last one wins.
function distinctFiles(items) {
  if (!items || !items.length) {
    return items;
  }
  var output = [];
  var names = [];
  for (var i = 0; i < items.length; i++) {
    var name = items[i].file.name;
    var idx = names.indexOf(name);
    // Duplikat, alten löschen, der neue kommt ans ende
    if (idx !== -1) {
      names.splice(idx, 1);
      output.splice(idx, 1);
    }
    names.push(name);
    output.push(items[i]);
  }
  return output;
}

// Liefert einen komma-separierten String mit allen unterstützten
// Dateitypen; erhält Array mit Mediatypes (MIME Typen)
function getAcceptedFileTypes(mediaTypeArray) {
  if (mediaTypeArray && mediaTypeArray.length) {
    var accepted = mediaTypeArray.join();
    //Sonderbehandlung für Mediatype images/tiff; in IE und Firefox werden
    //im FileChooser-Dialog nur *.tiff Dateien angezeigt, nicht jedoch *.tif
    if (_.find(mediaTypeArray, function(t) { return t === 'image/tiff'; })) {
      accepted = accepted + ',.tif';
    }
    return accepted;
  } else {
    return '';
  }
}
