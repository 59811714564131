import angular from 'angular';
import commonModule from '../../common/common.module';

import navigationService from './navigation.service';
import { NavigationComponent } from './navigation.component';
import './_navigation.scss';
import './_navtabs.scss';

export default angular
  .module('obs.navigation', [
    commonModule
  ])
  .component('obsNavigation', NavigationComponent)
  .service('navigationService', navigationService)
  .name;
