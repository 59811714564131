import template from './obs-slider.html';
import './_obs-slider.scss';

export {SliderComponent, SliderComponentController, initSlider};

const SliderComponent = {
  bindings: {
    slider: '<',
    inputDisabled: '<',
    outputHidden: '<',
    inputDecimalPlaces: '<',
    unit: '@',
    titleLabel: '@',
    onChange: '&',
    onEnd: '&'
  },
  transclude: true,
  template: template,
  controller: SliderComponentController
};

function SliderComponentController() {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    //isTooLow repräsentiert den Fehlerfall, wenn nach Verlassen des Eingabefelds die Eingabe unterhalb der Grenze liegt
    ctrl.isTooLow = false;
    //isTooLowInputPending repräsentiert den Fehlerfall, wenn während der Eingabe im Eingabefeld die Eingabe unterhalb der Grenze liegt
    //Beispiel: Anwender löscht den Feldinhalt, dann ist editvalue = 0
    ctrl.isTooLowInputPending = false;
    ctrl.isTooHigh = false;
    ctrl.hasError = false;
    ctrl.editvalue = ctrl.slider.value;
    //editvalueChanged wird true, wenn im Eingabefeld des Sliders eine gültige Eingabe getätigt wird;
    //nur wenn editvalueChanged==true, löst der onEnd-Handler beim Verlassen des Eingabefelds (blur-Event) aus
    ctrl.editvalueChanged = false;
    ctrl.slider.isValid = isValid;
    ctrl.slider.options.onChange = onChange;
    ctrl.slider.options.onEnd = onEnd;
    ctrl.validateInput = validateInput;
    ctrl.focusInput = focusInput;
    ctrl.blurInput = blurInput;
    ctrl.changeInput = changeInput;
    ctrl.keyDown = keyDown;

    //initiale Validierung
    validateInput(ctrl.editvalue, false);
  };

  /**
   * Listener auf Model-Changes
   */
  ctrl.$onChanges = function (changes) {
    //falls das Binding "slider" von außen geändert wurde, soll der Wert des Eingabefelds (editvalue)
    //auf den aktuellen Wert des Sliders (value) gesetzt werden; damit wird sichergestellt, dass Änderungen
    //am Model auch mit dem Eingabefeld synchronisiert sind.
    if (changes.slider && ctrl.slider.value != ctrl.editvalue) {
      ctrl.editvalue = ctrl.slider.value;
      ctrl.editvalueChanged = false;
    }
  };

  function isValid() {
    return !(ctrl.isTooHigh || ctrl.isTooLow || ctrl.isTooLowInputPending || ctrl.hasError);
  }

  /**
   * Handler, der fortlaufend während des Bewegens des Sliders ausgelöst wird.
   */
  function onChange() {
    //for debugging: console.log('slider changed: ' + vm.slider.value);
    ctrl.editvalue = ctrl.slider.value;
    ctrl.hasError = false;
    ctrl.isTooLow = false;
    ctrl.isTooLowInputPending = false;
    ctrl.isTooHigh = false;
    if (ctrl.onChange) {
      ctrl.onChange();
    }
  }

  /**
   * Handler, der beim Loslassen des Sliders ausgelöst wird.
   */
  function onEnd() {
    ctrl.editvalue = ctrl.slider.value;
    ctrl.hasError = false;
    ctrl.isTooLow = false;
    ctrl.isTooLowInputPending = false;
    ctrl.isTooHigh = false;
    if (ctrl.onEnd) {
      ctrl.onEnd();
    }
  }

  function validateInput(newVal, inputPending) {
    if (isNaN(newVal)) {
      ctrl.hasError = true;
      ctrl.isTooLow = false;
      ctrl.isTooLowInputPending = false;
      ctrl.isTooHigh = false;
    } else if (newVal < ctrl.slider.options.floor) {
      ctrl.hasError = false;
      ctrl.isTooLow = inputPending ? false : true;
      ctrl.isTooLowInputPending = true;
      ctrl.isTooHigh = false;
    } else if (newVal > ctrl.slider.options.ceil) {
      ctrl.hasError = false;
      ctrl.isTooHigh = true;
      ctrl.isTooLow = false;
      ctrl.isTooLowInputPending = false;
    } else {
      ctrl.hasError = false;
      ctrl.isTooLow = false;
      ctrl.isTooLowInputPending = false;
      ctrl.isTooHigh = false;
      ctrl.slider.value = newVal;
    }
  }

  function focusInput() {
    ctrl.editvalueChanged = false;
  }

  function blurInput() {
    ctrl.validateInput(ctrl.editvalue, false);
    if (isValid() && ctrl.editvalueChanged && ctrl.onEnd) {
      ctrl.editvalueChanged = false;
      ctrl.onEnd();
    }
  }

  function changeInput() {
    ctrl.validateInput(ctrl.editvalue, true);
    if (isValid() && ctrl.onChange) {
      ctrl.editvalueChanged = true;
      ctrl.onChange();
    }
  }

  function keyDown($event) {
    if (13 === $event.keyCode) {
      //Return bewirkt Verlassen des Felds mit Übernahme des eingebenen Wertes, falls dieser gültig ist
      //falls ungültig, bleibt der Cursor im Eingabefeld
      blurInput();
      if (isValid()) {
        $event.srcElement.blur();
      }
    } else if (27 === $event.keyCode) {
      //Escape bewirkt Verlassen des Felds mit Zurücksetzen auf den letzten gültigen Wert, falls der aktuelle
      //Eingabewert ungültig ist; Übernahme, falls er gültig ist
      blurInput();
      if (!isValid()) {
        ctrl.editvalue = ctrl.slider.value;
      }
      $event.srcElement.blur();
    }
  }

}

/**
 * Globale Konfiguration aller Slider, wird in der Module-Definition als run-Block
 * ausgeführt.
 * @param {} RzSlider
 * @param {*} RzSliderOptions
 */
function initSlider(RzSlider, RzSliderOptions) {
  'ngInject'; //NOSONAR
  // Patch Slider-Komponente; Behebung von Darstellungsfehlern im IFrame (BUG-7870)
  // Erweiterung der positionTrackingHandle-Funktion im Prototypen des RzSlider Services
  RzSlider.prototype.__positionTrackingHandle = RzSlider.prototype.positionTrackingHandle;
  RzSlider.prototype.positionTrackingHandle = function(newValue) {
    //falls die Instanzvariable tracking (enthält normalerweise die Werte 'lowValue' oder
    //'highValue' um festzulegen, welcher Slider-Knopf per Dragndrop bewegt wird)
    //nicht gesetzt ist (sollte nicht sein, tritt aber  z.B. auf, wenn ein Slider
    //außerhalb der i-Frame Grenzen gezogen und losgelassen wird und dann ein zweiter Slider
    //verwendet wird), soll keine weitere Verarbeitung des Dragndrop Events stattfinden.
    if (this.tracking === '') {
      return;
    }
    RzSlider.prototype.__positionTrackingHandle.call(this, newValue);
  };
  // Defaults für alle Slider
  RzSliderOptions.options({
    hideLimitLabels: true,
    hideMinLimitLabel: false,
    hideMaxLimitLabel: false,
    hideMinLimitValue: false,
    hideMaxLimitValue: false,
    showSelectionBar: true,
    hidePointerLabels: true,
    enforceStep: false,
    keyboardSupport: false
  });
}


