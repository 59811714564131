import template from './firmensuche-auswahl.html';

export { FirmensucheAuswahlComponent, FirmensucheAuswahlController };

const FirmensucheAuswahlComponent = {
  template: template,
  controller: FirmensucheAuswahlController,
  bindings: {
    onErneuteSuche: '&'
  }
};

/**
 * Controller für die Anzeige der Suchkriterien zur Firmensuche.
 *
 * @param firmenSuchService
 */
function FirmensucheAuswahlController(firmenSuchService) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.model = firmenSuchService.model;
    ctrl.sucheAnzeigen = sucheAnzeigen;
    if (!firmenSuchService.initialized) {
      firmenSuchService.initModel();
    }
  };

  function sucheAnzeigen() {
    firmenSuchService.sucheAnzeigen();
    ctrl.onErneuteSuche({});
  }

}
