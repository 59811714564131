import template from './obs-sterne.html';
import './_obs-sterne.scss';

export const SterneComponent = {
  bindings: {
    maximaleAnzahl: '<',
    matomoName: '@',
    beschriftungen: '<',
    rating: '&'
  },
  template: template
};
