import template from './interessent.html';
import './_interessent.scss';

export { InteressentComponent, InteressentController };

const InteressentComponent = {
  template: template,
  controller: InteressentController
};

/**
 * @param $timeout
 * @param $state
 * @param $log
 * @param IsDemoMode
 * @param {PiwikService} piwikService
 * @param {InteressentenService} interessentenService
 * @param {FormUtilService} formUtilService
 * @param $stateParams
 * */
function InteressentController($timeout, $state, $log, IsDemoMode, piwikService, interessentenService,
  formUtilService, $stateParams) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.model = {
      loaded: false,
      showPassword: false,
      submitted: false,
      errorCounter: 0,
      technicalError: false,
      daten: {
        zugangscode: null
      }
    };

    ctrl.togglePasswordDisplay = togglePasswordDisplay;
    ctrl.confirmCode = confirmCode;
    ctrl.isFieldError = isFieldError;

    ctrl.isDemo = IsDemoMode;

    $timeout(() => {
      ctrl.model.loaded = true;
      document.getElementById('zugangscode').focus();
    }, 500);
    piwikService.trackPopup('Ihr Zugangscode', 'Open');
  };

  function togglePasswordDisplay() {
    ctrl.model.showPassword = !ctrl.model.showPassword;
  }

  function confirmCode() {
    ctrl.model.submitted = true;
    piwikService.trackElementClick('Zugangscode bestätigen');
    if (ctrl.interessentForm.$valid) {
      interessentenService.load($stateParams.iCode, ctrl.model.daten.zugangscode).then((response) => {
        $state.go(response);
      }).catch((error) => {
        $log.debug('Fehler bei der Prüfung des Zugangscodes', error);
        if (error.status === 401) {
          ctrl.model.errorCounter++;
          piwikService.trackServerErgebnis('Ihr Zugangscode',
            ctrl.model.errorCounter > 2 ? 'Fehlermeldung/Hinweis Nr. 3' : 'Fehlermeldung/Hinweis Nr. 1');
        } else {
          ctrl.model.technicalError = true;
        }
      });
    }
  }

  function isFieldError() {
    return formUtilService.isFieldError(ctrl.interessentForm, ctrl.model.submitted, 'zugangscode') || this.model.errorCounter > 0;
  }
}
