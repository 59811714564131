import _ from 'lodash';


const ValidatorContainer = class {

  constructor () {
    this.validatoren = [];
  }

  addValidator (validator, executionOrder) {
    this.validatoren.push(
      {executionOrder: executionOrder || this.validatoren.length + 1, validator: validator});
  }

  removeValidator (validator) {
    this.validatoren.splice(_.findIndex(this.validatoren, ['validator',validator]), 1);
  }

  validate () {
    var isValidAll = true;
    var ordered = _.orderBy(this.validatoren, ['executionOrder'], ['asc']);
    _.forEach(ordered, function(item) {
      var isValid = item.validator();
      isValidAll = isValidAll && isValid;
    });
    return isValidAll;
  }
};

const FormUtilService = class {

  isFieldError (form, formSubmitted, fieldname) {
    //Verwendung von $dirty oder $touched steuert Zeitpunkt der Fehleranzeige:
    //$dirty ist ein Feld erst nach Änderungen, $touched bereits beim Verlassen des Feldes ohne Änderung
    return (formSubmitted || form[fieldname].$dirty) && form[fieldname].$invalid;
  }

  isFormError (form) {
    return (form.$invalid);
  }

  isSubmittedFormError (form, formSubmitted) {
    return (formSubmitted && this.isFormError(form));
  }

  getErroneousFields (form) {
    const erroneousFields = [];
    Object.keys(form.$error).forEach((errorType) => {
      form.$error[errorType].forEach((error) => {
        const trackingName = typeof error.$$attr.matomoName !== 'undefined' ? error.$$attr.matomoName : error.$name;
        if(erroneousFields.indexOf(trackingName) < 0) {
          erroneousFields.push(trackingName);
        }
      });
    });
    return erroneousFields;
  }

  removeOnPaste(event, form, fieldname, expression) {
    if (!expression) {
      expression = /^\s*|^\r?\n|\r/g; // default - nur führende Whitespaces und Leerzeichen entfernen
    }

    const clipboardData = event.clipboardData || window.clipboardData || event.originalEvent.clipboardData;
    const source = clipboardData.getData('Text');
    const value = source.replace(expression, '');

    form[fieldname].$setViewValue(value);
    form[fieldname].$render();

    event.preventDefault();

    return value;
  }
};

export {FormUtilService, ValidatorContainer};
