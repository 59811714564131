export { blinkSliderTitle, animateSliderBar };

/**
 * Startet und resettet die Blink-Animation des Slider-Labels.
 * @param {*} sliderModel 
 * @param {*} $timeout
 * @param {*} $delayMillis Verzögerung des Starts der Animation
 */
function blinkSliderTitle(sliderModel, $timeout, delayMillis) {
  $timeout(() => {
    sliderModel.options.animatedTitle = true;
    //Reset des Flags nach Ablauf der Animation
    $timeout(() => sliderModel.options.animatedTitle = false, 3000);
  }, delayMillis > 0 ? delayMillis : 0);
}

/**
 * Startet und resettet die Animation des Slider-Reglers.
 * @param {*} sliderModel 
 * @param {*} $timeout
 * @param {*} $delayMillis Verzögerung des Starts der Animation
 */
function animateSliderBar(sliderModel, $timeout, delayMillis) {
  $timeout(() => {
    sliderModel.options.animatedBar = true;
    $timeout(() => sliderModel.options.animatedBar = false, 3000);
  }, delayMillis > 0 ? delayMillis : 0);
}
