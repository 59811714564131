

/**
 * Dieser Service enthält Funktionen für die Kontaktdatenseite und
 * dient als Wrapper für untergeordnete Services, die den einzelnen
 * Seitenbereichen (Unternehmensangaben, persönliche Daten etc.) zugeordnet sind.
 */
export default class KontaktdatenService {

  constructor (firmenSuchService, personDatenService) {
    'ngInject'; //NOSONAR

    this._firmenSuchService = firmenSuchService;
    this._personDatenService = personDatenService;
    this.model = {
      firmaModel: firmenSuchService.model,
      personModel: personDatenService.model,
      showPersonDaten: false,
      showEinwilligungWerbung: false,
      showNextPageButton: false
    };
  }

  /**
   * Prüft ob alle Modelle der Seite valide sind.
   */
  isValid () {
    return this._firmenSuchService.isValid() && this._personDatenService.isValid();
  }

}
