import { ogrFrameMsg } from './iframe-messaging';

export default class ScrollService {
  constructor($document, $log, popupService) {
    'ngInject'; //NOSONAR
    this._$document = $document;
    this._$log = $log;
    this._popupService = popupService;
  }

  scrollToTop() {
    if (ogrFrameMsg.runsInIframe()) {
      ogrFrameMsg.scrollToTop();
      this._$log.debug('[INFO] IFrame Scrolling top');
    } else {
      window.scrollTo(0, 0);
      this._$log.debug('[INFO] Scrolling top');
    }
  }
  /**
   * Scrollt das Fenster zu der angegeben ID. Falls kein offset angegeben ist,
   * wird die ID zentriert gescrollt.
   *
   * @param {String} elementId
   * @param {number} offset
   * @param {*} popUpType
   */
  scrollTo(elementId, popUpType, offset) {
    var domElement = document.getElementById(elementId);
    //Falls die Anwendung im IFrame läuft, muss im Parent Window gescrollt werden,
    //da der IFrame keine Scrollbalken enthält
    if (ogrFrameMsg.runsInIframe()) {
      ogrFrameMsg.scrollTo(domElement);
      this._$log.debug('[INFO] IFrame Scrolling to element ' + elementId);
    } else {
      var scrollElement = angular.element(domElement);
      if (typeof offset != 'undefined') {
        this._$document.scrollToElementAnimated(scrollElement, offset).then(() => {
          this._$log.debug('[INFO] Scrolling to element ' + elementId);
        }, angular.noop);
      } else
      this._$document.scrollToElementAnimated(scrollElement).then(() => {
        this._$log.debug('[INFO] Scrolling to element ' + elementId);
      }, angular.noop);
    }
    if (popUpType) {
      this._popupService.openConfirm(popUpType);
    }
  }
}
