import template from './obs-collapse.html';
import './_obs-collapse.scss';

export const CollapseComponent = {
  bindings: {
    title: '@',
    classIfExpanded: '@',
    classIfCollapsed: '@',
    collapse: '=',
    onClick: '&',
    icon: '@',
    lastPanel: '@'
  },
  template: template,
  transclude: true
};
