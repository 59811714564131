import template from './finanzierungswunsch.html';
import { animateSliderBar, blinkSliderTitle } from '../../../components/obs-slider-utils';

export const FinanzierungswunschComponent = {
  template: template,
  controller: FinanzierungswunschController,
  bindings: {
    kalkulationParameter: '<'
  }
};

function FinanzierungswunschController(finanzierungswunschService, $timeout) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.model = finanzierungswunschService.daten;
    ctrl.kalkparams = ctrl.model.finanzierungsparameter;
    ctrl.showWunschrate = showWunschrate;
    ctrl.showWunschbetrag = showWunschbetrag;
    ctrl.onChangeBetrag = onChangeBetrag;
    ctrl.onChangeLaufzeit = onChangeLaufzeit;
    if (!finanzierungswunschService.initialized) {
      finanzierungswunschService.initModel(ctrl.kalkulationParameter);
      //Animation beim ersten Laden (verzögert um 500ms, sonst startet sie bevor die Page gerendert ist)
      animateSliderBar(ctrl.kalkparams.laufzeitSlider, $timeout, 500);
      animateSliderBar(ctrl.kalkparams.rateSlider, $timeout, 500);
      animateSliderBar(ctrl.kalkparams.betragSlider, $timeout, 500);
    }
  };

  /**
   * Die Kalkulation mit einer Wunschrate wird aktiviert.
   */
  function showWunschrate() {
    onChangeBerechnungsart(true);
  }

  /**
   * Die Kalkulation mit einem Wunschbetrag wird aktiviert.
   */
  function showWunschbetrag() {
    onChangeBerechnungsart(false);
  }

  /**
   * Event-Handler bei Wechsel der Berechnungsart
   * @param {*} isWunschrate
   */
  function onChangeBerechnungsart(isWunschrate) {
    ctrl.kalkparams.berechnungsartBudgetrechner = isWunschrate;
    const sliderModel = isWunschrate ?
      ctrl.kalkparams.rateSlider : ctrl.kalkparams.betragSlider;
    //Animation beim Wechsel der Berechnungsart
    animateSliderBar(sliderModel, $timeout);
    blinkSliderTitle(sliderModel, $timeout);
    finanzierungswunschService.berechnungsartGeaendert();
  }

  /**
   * Der Wert eines Betrag-Schiebereglers (Wunschrate oder Finanzierungsbetrag) hat sich geändert;
   * -> erneute Kalkulation durchführen
   */
  function onChangeBetrag() {
    finanzierungswunschService.betragGeaendert();
  }

  /**
   * Der Wert des Laufzeit-Schiebereglers hat sich geändert;
   * -> erneute Kalkulation durchführen
   */
  function onChangeLaufzeit() {
    finanzierungswunschService.laufzeitGeaendert();
  }

}

