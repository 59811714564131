import obsLayoutModule from '../layout/layout.module';
import errorPageModule from './error/error-page.module';
import kontaktformularModule from './kontaktformular/kontaktformular.module';

/**
 * Das Modul obs.shared enthält Masken, die von allen Anwendungsteilen verwendet werden.
 */
export default angular
  .module('obs.shared', [
    obsLayoutModule,
    errorPageModule,
    kontaktformularModule
  ])
  .name;

