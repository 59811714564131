export const staatsangehoerigkeit = [
  {
    nationalitaet: 'afghanisch',
    isocode: 'AF'
  },{
    nationalitaet: 'ägyptisch',
    isocode: 'EG'
  },{
    nationalitaet: 'albanisch',
    isocode: 'AL'
  },{
    nationalitaet: 'algerisch',
    isocode: 'DZ'
  },{
    nationalitaet: 'amerikanisch',
    isocode: 'US'
  },{
    nationalitaet: 'andorranisch',
    isocode: 'AD'
  },{
    nationalitaet: 'angolanisch',
    isocode: 'AO'
  },{
    nationalitaet: 'antiguanisch',
    isocode: 'AG'
  },{
    nationalitaet: 'äquatorialguineisch',
    isocode: 'GQ'
  },{
    nationalitaet: 'argentinisch',
    isocode: 'AR'
  },{
    nationalitaet: 'armenisch',
    isocode: 'AM'
  },{
    nationalitaet: 'aserbaidschanisch',
    isocode: 'AZ'
  },{
    nationalitaet: 'äthiopisch',
    isocode: 'ET'
  },{
    nationalitaet: 'australisch',
    isocode: 'AU'
  },{
    nationalitaet: 'bahamaisch',
    isocode: 'BS'
  },{
    nationalitaet: 'bahrainisch',
    isocode: 'BH'
  },{
    nationalitaet: 'bangladeschisch',
    isocode: 'BD'
  },{
    nationalitaet: 'barbadisch',
    isocode: 'BB'
  },{
    nationalitaet: 'belgisch',
    isocode: 'BE'
  },{
    nationalitaet: 'belizisch',
    isocode: 'BZ'
  },{
    nationalitaet: 'beninisch',
    isocode: 'BJ'
  },{
    nationalitaet: 'bhutanisch',
    isocode: 'BT'
  },{
    nationalitaet: 'bolivianisch',
    isocode: 'BO'
  },{
    nationalitaet: 'bosnisch-herzegowinisch',
    isocode: 'BA'
  },{
    nationalitaet: 'botsuanisch',
    isocode: 'BW'
  },{
    nationalitaet: 'brasilianisch',
    isocode: 'BR'
  },{
    nationalitaet: 'britisch',
    isocode: 'GB'
  },{
    nationalitaet: 'bruneiisch',
    isocode: 'BN'
  },{
    nationalitaet: 'bulgarisch',
    isocode: 'BG'
  },{
    nationalitaet: 'burkinisch',
    isocode: 'BF'
  },{
    nationalitaet: 'burundisch',
    isocode: 'BI'
  },{
    nationalitaet: 'cabo-verdisch',
    isocode: 'CV'
  },{
    nationalitaet: 'chilenisch',
    isocode: 'CL'
  },{
    nationalitaet: 'chinesisch',
    isocode: 'CN'
  },{
    nationalitaet: 'chinesisch (Hongkong)',
    isocode: 'HK'
  },{
    nationalitaet: 'chinesisch (Macau)',
    isocode: 'MO'
  },{
    nationalitaet: 'costa-ricanisch',
    isocode: 'CR'
  },{
    nationalitaet: 'dänisch',
    isocode: 'DK'
  },{
    nationalitaet: 'der Demokratischen Volksrepublik Korea',
    isocode: 'KP'
  },{
    nationalitaet: 'deutsch',
    isocode: 'DE'
  },{
    nationalitaet: 'dominicanisch',
    isocode: 'DM'
  },{
    nationalitaet: 'dominikanisch',
    isocode: 'DO'
  },{
    nationalitaet: 'dschibutisch',
    isocode: 'DJ'
  },{
    nationalitaet: 'ecuadorianisch',
    isocode: 'EC'
  },{
    nationalitaet: 'eritreisch',
    isocode: 'ER'
  },{
    nationalitaet: 'estnisch',
    isocode: 'EE'
  },{
    nationalitaet: 'fidschianisch',
    isocode: 'FJ'
  },{
    nationalitaet: 'finnisch',
    isocode: 'FI'
  },{
    nationalitaet: 'französisch',
    isocode: 'FR'
  },{
    nationalitaet: 'gabunisch',
    isocode: 'GA'
  },{
    nationalitaet: 'gambisch',
    isocode: 'GM'
  },{
    nationalitaet: 'georgisch',
    isocode: 'GE'
  },{
    nationalitaet: 'ghanaisch',
    isocode: 'GH'
  },{
    nationalitaet: 'grenadisch',
    isocode: 'GD'
  },{
    nationalitaet: 'griechisch',
    isocode: 'GR'
  },{
    nationalitaet: 'guatemaltekisch',
    isocode: 'GT'
  },{
    nationalitaet: 'guinea-bissauisch',
    isocode: 'GW'
  },{
    nationalitaet: 'guineisch',
    isocode: 'GN'
  },{
    nationalitaet: 'guyanisch',
    isocode: 'GY'
  },{
    nationalitaet: 'haitianisch',
    isocode: 'HT'
  },{
    nationalitaet: 'honduranisch',
    isocode: 'HN'
  },{
    nationalitaet: 'indisch',
    isocode: 'IN'
  },{
    nationalitaet: 'indonesisch',
    isocode: 'ID'
  },{
    nationalitaet: 'irakisch',
    isocode: 'IQ'
  },{
    nationalitaet: 'iranisch',
    isocode: 'IR'
  },{
    nationalitaet: 'irisch',
    isocode: 'IE'
  },{
    nationalitaet: 'isländisch',
    isocode: 'IS'
  },{
    nationalitaet: 'israelisch',
    isocode: 'IL'
  },{
    nationalitaet: 'italienisch',
    isocode: 'IT'
  },{
    nationalitaet: 'ivorisch',
    isocode: 'CI'
  },{
    nationalitaet: 'jamaikanisch',
    isocode: 'JM'
  },{
    nationalitaet: 'japanisch',
    isocode: 'JP'
  },{
    nationalitaet: 'jemenitisch',
    isocode: 'YE'
  },{
    nationalitaet: 'jordanisch',
    isocode: 'JO'
  },{
    nationalitaet: 'kambodschanisch',
    isocode: 'KH'
  },{
    nationalitaet: 'kamerunisch',
    isocode: 'CM'
  },{
    nationalitaet: 'kanadisch',
    isocode: 'CA'
  },{
    nationalitaet: 'kasachisch',
    isocode: 'KZ'
  },{
    nationalitaet: 'katarisch',
    isocode: 'QA'
  },{
    nationalitaet: 'kenianisch',
    isocode: 'KE'
  },{
    nationalitaet: 'kirgisisch',
    isocode: 'KG'
  },{
    nationalitaet: 'kiribatisch',
    isocode: 'KI'
  },{
    nationalitaet: 'kolumbianisch',
    isocode: 'CO'
  },{
    nationalitaet: 'komorisch',
    isocode: 'KM'
  },{
    nationalitaet: 'kongolesisch',
    isocode: 'CG'
  },{
    nationalitaet: 'kosovarisch',
    isocode: 'XK'
  },{
    nationalitaet: 'kroatisch',
    isocode: 'HR'
  },{
    nationalitaet: 'kubanisch',
    isocode: 'CU'
  },{
    nationalitaet: 'kuwaitisch',
    isocode: 'KW'
  },{
    nationalitaet: 'laotisch',
    isocode: 'LA'
  },{
    nationalitaet: 'lesothisch',
    isocode: 'LS'
  },{
    nationalitaet: 'lettisch',
    isocode: 'LV'
  },{
    nationalitaet: 'libanesisch',
    isocode: 'LB'
  },{
    nationalitaet: 'liberianisch',
    isocode: 'LR'
  },{
    nationalitaet: 'libysch',
    isocode: 'LY'
  },{
    nationalitaet: 'liechtensteinisch',
    isocode: 'LI'
  },{
    nationalitaet: 'litauisch',
    isocode: 'LT'
  },{
    nationalitaet: 'lucianisch',
    isocode: 'LC'
  },{
    nationalitaet: 'luxemburgisch',
    isocode: 'LU'
  },{
    nationalitaet: 'madagassisch',
    isocode: 'MG'
  },{
    nationalitaet: 'malawisch',
    isocode: 'MW'
  },{
    nationalitaet: 'malaysisch',
    isocode: 'MY'
  },{
    nationalitaet: 'maledivisch',
    isocode: 'MV'
  },{
    nationalitaet: 'malisch',
    isocode: 'ML'
  },{
    nationalitaet: 'maltesisch',
    isocode: 'MT'
  },{
    nationalitaet: 'marokkanisch',
    isocode: 'MA'
  },{
    nationalitaet: 'marshallisch',
    isocode: 'MH'
  },{
    nationalitaet: 'mauretanisch',
    isocode: 'MR'
  },{
    nationalitaet: 'mauritisch',
    isocode: 'MU'
  },{
    nationalitaet: 'mazedonisch',
    isocode: 'MK'
  },{
    nationalitaet: 'mexikanisch',
    isocode: 'MX'
  },{
    nationalitaet: 'mikronesisch',
    isocode: 'FM'
  },{
    nationalitaet: 'moldauisch',
    isocode: 'MD'
  },{
    nationalitaet: 'monegassisch',
    isocode: 'MC'
  },{
    nationalitaet: 'mongolisch',
    isocode: 'MN'
  },{
    nationalitaet: 'montenegrinisch',
    isocode: 'ME'
  },{
    nationalitaet: 'mosambikanisch',
    isocode: 'MZ'
  },{
    nationalitaet: 'myanmarisch',
    isocode: 'MM'
  },{
    nationalitaet: 'namibisch',
    isocode: 'NA'
  },{
    nationalitaet: 'nauruisch',
    isocode: 'NR'
  },{
    nationalitaet: 'nepalesisch',
    isocode: 'NP'
  },{
    nationalitaet: 'neuseeländisch',
    isocode: 'NZ'
  },{
    nationalitaet: 'nicaraguanisch',
    isocode: 'NI'
  },{
    nationalitaet: 'niederländisch',
    isocode: 'NL'
  },{
    nationalitaet: 'nigerianisch',
    isocode: 'NG'
  },{
    nationalitaet: 'nigrisch',
    isocode: 'NE'
  },{
    nationalitaet: 'norwegisch',
    isocode: 'NO'
  },{
    nationalitaet: 'omanisch',
    isocode: 'OM'
  },{
    nationalitaet: 'österreichisch',
    isocode: 'AT'
  },{
    nationalitaet: 'pakistanisch',
    isocode: 'PK'
  },{
    nationalitaet: 'palauisch',
    isocode: 'PW'
  },{
    nationalitaet: 'panamaisch',
    isocode: 'PA'
  },{
    nationalitaet: 'papua-neuguineisch',
    isocode: 'PG'
  },{
    nationalitaet: 'paraguayisch',
    isocode: 'PY'
  },{
    nationalitaet: 'peruanisch',
    isocode: 'PE'
  },{
    nationalitaet: 'philippinisch',
    isocode: 'PH'
  },{
    nationalitaet: 'polnisch',
    isocode: 'PL'
  },{
    nationalitaet: 'portugiesisch',
    isocode: 'PT'
  },{
    nationalitaet: 'der Republik Korea',
    isocode: 'KR'
  },{
    nationalitaet: 'ruandisch',
    isocode: 'RW'
  },{
    nationalitaet: 'rumänisch',
    isocode: 'RO'
  },{
    nationalitaet: 'russisch',
    isocode: 'RU'
  },{
    nationalitaet: 'salomonisch',
    isocode: 'SB'
  },{
    nationalitaet: 'salvadorianisch',
    isocode: 'SV'
  },{
    nationalitaet: 'sambisch',
    isocode: 'ZM'
  },{
    nationalitaet: 'samoanisch',
    isocode: 'WS'
  },{
    nationalitaet: 'san-marinesisch',
    isocode: 'SM'
  },{
    nationalitaet: 'são-toméisch',
    isocode: 'ST'
  },{
    nationalitaet: 'saudi-arabisch',
    isocode: 'SA'
  },{
    nationalitaet: 'schwedisch',
    isocode: 'SE'
  },{
    nationalitaet: 'schweizerisch',
    isocode: 'CH'
  },{
    nationalitaet: 'senegalesisch',
    isocode: 'SN'
  },{
    nationalitaet: 'serbisch',
    isocode: 'RS'
  },{
    nationalitaet: 'seychellisch',
    isocode: 'SC'
  },{
    nationalitaet: 'sierra-leonisch',
    isocode: 'SL'
  },{
    nationalitaet: 'simbabwisch',
    isocode: 'ZW'
  },{
    nationalitaet: 'singapurisch',
    isocode: 'SG'
  },{
    nationalitaet: 'slowakisch',
    isocode: 'SK'
  },{
    nationalitaet: 'slowenisch',
    isocode: 'SI'
  },{
    nationalitaet: 'somalisch',
    isocode: 'SO'
  },{
    nationalitaet: 'spanisch',
    isocode: 'ES'
  },{
    nationalitaet: 'sri-lankisch',
    isocode: 'LK'
  },{
    nationalitaet: 'südafrikanisch',
    isocode: 'ZA'
  },{
    nationalitaet: 'sudanesisch',
    isocode: 'SD'
  },{
    nationalitaet: 'surinamisch',
    isocode: 'SR'
  },{
    nationalitaet: 'swasiländisch',
    isocode: 'SZ'
  },{
    nationalitaet: 'syrisch',
    isocode: 'SY'
  },{
    nationalitaet: 'tadschikisch',
    isocode: 'TJ'
  },{
    nationalitaet: 'taiwanisch',
    isocode: 'TW'
  },{
    nationalitaet: 'tansanisch',
    isocode: 'TZ'
  },{
    nationalitaet: 'thailändisch',
    isocode: 'TH'
  },{
    nationalitaet: 'togoisch',
    isocode: 'TG'
  },{
    nationalitaet: 'tongaisch',
    isocode: 'TO'
  },{
    nationalitaet: 'tschadisch',
    isocode: 'TD'
  },{
    nationalitaet: 'tschechisch',
    isocode: 'CZ'
  },{
    nationalitaet: 'tunesisch',
    isocode: 'TN'
  },{
    nationalitaet: 'türkisch',
    isocode: 'TR'
  },{
    nationalitaet: 'turkmenisch',
    isocode: 'TM'
  },{
    nationalitaet: 'tuvaluisch',
    isocode: 'TV'
  },{
    nationalitaet: 'ugandisch',
    isocode: 'UG'
  },{
    nationalitaet: 'ukrainisch',
    isocode: 'UA'
  },{
    nationalitaet: 'ungarisch',
    isocode: 'HU'
  },{
    nationalitaet: 'uruguayisch',
    isocode: 'UY'
  },{
    nationalitaet: 'usbekisch',
    isocode: 'UZ'
  },{
    nationalitaet: 'vanuatuisch',
    isocode: 'VU'
  },{
    nationalitaet: 'vatikanisch',
    isocode: 'VA'
  },{
    nationalitaet: 'venezolanisch',
    isocode: 'VE'
  },{
    nationalitaet: 'der Vereinigten Arabischen Emirate',
    isocode: 'AE'
  },{
    nationalitaet: 'vietnamesisch',
    isocode: 'VN'
  },{
    nationalitaet: 'vincentisch',
    isocode: 'VC'
  },{
    nationalitaet: 'von St. Kitts und Nevis',
    isocode: 'KN'
  },{
    nationalitaet: 'von Timor-Leste',
    isocode: 'TL'
  },{
    nationalitaet: 'von Trinidad und Tobago',
    isocode: 'TT'
  },{
    nationalitaet: 'weißrussisch',
    isocode: 'BY'
  },{
    nationalitaet: 'zentralafrikanisch',
    isocode: 'CF'
  },{
    nationalitaet: 'zyprisch',
    isocode: 'CY'
  }
];

//Nationalitäten, die in anderen Systemen (STAMMD, MapStamm)
//unbekannt sind und daher nicht zur Auswahl angeboten werden:
// kosovarisch XK
// der Demokratischen Republik Kongo CD
// südsudanesisch SS
// staatenlos



