export default class DateUtilService {
  constructor() {
    this._SPECIAL_CHAR_EXP = /[\\\^\$\*\+\?\|\[\]\(\)\.\-\{\}]/g;
  }


  tagVorXJahre(date, count) {
    return new Date(date.setFullYear(date.getFullYear() - count));
  }

  /**
   * @param {Date} date
   * @param {number} count
   * @returns {Date}
   */
  tagVorXMonaten(date, count) {
    return new Date(date.setMonth(date.getMonth() - count));
  }

  getTagInXJahren(date, count) {
    return new Date(date.getFullYear() + count, date.getMonth(), date.getDate());
  }

  getLastDayOfXYear(count) {
    return new Date(new Date().getFullYear() - count, 11, 31);
  }

  dayAtMidn(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  getDate (dateString) {
    var d = new Date(dateString);
    var day = '' + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate()) ;
    var month = '' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) ;
    var year = '' + d.getFullYear();
    return [year, month, day].join('-');
  }

  dateToString(date, dateFormat) {
    if (!angular.isDate(date) || !dateFormat) {
      return '';
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const formatArr = dateFormat.split(this._SPECIAL_CHAR_EXP);
    const valueArr = [0, 0, 0];

    for (let i = 0; i < 3; i++) {
      if (formatArr[i] === 'TT') {
        valueArr[i] = (day < 10 ? '0' + day : day);
      }
      else if (formatArr[i] === 'MM') {
        valueArr[i] = (month < 10 ? '0' + month : month);
      }
      else if (formatArr[i] === 'JJJJ') {
        valueArr[i] = year;
      }
    }

    return valueArr.join('.');
  }

  dateWithoutTime(date) {
    if (date) {
      let dateWithoutTime = new Date();
      dateWithoutTime.setFullYear(date.getFullYear());
      dateWithoutTime.setMonth(date.getMonth());
      dateWithoutTime.setDate(date.getDate());
      dateWithoutTime.setMilliseconds(0);
      dateWithoutTime.setSeconds(0);
      dateWithoutTime.setMinutes(0);
      dateWithoutTime.setHours(0);
      return dateWithoutTime;
    }

    return date;
  }

  stringToDate(dateString, dateFormat) {
    if (!angular.isString(dateString) || !dateFormat || dateString.length == 0) {
      return undefined;
    }

    const date = new Date();
    date.setDate(1);

    const formatArr = dateFormat.split(this._SPECIAL_CHAR_EXP);
    const valueArr = dateString.split(this._SPECIAL_CHAR_EXP);

    let year, month, day;

    for (let i = 0; i < 3; i++) {
      if (formatArr[i] === 'TT') {
        day = parseInt(valueArr[i]);
      }
      else if (formatArr[i] === 'MM') {
        month = parseInt(valueArr[i]-1);
      }
      else if (formatArr[i] === 'JJJJ') {
        year = parseInt(valueArr[i]);
      }
    }

    if (year != undefined) {
      date.setFullYear(year);
    }

    if (month != undefined) {
      date.setMonth(month);
    }

    if (day != undefined) {
      date.setDate(day);
    }

    if (date.getMonth() != month || date.getDate() != day || date.getFullYear() != year) {
      return undefined;
    }

    return date;
  }
}
